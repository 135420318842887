import forOwn from 'lodash-es/forOwn';
import forEach from 'lodash-es/forEach';
import * as regexEscape from 'escape-string-regexp';
import {
  CREATE_TAXONOMY,
  CREATE_TAXONOMY_COMPLETE,
  DELETE_TAXONOMY,
  DELETE_TAXONOMY_COMPLETE,
  GET_TAXONOMIES,
  GET_TAXONOMIES_COMPLETE,
  UPDATE_TAXONOMY,
  UPDATE_TAXONOMY_COMPLETE,
  SET_TAXONOMY_SUGGESTION_FILTER,
  REORDER_TAXONOMIES_COMPLETE,
  REORDER_TAXONOMIES,
  TAXONOMY_TREE_NODE_UPDATE,
  TAXONOMY_TREE_NODE_UPDATE_COMPLETE,
  TAXONOMY_TREE_SORT_CHANGE,
  GET_TAXONOMY,
  GET_TAXONOMY_COMPLETE,
  CLEAR_SELECTED_TAXONOMY,
  CLEAR_TAXONOMIES,
  SET_TAXONOMIES_LOADING_FLAG,
  DELETE_TAXONOMY_LOCALIZATION,
  ACTIVATE_TAXONOMY_COMPLETE,
  DEACTIVATE_TAXONOMY_COMPLETE
} from './taxonomies.actions';
import { createSelector } from 'reselect';
import { UnsafeAction } from '../unsafe-action.interface';
import { isTaxonomyAllowed, processTaxonomies } from '../../api/taxonomies/taxonomies.service';
import { ContentLocale } from '../content-locales/content-locales.model';
import { get } from 'lodash';

interface TaxonomyVersion {
  name: string;
  slug: string;
  additionalItems?: any;
  customTaxonomyConfiguration?: any;
  contentLocale: ContentLocale,
};

// TODO change parent to parentId
export interface Taxonomy {
  id: number;
  name: string;
  slug?: string;
  parent: number;
  children: number[];
  position?: number;
  additionalItems?: any;
  sectionPage?: boolean;
  customTaxonomyConfiguration?: any;
  contentLocale?: ContentLocale,
  localizedVersions?: TaxonomyVersion[];
  image?: Image;
  icon?: Image;
  seoData?: any;
  active: boolean;

  // UI specific data
  taxonomyDisplayData?: string[];
  fullPath?: string;
  userHasPermission?: boolean;
  localizedDisplayData?: any;
  lookupId?: string;
  allowed?: boolean;
}

export interface Image {
  id: string;
  url: string;
}

interface TaxonomyTreeNode extends Taxonomy {
  pId: number;
}

export interface NormalizedTaxonomies {
  [key: number]: Taxonomy;
}

export interface TaxonomiesState {
  loaded: boolean;
  loading: boolean;
  sortBy: number;
  taxonomies: NormalizedTaxonomies;
  selectedTaxonomy: Taxonomy;
  suggestionFilter: string;
}

export const taxonomiesInitialState: TaxonomiesState = {
  loaded: false,
  loading: false,
  sortBy: 0,
  taxonomies: {},
  selectedTaxonomy: null,
  suggestionFilter: '',
};

export function taxonomiesReducer(state: TaxonomiesState = taxonomiesInitialState, action: UnsafeAction) {
  switch (action.type) {

    case TAXONOMY_TREE_NODE_UPDATE: {
      const updateTaxonomyData = action.payload.updateTaxonomy;
      const updateTreeData = action.payload.updateTree;
      const newTaxonomiesUpdatedTaxonomy = updateTaxonomy(state, updateTaxonomyData);
      const newTaxonomies = updateTreeData ? reorderTaxonomies(newTaxonomiesUpdatedTaxonomy, updateTreeData) : newTaxonomiesUpdatedTaxonomy;

      return Object.assign({}, state, {
        loading: true,
        taxonomies: { ...newTaxonomies }
      });
    }

    case TAXONOMY_TREE_NODE_UPDATE_COMPLETE: {
      return { ...state, loading: false, selectedTaxonomy: action.payload };
    }

    case REORDER_TAXONOMIES: {
      const newTaxonomies = reorderTaxonomies(state.taxonomies, action.payload);
      return Object.assign({}, state, {
        loading: true,
        taxonomies: newTaxonomies
      });
    }

    case REORDER_TAXONOMIES_COMPLETE: {
      return Object.assign({}, state, {
        loading: false
      });
    }

    case GET_TAXONOMIES: {
      return Object.assign({}, state, {
        loading: true
      });
    }

    case GET_TAXONOMIES_COMPLETE: {
      const taxonomies = action.payload;
      return Object.assign({}, state, {
        loaded: true,
        loading: false,
        taxonomies: taxonomies
      });
    }

    case GET_TAXONOMY: {
      return Object.assign({}, state, {
        loading: true,
        selectedTaxonomy: null
      });
    }

    case GET_TAXONOMY_COMPLETE: {
      const taxonomy = action.payload;
      return Object.assign({}, state, {
        loaded: true,
        loading: false,
        selectedTaxonomy: taxonomy
      });
    }

    case UPDATE_TAXONOMY: {
      const taxonomies = action.payload;
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case UPDATE_TAXONOMY_COMPLETE: {
      const newTaxonomies = updateTaxonomy(state, action.payload);
      // return new state
      return Object.assign({}, state, {
        loading: false,
        taxonomies: newTaxonomies
      });
    }

    case DELETE_TAXONOMY_COMPLETE: {
      const idToRemove = action.payload.id;
      const parentOfNodeToRemove = state.taxonomies[idToRemove].parent;
      const positionOfNodeToRemove = state.taxonomies[idToRemove].position;
      const newTaxonomies = {};
      forOwn(state.taxonomies, (taxonomy, taxoId) => {
        if (+taxoId !== +idToRemove) {
          if (parentOfNodeToRemove !== taxonomy.parent) {
            newTaxonomies[taxoId] = taxonomy;
            return;
          }
          if (taxonomy.position > positionOfNodeToRemove) {
            taxonomy = { ...taxonomy, position: --taxonomy.position };
          }
          newTaxonomies[taxoId] = taxonomy;
          return;
        }
        // We have found the node to remove, but we also need to remove it from parent's children (if a parent exists)
        const parentId = taxonomy.parent;
        const parent = state.taxonomies[parentId];
        if (parent) {
          const modifiedChildren = (state.taxonomies[parentId] as any).children
            .filter(child => child !== taxonomy.id);
          newTaxonomies[parentId] = Object.assign({}, state.taxonomies[parentId], {
            children: modifiedChildren
          });
        }
      });
      return Object.assign({}, state, {
        loading: false,
        taxonomies: { ...newTaxonomies }
      });
    }

    case CREATE_TAXONOMY_COMPLETE: {
      const taxonomyToAdd = action.payload;
      const taxonomiesAfterCreate = Object.assign({}, state.taxonomies);
      if (taxonomyToAdd.parent === null) {
        const nbrOfRootElement = Object.values(taxonomiesAfterCreate)
          .filter((el) => el.parent === null).length;
        taxonomyToAdd.position = nbrOfRootElement;
      }
      taxonomiesAfterCreate[taxonomyToAdd.id] = taxonomyToAdd;
      if (taxonomyToAdd.parent && state.taxonomies[taxonomyToAdd.parent]) {
        const parentTaxonomy = taxonomiesAfterCreate[taxonomyToAdd.parent];
        const modifiedChildren = (parentTaxonomy as any).children.slice();
        taxonomyToAdd.position = modifiedChildren.length;
        modifiedChildren.push(taxonomyToAdd.id);
        taxonomiesAfterCreate[taxonomyToAdd.parent] = Object.assign({}, parentTaxonomy, { children: modifiedChildren });
      }
      return Object.assign({}, state, {
        loading: false,
        taxonomies: taxonomiesAfterCreate,
        selectedTaxonomy: taxonomyToAdd
      });
    }

    case SET_TAXONOMY_SUGGESTION_FILTER: {
      const suggestionFilter = typeof action.payload === 'string' ? action.payload.trim().toLowerCase() : '';
      return { ...state, suggestionFilter };
    }

    case TAXONOMY_TREE_SORT_CHANGE: {
      let sortValue = state.sortBy;
      (sortValue === 0 || sortValue === 1) ? sortValue++ : sortValue = 0;
      return Object.assign({}, state, {
        sortBy: sortValue
      });
    }

    case CLEAR_SELECTED_TAXONOMY: {
      return { ...state, selectedTaxonomy: null };
    }

    case CLEAR_TAXONOMIES: {
      return {
        ...taxonomiesInitialState,
        taxonomies: {}
      };
    }

    case SET_TAXONOMIES_LOADING_FLAG: {
      return { ...state, loading: action.payload };
    }

    case DELETE_TAXONOMY_LOCALIZATION: {
      const { taxonomyId, localizationId, localeId } = action.payload;
      const taxonomy = { ...state.taxonomies[taxonomyId] };
      delete taxonomy.localizedDisplayData[localeId];
      taxonomy.localizedVersions = taxonomy.localizedVersions.filter((l: any) => l.id !== localizationId)

      return {
        ...state,
        loading: false,
        taxonomies: { ...state.taxonomies, [taxonomyId]: taxonomy },
        selectedTaxonomy: {
          ...state.selectedTaxonomy,
          localizedVersions:  state.selectedTaxonomy.localizedVersions.filter((l: any) => l.id !== localizationId)
        }
      };
    }

    case ACTIVATE_TAXONOMY_COMPLETE: {
      const taxonomy = action.payload;
      return {
        ...state,
        loading: false,
        taxonomies: {
          ...state.taxonomies,
          [taxonomy.id]: { ...state.taxonomies[taxonomy.id], active: taxonomy.active },
        },
        selectedTaxonomy: {
          ...taxonomy,
        },
      };
    }

    case DEACTIVATE_TAXONOMY_COMPLETE: {
      const taxonomy = action.payload;

      return {
        ...state,
        loading: false,
        taxonomies: {
          ...state.taxonomies,
          [taxonomy.id]: { ...state.taxonomies[taxonomy.id], active: taxonomy.active },
        },
        selectedTaxonomy: {
          ...taxonomy,
        },
      };
    }

    default: {
      return state;
    }
  }
}

// --------------------reducer functions--------------------------------------- //

function reorderTaxonomies(taxonomies: NormalizedTaxonomies, payload: any) {
  const parentOldId = payload.parentOldId;
  const parentNewId = payload.parentNewId;
  const parentNewChildren = payload.parentNewChildren;
  const nodeId = payload.nodeId;

  const rootChildren = Object.values(taxonomies)
    .filter(taxonomy => taxonomy.parent === null)
    .sort((a, b) => a.position - b.position)
    .map(taxonomy => taxonomy.id);
  const rootNode = { children: rootChildren };

  const newTaxonomies = { ...taxonomies };
  newTaxonomies[nodeId].parent = parentNewId;

  const oldParent = parentOldId ? newTaxonomies[parentOldId] : rootNode;
  oldParent.children = oldParent.children.filter(id => +id !== nodeId);
  oldParent.children.forEach((id, i) => newTaxonomies[id].position = i);

  const index = parentNewChildren.indexOf(nodeId);
  const newParent = parentNewId ? newTaxonomies[parentNewId] : rootNode;
  newParent.children.splice(index, 0, nodeId);
  newParent.children.forEach((id, i) => newTaxonomies[id].position = i);

  return newTaxonomies;
}

function updateTaxonomy(state: any, payload: any) {
  const updatedTaxo: Taxonomy = payload;
  const newTaxonomies = {};
  forOwn(state.taxonomies, (taxonomy, taxoId) => {
    if (+taxoId !== +updatedTaxo.id && !newTaxonomies[taxoId]) { // no modification
      newTaxonomies[taxoId] = taxonomy;
    } else {
      newTaxonomies[taxoId] = Object.assign({}, taxonomy, { ...updatedTaxo });
    }
  });
  return newTaxonomies;
}

// -----------------------------------------------------------------------------//
export const getTaxonomiesState = (state) => state.taxonomies;
export const getTaxonomiesLoaded = (state) => state.taxonomies.loaded;
export const getTaxonomyLoading = (state) => state.taxonomies.loading;
export const getTaxonomies = (state) => state.taxonomies.taxonomies;
export const getTaxonomiesFilter = (state) => state.taxonomies.suggestionFilter;
export const getTaxonomiesSortValue = (state) => state.taxonomies.sortBy;
export const getActiveTaxonomy = (state) => state.taxonomies.selectedTaxonomy;

export const getDenormalizedTaxonomies = createSelector(getTaxonomies, getTaxonomiesSortValue, (taxonomies, sortBy) => {
  return Object.keys(taxonomies)
    // get the root root nodes - they have parent id of null
    .filter(taxoId => taxonomies[taxoId].parent === null)
    // start recursive denormalization
    .map(taxoId => denormalizeTaxonomy(taxonomies, taxoId, sortBy))
    .sort((x, y) => sortByName(x, y, sortBy));
});

function denormalizeTaxonomy(taxonomies, taxoId, sortBy) {
  // TODO check performance and see if there is need for node caching
  return {
    id: taxonomies[taxoId].id,
    pId: taxonomies[taxoId].parent,
    position: taxonomies[taxoId].position,
    name: taxonomies[taxoId].name,
    additionalItems: taxonomies[taxoId].additionalItems,
    sectionPage: taxonomies[taxoId].sectionPage,
    slug: taxonomies[taxoId].slug,
    children: taxonomies[taxoId].children
      .filter(childId => !!taxonomies[childId])
      .map(childId => denormalizeTaxonomy(taxonomies, childId, sortBy))
      .sort((x, y) => sortByName(x, y, sortBy)),
    customTaxonomyConfiguration: taxonomies[taxoId].customTaxonomyConfiguration,
    userHasPermission: taxonomies[taxoId].userHasPermission,
    contentLocale: taxonomies[taxoId].contentLocale,
    localizedVersions: taxonomies[taxoId].localizedVersions,
    localizedDisplayData: taxonomies[taxoId].localizedDisplayData,
    lookupId: taxonomies[taxoId].lookupId,
    allowed: typeof taxonomies[taxoId].allowed === 'boolean' ? taxonomies[taxoId].allowed : true,
    active: taxonomies[taxoId].active
  } as TaxonomyTreeNode;
}

function sortByName (x, y, sortBy) {
  let nameX = x.name.toUpperCase().trim();
  let nameY = y.name.toUpperCase().trim();
  switch (sortBy) {
    case 1:
      return (nameX < nameY) ? -1 : (nameX > nameY) ? 1 : 0;
    case 2:
      return (nameX > nameY) ? -1 : (nameX < nameY) ? 1 : 0;
    default:
      return x.position - y.position;
  }
}

export const getRawTaxonomies = createSelector(getTaxonomies, (taxonomies) => {
  return Object.keys(taxonomies).map(taxoId => taxonomies[taxoId]);
});

// note that 'showOnlyPermitted' defaults to true for filtering, but can be overriden
export const getFilteredTaxonomies = (limit: any = 5, showTaxonomyPath = false, showOnlyPermitted = true, contentLocaleId = null, allowedTaxonomies = [], allowedTaxonomySubtrees = [], options: any = {}, showDeactivated: boolean = false) =>
  createSelector(getTaxonomies, getTaxonomiesFilter, (taxonomies, filter) => {
    let filteredTaxonomies = getNTaxonomiesFilteredByName(Object.values(taxonomies), filter, limit, showOnlyPermitted, contentLocaleId, allowedTaxonomies, allowedTaxonomySubtrees, options, showDeactivated);
    return processTaxonomies(filteredTaxonomies, taxonomies, showTaxonomyPath, null, false, contentLocaleId, showDeactivated);
  });

export const getTaxonomiesByIds = (ids: number[], showTaxonomyPath = false, contentLocaleId = null, showDeactivated: boolean = false) =>
  createSelector(getTaxonomies, (taxonomies) => {
    const filteredTaxonomies = ids.reduce((acc, taxonomyId) => {
      acc.push(taxonomies[taxonomyId]);
      return acc;
    }, []);
    return processTaxonomies(filteredTaxonomies, taxonomies, showTaxonomyPath, null, false, contentLocaleId, showDeactivated);
  });

function getNTaxonomiesFilteredByName(taxonomies, filter, count = 10, showOnlyPermitted, contentLocaleId = null, allowedTaxonomies = [], allowedTaxonomySubtrees  = [], options: any = {}, showDeactivated: boolean = false) {
  let filteredTaxonomies = [];
  const filterRegExp = new RegExp(regexEscape(filter), 'i');

  forEach(taxonomies, (taxonomy: Taxonomy) => {
    const name = get(taxonomy, `localizedDisplayData.${contentLocaleId}.name`, null) || taxonomy.name;
    const omitBasedOnPermissions = showOnlyPermitted && !taxonomy.userHasPermission;
    const allowed = isTaxonomyAllowed(taxonomy, taxonomies, allowedTaxonomies, allowedTaxonomySubtrees);
    const hasRequiredLocalization = !contentLocaleId || taxonomy.localizedDisplayData[contentLocaleId];
    let hasAllNeededLocalizations = true;
    if (Array.isArray(options?.includedLocalizations) && !!options?.includedLocalizations.length) {
      hasAllNeededLocalizations = options.includedLocalizations.every(id => Object.keys(taxonomy.localizedDisplayData).includes(`${id}`));
    }
    if (allowed && !omitBasedOnPermissions && filterRegExp.test(name) && hasRequiredLocalization && hasAllNeededLocalizations) {
      filteredTaxonomies.push(taxonomy);
    }
    if (!showDeactivated) {
      filteredTaxonomies = filteredTaxonomies.filter(taxonomy => taxonomy.active);
    }
    if (count) {
      return filteredTaxonomies.length !== count;
    }
  });
  return filteredTaxonomies;
}

export const getAllowedTaxonomies = (
  allowedTaxonomies: number[] = [],
  allowedTaxonomySubtrees: number[] = []
) =>
  createSelector(getTaxonomies, (taxonomies) => {
    return Object.values(taxonomies)
    .filter(tax => isTaxonomyAllowed(tax, taxonomies, allowedTaxonomies, allowedTaxonomySubtrees))
    .reduce((acc: any, tax: any) => ({ ...acc, [tax.id]: tax}), {});
});

export const getAllowedTaxonomiesTree = (
  allowedTaxonomies: number[] = [],
  allowedTaxonomySubtrees: number[] = []
) =>
  createSelector(getTaxonomies, getTaxonomiesSortValue, (taxonomies, sortBy) => {
    const allowedAll = !allowedTaxonomies.length && !allowedTaxonomySubtrees.length;
    const filteredTaxos = Object.values(taxonomies)
    .map((tax: any) => {
      const allowed = allowedAll || allowedTaxonomies.includes(tax.id) || allowedTaxonomySubtrees.some(id => tax.id !== id && tax.lookupId.startsWith(taxonomies[id].lookupId));
      return { ...tax, allowed };
    })
    .filter(tax => tax.allowed || [...allowedTaxonomies ,...allowedTaxonomySubtrees].some(id => taxonomies[id].lookupId.startsWith(tax.lookupId)))
    .reduce((acc, tax) => ({ ...acc, [tax.id]: tax}), {});

    return Object.keys(filteredTaxos)
      // get the root root nodes - they have parent id of null
      .filter((taxoId) => filteredTaxos[taxoId].parent === null)
      // start recursive denormalization
      .map((taxoId) => denormalizeTaxonomy(filteredTaxos, taxoId, sortBy))
      .sort((x, y) => sortByName(x, y, sortBy));
  });


