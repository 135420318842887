import { UnsafeAction } from '../unsafe-action.interface';

export const GET_EXTERNAL_APPS = '[External Apps] Get List';
export class GetExternalAppsAction implements UnsafeAction {
  readonly type = GET_EXTERNAL_APPS;
  constructor(public payload: any = {}) {}
}

export const GET_EXTERNAL_APPS_SUCCESS = '[External Apps] Get List Success';
export class GetExternalAppsSuccessAction implements UnsafeAction {
  readonly type = GET_EXTERNAL_APPS_SUCCESS;
  constructor(public payload: any) {}
}

export const GET_ACTIVE_EXTERNAL_APP = '[External Apps] Get active external app';
export class GetActiveExternalAppAction implements UnsafeAction {
  readonly type = GET_ACTIVE_EXTERNAL_APP;
  constructor(public payload: any) {}
}

export const GET_ACTIVE_EXTERNAL_APP_SUCCESS = '[External Apps] Get active external app success';
export class GetActiveExternalAppSuccessAction implements UnsafeAction {
  readonly type = GET_ACTIVE_EXTERNAL_APP_SUCCESS;
  constructor(public payload: any) {}
}

export const CREATE_EXTERNAL_APP = '[External Apps] Create External App';
export class CreateExternalAppAction implements UnsafeAction {
  readonly type = CREATE_EXTERNAL_APP;
  constructor(public payload: any = {}) {}
}

export const CREATE_EXTERNAL_APP_SUCCESS = '[External Apps] Create External App Success';
export class CreateExternalAppSuccessAction implements UnsafeAction {
  readonly type = CREATE_EXTERNAL_APP_SUCCESS;
  constructor(public payload: any) {}
}

export const UPDATE_EXTERNAL_APP = '[External Apps] Update External App';
export class UpdateExternalAppAction implements UnsafeAction {
  readonly type = UPDATE_EXTERNAL_APP;
  constructor(public payload: any = {}) {}
}

export const UPDATE_EXTERNAL_APP_SUCCESS = '[External Apps] Update External App Success';
export class UpdateExternalAppSuccessAction implements UnsafeAction {
  readonly type = UPDATE_EXTERNAL_APP_SUCCESS;
  constructor(public payload: any) {}
}

export const DELETE_EXTERNAL_APP = '[External Apps] Delete External App';
export class DeleteExternalAppAction implements UnsafeAction {
  readonly type = DELETE_EXTERNAL_APP;
  constructor(public payload: number) {}
}

export const DELETE_EXTERNAL_APP_SUCCESS = '[External Apps] Delete External App Success';
export class DeleteExternalAppSuccessAction implements UnsafeAction {
  readonly type = DELETE_EXTERNAL_APP_SUCCESS;
  constructor(public payload: any) {}
}

export const UPDATE_EXTERNAL_APPS_POSITIONS = '[External Apps] Update positions';
export class UpdateExternalAppsPositionsAction implements UnsafeAction {
  readonly type = UPDATE_EXTERNAL_APPS_POSITIONS;
  constructor(public payload: any) {}
}

export const SET_EXTERNAL_APPS_LOADING_FLAG = '[External Apps] Set External Apps Loading Flag';
export class SetExternalAppsLoadingFlagAction implements UnsafeAction {
  readonly type = SET_EXTERNAL_APPS_LOADING_FLAG;
  constructor(public payload: any) {}
}

export const CLEAR_ACTIVE_EXTERNAL_APP = '[External Apps] Clear active external app';
export class ClearActiveExternalAppAction implements UnsafeAction {
  readonly type = CLEAR_ACTIVE_EXTERNAL_APP;
  constructor() {}
}

export const EXTERNAL_APPS_ACTION_FAILED = '[External Apps] Generic failure action';
export class ExternalAppsFailedAction implements UnsafeAction {
  readonly type = EXTERNAL_APPS_ACTION_FAILED;
  constructor(public payload: any) {}
}
