import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlideCreateRoutingModule } from './glide-create-routing.module';
import { ExternalAppViewComponent } from './+site-builder/+external-apps/external-app-view/external-app-view.component';

@NgModule({
  imports: [
    CommonModule,
    GlideCreateRoutingModule,
    ExternalAppViewComponent
  ],
  declarations: [],
  exports: [GlideCreateRoutingModule]
})
export class GlideCreateModule { }
