<mat-card class="gd-ext-app"
          [dir]="dir$ | async">
  <h2 class="mat-h2"
      *ngIf="externalApp?.name">
    {{ externalApp?.name }}
    <mat-icon fontSet="fal"
              fontIcon="fa-exclamation-circle"
              [matTooltip]="getHelpText()"></mat-icon>
  </h2>
  <div class="gd-ext-app__content">
    <iframe #ext_app_container
            sandbox="allow-same-origin allow-scripts allow-forms allow-modals"
            allow=""
            referrerpolicy="no-referrer"
            [src]="url"
            *ngIf="url"
            (load)="externalApp?.sendGlideAuthToken && this.sendAuthDataToIframe()"></iframe>
  </div>
</mat-card>
