<div class="gd-generate-image">
  <div class="gd-generate-image__body"
       fxFLex="100">
    <div class="gd-generate-image__left-column"
         [style.width]="usage === 'imagePage' ? '45%' : '52%'">
      <form *ngIf="generateImageForm"
            [formGroup]="generateImageForm"
            class="gd-generate-image__left-column-form">
        <div class="gd-generate-image__prompt-title">
          <span class="gd-generate-image__prompt-title-text"
                i18n>Positive Prompt</span>
          <mat-icon class="gd-generate-image__prompt-title-icon"
                    matTooltip="Describe the image you want to create and then 'Generate' the image to see a preview. Inference Configuration options can be tweaked and the image can be regenerated if need be."
                    i18n-matTooltip
                    fontSet="fal"
                    fontIcon="fa-info-circle"></mat-icon>
        </div>
        <div style="display: flex; flex-direction: column">
          <div style="display: flex; flex-direction: row">
            <mat-form-field appearance="outline"
                            fxFlex="100">
              <textarea matInput
                        formControlName="text"
                        required
                        autocomplete="off"
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="7"
                        cdkAutosizeMaxRows="7"
                        trim="blur"
                        class="gd-scroller-custom"
                        (change)="sendMixpanelEvent(textControl.value, 'ImagePrompt')"
                        class="gd-custom-scroller"></textarea>
              <mat-error *ngIf="textControl.hasError('invalidText')">You have used a term(s) which cannot be generated.
                Please use another
                prompt.</mat-error>
              <mat-error *ngIf="textControl.hasError('required')">Prompt cannot be empty.</mat-error>
            </mat-form-field>
            <mat-slider [value]="textWeightControl.value"
                        (change)="textWeightControl.setValue($event.value)"
                        color="primary"
                        min="0.1"
                        max="2"
                        step="0.1"
                        #promptSlider
                        [vertical]="true"
                        style="min-height: 170px; margin-left: 24px; width: 0px; padding: 0"
                        *ngIf="modelVersion !== 'TitanImage'">
              <input matSliderThumb />
            </mat-slider>
          </div>
          <span class="gd-generate-image__weight-text" *ngIf="modelVersion !== 'TitanImage'">Weight: {{ textWeightControl.value }}</span>
        </div>
        <div style="padding-bottom: 8px; display: flex">
          <span class="gd-generate-image__prompt-title-text"
                i18n>Negative Prompt</span>
          <mat-icon class="gd-generate-image__prompt-title-icon"
                    matTooltip="The negative prompt is a optional way to control image generation where you write what you don't want to see in the generated images."
                    i18n-matTooltip
                    fontSet="fal"
                    fontIcon="fa-info-circle"></mat-icon>
        </div>
        <div style="display: flex; flex-direction: column">
          <div style="display: flex; flex-direction: row">
            <mat-form-field appearance="outline"
                            fxFlex="100">
              <textarea matInput
                        formControlName="negativeText"
                        autocomplete="off"
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="7"
                        cdkAutosizeMaxRows="7"
                        trim="blur"
                        class="gd-custom-scroller"></textarea>
              <mat-error *ngIf="textControl.hasError('invalidText')">You have used a term(s) which cannot be generated.
                Please use another
                prompt.</mat-error>
              <mat-error *ngIf="textControl.hasError('required')">Prompt cannot be empty.</mat-error>
            </mat-form-field>
            <mat-slider [value]="negativeTextWeightControl.value"
                        (change)="negativeTextWeightControl.setValue($event.value)"
                        color="primary"
                        min="0"
                        max="2"
                        step="0.1"
                        [vertical]="true"
                        style="min-height: 170px; margin-left: 24px; width: 0px; padding: 0"
                        *ngIf="modelVersion !== 'TitanImage'">
              <input matSliderThumb />
            </mat-slider>
          </div>
          <span class="gd-generate-image__weight-text" *ngIf="modelVersion !== 'TitanImage'">Weight: {{ negativeTextWeightControl.value }}</span>
        </div>
      </form>
      <div style="display: flex; flex-direction: column">
        <div style="display: flex; flex-direction: column; width: 100%">
          <span class="gd-generate-image__inference-config-title"
                i18n>Inference configuration</span>
          <div class="gd-generate-image__config-body-wrapper"
               [class.--modal]="usage === 'embedModal'">
               <div class="gd-fm-config__form-field"
                            *ngFor="let param of modelConfigParam">
               <ng-container *ngIf="getFormControl(param.key) as fieldControl">
                <div style="display: flex; flex-direction: column; width: 100%">
                  <div class="gd-generate-image__config-body">
                    <div>
                      <span style="font-size: 14px"
                            i18n> {{ param.label }} </span>
                      <mat-icon [matTooltip]="param.tooltip"
                                i18n-matTooltip
                                fontSet="fal"
                                fontIcon="fa-info-circle"></mat-icon>
                    </div>
                    <input type="number"
                           class="gd-generate-image__config-field-value"
                           [max]="param.max"
                           [min]="param.min"
                           [step]="param.step"
                           [value]="slider.value"
                           (change)="handleNumberInputChange(fieldControl, numberInput.value, param.key)"
                           [class.--invalid]="fieldControl.invalid"
                           [class.--seed]="param.key === 'seed'"
                           #numberInput />
                  </div>
                  <mat-slider color="primary"
                      [max]="param.max"
                      [min]="param.min"
                      [step]="param.step"
                      fxFlex="100%"
                      [formControl]="fieldControl"
                      (valueChange)="sendMixpanelEvent($event, param.key)"
                      #slider>
                    <input matSliderThumb>
                  </mat-slider>
                </div>
               </ng-container>
              </div>
          </div>
        </div>
      </div>
    </div>
    <mat-divider [inset]="true"
                 [vertical]="true"
                 style="margin: 0 28px"></mat-divider>
    <div style="padding: 0 12px"
         [style.width]="usage === 'imagePage' ? '50%' : '44%'">
      <div class="gd-generate-image__image-card-header-wrapper">
        <span class="gd-generate-image__image-card-header-text"
              i18n>Generated Image</span>
              {{ imageDimensionForm.value }}
      </div>
      <section>
        <mat-card class="gd-generate-image__image-card">
          <mat-spinner diameter="70"
                       *ngIf="loading"
                       [class.loaded]="!loading"> </mat-spinner>
          <img *ngIf="imageUrl"
               mat-card-image
               class="gd-generate-image__img"
               [class.loaded]="!loading && imageUrl"
               [src]="imageUrl | sanitazeURL" />
          <mat-icon *ngIf="!imageUrl && !loading"
                    fontSet="fal"
                    fontIcon="fa-image"
                    style="color: #757575; font-size: 80px; width: 80px; height: 80px"></mat-icon>
        </mat-card>
      </section>
      <div class="gd-generate-image__card-actions-wrapper">
        <div style="display: flex; flex-direction: column; width: 45%" *ngIf="modelVersion !== 'TitanImage'" [style.width]="modelVersion === 'SDXLV1' && '30%'">
          <mat-form-field appearance="outline"
                          floatLabel="auto"
                          style="width: 100%">
            <mat-label> Style Preset</mat-label>
            <mat-select [value]="stylePresetControl.value">
              <mat-option *ngFor="let style of stylePresetList"
                          [value]="style.name"
                          (click)="stylePresetControl.setValue(style.name)">
                {{ style.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div style="display: flex; flex-direction: column; width: 45%; padding: 0 16px"
             [style.padding]="modelVersion === 'TitanImage' && '0'"
             *ngIf="modelVersion === 'SDXLV1' || modelVersion === 'TitanImage'"
             [style.width]="modelVersion === 'SDXLV1' && '30%'">
          <mat-form-field appearance="outline"
                          floatLabel="auto"
                          style="width: 100%">
            <mat-label> Orientation</mat-label>
            <mat-select [value]="imageOrientationForm.value">
              <mat-option *ngFor="let orientation of imageOrientation"
                          [value]="orientation.label"
                          (click)="changeImageOrientation(orientation.label)">
                {{ orientation.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div style="display: flex; flex-direction: column;" [style.width]="(modelVersion === 'SDXLV1') ? '30%' : '45%'">
          <mat-form-field appearance="outline"
                          floatLabel="auto"
                          style="width: 100%">
            <mat-label> Image Dimensions</mat-label>
            <mat-select [value]="imageDimensionForm.value">
              <mat-option *ngFor="let dimension of imageDimensions"
                          [value]="dimension.label"
                          (click)="changeImageDimension(dimension.width, dimension.height)">
                {{ dimension.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="gd-generate-image__config-action-buttons">
  <button mat-button
          mat-raised-button
          i18n
          (click)="resetAdvanceConfig()">Reset</button>
  <button mat-button
          mat-raised-button
          color="primary"
          [disabled]="generateImageForm.invalid || loading"
          (click)="generate()"
          class="gd-generate-image__generate-button"
          [class.--active]="!generateImageForm.invalid && !loading">
    <div fxLayoutGap="5px"
         fxLayoutAlign="start center">
      <mat-icon class="gd-image-generate-icon"
                svgIcon="glideCustomIcon_gaia_image_generation"></mat-icon>
      <span class="gd-files-upload__btn-label"
            i18n>Generate</span>
    </div>
  </button>
</div>
<mat-divider style="margin-top: 2%"></mat-divider>
