import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ArticlesService, ImagesService, RestService, resolveArticleBodyImage, loadImageUrl } from '../../api';
import { ContentPanelsService } from '../../api/content-panels/content-panels.service';
import { ContentTagsService } from '../../api/content-tags/content-tags.service';
import { FilesService } from '../../api/files/files.service';
import { GalleriesService } from '../../api/galleries/galleries.service';
import { PostsService } from '../../api/posts/posts.service';
import { AppState } from '../../store/app-reducer';
import { ContentModel, ContentNode, EmbeddedType, ReferencedContentType } from '../../store/article/article-content.model';
import * as moment from 'moment-timezone';
import { get } from 'lodash-es';
import {  getTaxonomiesState } from '../../store/taxonomies/taxonomies.reducer';
import { filter, take } from 'rxjs/operators';

/**
 * Must override `EmbeddedImageVisibilityAction` and `UpdateReferencedContentAction` on extending.
 * This basically defines for which content will the service load referenced content
 */
@Injectable({
  providedIn: 'root',
})
export abstract class AbstractReferencedContentService {

  protected EmbeddedImageVisibilityAction;
  protected UpdateReferencedContentAction;

  constructor(
    protected articlesService: ArticlesService,
    protected store: Store<AppState>,
    protected galleriesService: GalleriesService,
    protected imagesService: ImagesService,
    protected rest: RestService,
    protected contentPanelService: ContentPanelsService,
    protected filesService: FilesService,
    protected contentTagsService: ContentTagsService,
    protected postsService: PostsService
  ) { }

  public loadReferencedContentForContentModel(contentModel: ContentModel, referencedContentCache, localeId = null) {
    const referencedContent = getNewReferencedContent(contentModel, referencedContentCache);
    const nodesMap = referencedContent.reduce((acc, item) => {
      const nodes = [...(acc[item.type] || []), item];
      return { ...acc, [item.type]: nodes };
    }, {});
    this.loadReferencedItems(nodesMap, localeId);
  }

  /**
   * When extending AbstractReferencedContentService override the UpdateReferencedContentAction,
   * and this function can then be made to fire actions specific to glide content type
   */
  protected updateReferencedContent(nodes) {
    const referencedContent = this.normalizeReferencedContentNode(nodes);
    this.store.dispatch(new this.UpdateReferencedContentAction(referencedContent));
  }

  protected normalizeReferencedContentNode(nodes) {
    return nodes.reduce((acc, node) => ({ ...acc, [node.id]: { ...node } }), {});
  }


  protected loadReferencedItems(nodesMap, localeId = null) {
    Object.keys(nodesMap).forEach((type) => {
      const resourceMap = createResourceMap(nodesMap[type]);
      switch (type) {
        case EmbeddedType.ArticleMention:
          return this.loadArticles(resourceMap);
        case EmbeddedType.TaxonomyMention:
          return this.loadTaxonomies(resourceMap, localeId);
        case EmbeddedType.PostMention:
          return this.loadPost(resourceMap);
        case EmbeddedType.Gallery:
          return this.loadGalleries(resourceMap);
        case EmbeddedType.Image:
          return this.loadImages(resourceMap);
        case EmbeddedType.ContentTag:
          return this.loadContentTags(resourceMap);
        case EmbeddedType.ContentPanel:
          return this.loadContentPanels(resourceMap);
        case EmbeddedType.File:
          return this.loadFiles(resourceMap);
        case EmbeddedType.SystemWidget:
        case EmbeddedType.ThirdPartyWidget:
          return this.loadWidgets(nodesMap[type], type);
        case EmbeddedType.ImageURL:
          return this.loadImageUrlData(resourceMap);
        default:
          return;
      }
    });
  }

  protected loadArticles(resourceMap) {
    this.articlesService
      .getArticlesAdvancedFiltering({
        ids: Object.keys(resourceMap),
        generateUrl: true,
        limit: 100,
      })
      .subscribe(({ articles }) => {
        return Object.keys(resourceMap).forEach((id) => {
          const article = articles.find((item) => item.id === +id);
          if (article) {
            const isPublished = !!article.publishedRevisionId;
            const scheduled = !!article.scheduledRevision;
            const publishDate = get(article, 'scheduledRevision.publishDateFrom');
            const scheduledOn = moment(publishDate).format('DD-MMM-YYYY h:mm A');
            const state = {
              status: isPublished ? 'published' : scheduled ? 'scheduled' : 'unpublished',
              message: isPublished
                ? ''
                : scheduled
                ? scheduledOn
                : `Article: [${article.headline}] is not published`,
            };
            const affectedNodes = resourceMap[article.id].map((node) => {
              return {
                id: node.id,
                dataId: node.dataId,
                dataUrl: article.url,
                innerText: article.headline,
                state,
              };
            });
            this.updateReferencedContent(affectedNodes);
          }

          if (!article) {
            resourceMap[id][0] = {
              ...resourceMap[id][0],
              error: { message: `Resource not found. Type: Article, Id: ${id}`, status: 'error' },
            };
            console.error(`Error while loading referenced article data for article id: ${id}`);
            this.updateReferencedContent(resourceMap[id]);
          }
        });
      });
  }

  protected loadTaxonomies(resourceMap, localeId = null) {
    this.store.select(getTaxonomiesState).pipe(
      filter((taxState: any) => taxState.loaded),
      take(1),
    ).subscribe(({ taxonomies }) => {
      const affectedNodes = Object.entries(resourceMap).reduce((acc, [taxId, nodes]: any) => {
        const taxonomy = taxonomies[taxId];
        if (!taxonomy) {
          console.warn(`Cannot find a taxonomy with the id ${taxId}.`);
          return acc;
        }
        const taxName = get(taxonomy, `localizedDisplayData.${localeId}.name`, '') || taxonomy.name;
        nodes = nodes.map(node => {
          return {
            id: node.id,
            dataId: node.dataId,
            innerText: taxName,
            allowed: !localeId || !!get(taxonomy, `localizedDisplayData.${localeId}`, false),
            active: taxonomy?.active,
          };
        });
        return [...acc, ...nodes];
      }, []);
      this.updateReferencedContent(affectedNodes);
    });
  }

  protected loadPost(resourceMap) {
    Object.keys(resourceMap).forEach((id) => {
      const liveReportId = resourceMap[id][0].liveReportId;
      return this.postsService.getPost({ liveReportId, postId: id }).subscribe(
        (post) => {
          const isPublished = !!post.publishedRevision;
          const revision = post.publishedRevision || post.workingRevision;
          const state = {
            status: isPublished ? 'published' : 'unpublished',
            message: isPublished ? '' : `Post: ${revision.headline} is not published`,
          };
          const affectedNodes = resourceMap[id].map((node) => {
            return {
              id: node.id,
              dataId: node.dataId,
              dataUrl: revision.url,
              innerText: revision.headline,
              state
            };
          });
          this.updateReferencedContent(affectedNodes);
        },
        (err) => {
          resourceMap[id][0] = { ...resourceMap[id][0], 'error': { ...err, status: 'error' } };
          console.error(`Error while loading referenced post data for post id: ${id}`);
          this.updateReferencedContent(resourceMap[id]);
        }
      );
    });
  }

  protected loadGalleries(resourceMap) {
    Object.keys(resourceMap).forEach((id) => {
      return this.galleriesService.getGalleryDetails(id, true).subscribe(
        (gallery) => {
          let affectedNodes = resourceMap[id];
          if (!gallery || !gallery.published) {
            console.warn(`Cannot find a published gallery with the id ${id}.`);
          } else {
            const src = gallery.promoImage
              ? gallery.promoImage.thumbnail
              : './assets/img/embeded-gallery-placeholder.png';
            affectedNodes = affectedNodes.map((node) => {
              return {
                id: node.id,
                dataId: node.dataId,
                title: gallery.published.title,
                src,
              };
            });
          }
          this.updateReferencedContent(affectedNodes);
        },
        (err) => {
          console.error(`Error while loading referenced gallery data for gallery id: ${id}`);
          this.updateReferencedContent(resourceMap[id]);
        }
      );
    });
  }

  protected loadImages(resourceMap) {
    Object.keys(resourceMap).forEach((id) => {
      return this.imagesService
        .getImage(id)
        .then((image) => {
          image = this.imagesService.processImage(image);
          const src = resolveArticleBodyImage(image);
          const metaData = image.metaData.reduce(
            (acc, { key, value }) => ({ ...acc, [key]: value }),
            {}
          );
          const tags = [...image.tags].sort((a, b) => a.group > b.group ? -1 : 1)
            .sort((a, b) => a.premium > b.premium ? -1 : 1)
            .map((tag) => ({ name: tag.name, premium: tag.premium, group: tag.group }));

          const affectedNodes = resourceMap[id].map((node) => {
            return {
              id: node.id,
              dataId: node.dataId,
              src,
              ...metaData,
              tags,
              isNotLoaded: true
            };
          });
          setTimeout(() => {
            affectedNodes.forEach(node => {
              return this.imagesService.setArticleEmbeddedImageChecker(
                {
                  id: node.id,
                  dataId: node.dataId,
                  src: node.src,
                  attempt: 1
                },
                this.EmbeddedImageVisibilityAction
              );
            });
          }, 200);
          this.updateReferencedContent(affectedNodes);
        })
        .catch((err) => {
          console.error(
            'Error while loading referenced image data for image id:\n',
            id,
            '\nFor article content node ids:\n',
            resourceMap[id].map((node) => node.id).join(', ')
          );
          const nodes = resourceMap[id].map((node) => {
            return {
              id: node.id,
              dataId: node.dataId,
              src: './assets/img/outline-broken_image-24px.svg',
              tags: [],
              metaData: {},
            };
          });
          this.updateReferencedContent(nodes);
        });
    });
  }

  protected loadContentTags(resourceMap) {
    Object.keys(resourceMap).forEach((id) => {
      return this.contentTagsService.getContentTag(id).subscribe(
        (contentTag) => {
          const affectedNodes = resourceMap[id].map((node) => {
            return {
              id: node.id,
              identifier: contentTag.identifier || node.identifier,
              dataId: node.dataId,
              title: contentTag.name,
              colour: contentTag.colour,
            };
          });
          this.updateReferencedContent(affectedNodes);
        },
        (err) => {
          console.error(
            `Error while loading referenced content tag data for content tag id: ${id}`
          );
          this.updateReferencedContent(resourceMap[id]);
        }
      );
    });
  }

  protected loadContentPanels(resourceMap) {
    Object.keys(resourceMap).forEach((id) => {
      return this.contentPanelService.getContentPanelById(+id).subscribe(
        (contentPanel) => {
          const affectedNodes = resourceMap[id].map((node) => {
            return {
              id: node.id,
              dataId: node.dataId,
              title: contentPanel.name,
              colour: contentPanel.colour,
            };
          });
          this.updateReferencedContent(affectedNodes);
        },
        (err) => {
          console.error(
            `Error while loading referenced content panel data for content panel id: ${id}`
          );
          this.updateReferencedContent(resourceMap[id]);
        }
      );
    });
  }

  protected loadFiles(resourceMap) {
    Object.keys(resourceMap).forEach((id) => {
      return this.filesService
        .getFileById(id)
        .then((file) => {
          if (!file || !file.id) {
            console.error(`Error while loading referenced file data for file id: ${id}`);
            return this.updateReferencedContent(resourceMap[id]);
          }
          const type = file.filename.match(/[^.]+$/g)[0];
          const src = this.filesService.resolveEmbedFileThumbnail(type);
          const affectedNodes = resourceMap[id].map((node) => {
            return {
              id: node.id,
              dataId: node.dataId,
              name: file.meta_data.name,
              title: file.meta_data.name,
              src,
            };
          });
          this.updateReferencedContent(affectedNodes);
        })
        .catch((err) => {
          console.error(`Error while loading referenced file data for file id: ${id}`);
          this.updateReferencedContent(resourceMap[id]);
        });
    });
  }

  protected loadWidgets(nodes, type) {
    const requestPath =
      type === EmbeddedType.ThirdPartyWidget ? 'third-party-widgets' : 'system-widgets';
    return this.rest.get(requestPath).subscribe(
      ({ data }) => {
        const updatedNodes = nodes.map((node) => {
          const widget = data.find((wt) => wt.id === node.dataId);
          if (!widget) {
            console.error(
              `Error while loading referenced widget data for widget id: ${node.dataId}`
            );
            return node;
          }
          const src =
            node.type === EmbeddedType.SystemWidget
              ? './assets/img/custom-icons/glideCustomIcon_system-widgets.svg'
              : './assets/img/custom-icons/glideCustomIcon_third-party-widgets.svg';
          return {
            id: node.id,
            dataId: node.dataId,
            title: widget.name,
            src,
          };
        });
        this.updateReferencedContent(updatedNodes);
      },
      (err) => {
        console.error(`Error while loading referenced widget type data.`);
        this.updateReferencedContent(nodes);
      }
    );
  }

  protected loadImageUrlData(resourceMap) {
    Object.keys(resourceMap).forEach((id) => {
      return loadImageUrl(id)
        .then(() => {
          const affectedNodes = resourceMap[id].map((node) => {
            return {
              ...node,
              src: node.dataUrl,
            };
          });
          this.updateReferencedContent(affectedNodes);
        })
        .catch(() => {
          const nodes = resourceMap[id].map((node) => {
            console.error('Error while loading image from url:\n', node.dataUrl);
            return {
              ...node,
              src: '/assets/img/embed-image-url-gray.svg',
            };
          });
          this.updateReferencedContent(nodes);
        });
    });
  }

}



function getNewReferencedContent(body, cachedRefContent) {
  const referencedContentTypes = Object.values(ReferencedContentType);
  const nodes = body.contentNodes || {};
  const referencedContent = Object.values(nodes)
    // TODO revisit this cast to any after ng9 update
    .filter(
      (node: ContentNode) =>
        node.dataId &&
        referencedContentTypes.includes(node.type as any) &&
        shouldLoadElement(node, cachedRefContent)
    )
    .map((item: ContentNode) => ({ ...item }));

  return referencedContent;
}

function shouldLoadElement(element, referencedContent) {
  if (!referencedContent) {
    return true;
  }
  return !referencedContent[element.id] || referencedContent[element.id].dataId !== element.dataId;
}

function createResourceMap(nodes) {
  /**
   * Resource Map Template: { resourceId: [resourceNode1, resourceNode2] }
   * Resource Map Example (type: gallery): { 2: [galleryNode1, galleryNode2, ...] }
   */
  const resourceIds = Object.keys(
    nodes.reduce((acc, node) => ({ ...acc, [node.dataId]: true }), {})
  );
  const resourceMap = resourceIds.reduce((acc, id) => {
    return { ...acc, [id]: nodes.filter(({ dataId }) => (+dataId || dataId) === (+id || id)) };
  }, {});
  return resourceMap;
}
