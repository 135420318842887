import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { ExternalApp } from '../../store/external-apps/external-apps.model';

@Injectable()
export class ExternalAppsService {
  data = [
    {
      id: 1,
      name: `External App Test`,
      description: 'External App Test Description',
      url: 'https://nonprod-media-glidecloud1.s3.eu-west-1.amazonaws.com/ext-page-test.html',
      position: 0
    } as ExternalApp
  ];

  constructor(private rest: RestService) {}

  getExternalApps({ name }: any = {}) {
    let requestPath = `external-apps`;
    requestPath += name ? `?name=${encodeURIComponent(name)}` : '';
    return this.rest.get(requestPath).pipe(map(response => {
      return response.data.sort((a, b) => (+a.position || 0) - (+b.position || 0))
    }));
  }

  getExternalApp(id) {
    return this.rest.get('external-apps/' + id).pipe(map((response) => response.data));
  }

  createExternalApp(payload) {
    return this.rest.post('external-apps', payload).pipe(map((response) => response.data));
  }

  updateExternalApp(app) {
    return this.rest.put('external-apps/' + app.id, app).pipe(map((response) => response.data));
  }

  deleteExternalApp(id) {
    return this.rest.delete(`external-apps/${id}`);
  }

  updateExternalAppsPositions(apps) {
    return this.rest.put('external-apps', apps).pipe(map((response) => response.data));
  }
}
