export const i18nSidenavLabels = {
  Dashboard: $localize `Dashboard`,
  Articles: $localize `Articles`,
  Collections: $localize `Collections`,
  LiveReporting: $localize `Live Reporting`,
  Configure: $localize `Configure`,
  Media: $localize `Media`,
  Images: $localize `Images`,
  Galleries: $localize `Galleries`,
  Files: $localize `Files`,
  Transmit: $localize `Transmit`,
  Subscribers: $localize `Event Subscribers`,
  Taxonomies: $localize `Taxonomies`,
  SiteBuilder: $localize `Site Builder`,
  Menus: $localize `Menus`,
  Pages: $localize `Pages`,
  Templates: $localize `Templates`,
  Routing: $localize `Routing`,
  PageRoutes: $localize `Page Routes`,
  Redirects: $localize `Redirects`,
  SystemWidgets: $localize `System Widgets`,
  ThirdPartyWidgets: $localize `Third Party Widgets`,
  HTMLSnippets: $localize `HTML Snippets`,
  ArticleTypes: $localize `Article Types`,
  TaxonomyConfiguration: $localize `Taxonomy Configuration`,
  ContentPanels: $localize `Content Panels`,
  CollectionTypes: $localize `Collection Types`,
  ContentTags: $localize `Content Tags`,
  CustomFieldGroups: $localize `Custom Field Groups`,
  Users: $localize `Users`,
  Authors: $localize `Authors`,
  Roles: $localize `Roles`,
  SSO: $localize `SSO`,
  Accounts: $localize `Accounts`,
  Settings: $localize `Settings`,
  ContentLocales: $localize `Internationalisation`,
  Notifications: $localize `Notifications`,
  SystemNotifications: $localize `System`,
  LiveReportTypes: $localize `Live Report Types`,
  ExternalApps: $localize `External Apps`,
}
