<div class="gd-field-builder__component-wrapper"
[dir]="dir$ | async">
<!-- media CMS data inputs, like images and galleries -->
<ng-container *ngIf="isMediaWidget">
  <section [class.--read-only-simple-input]="readOnlyMode">
    <gd-media-widget-configuration [fieldControl]="typecastAbstractToFormControl(fieldControl)"
                                   [mediaType]="cmsDataSourceType"
                                   [fieldConfig]="fieldConfig"
                                   [hasActionPermission]="hasActionPermission"
                                   [contentLocaleId]="activeLocale?.id"
                                   [readOnlyMode]="readOnlyMode"></gd-media-widget-configuration>
  </section>
</ng-container>

<!-- all non media custom fields, which is determined by fieldType -->
<ng-container [ngSwitch]="fieldConfig.fieldType"
              *ngIf="!isMediaWidget">

  <!-- simple input fields of various types -->
  <ng-container *ngSwitchCase="'input'">

    <!-- simple input -->
    <section [class.--read-only-simple-input]="readOnlyMode"
             *ngIf="fieldConfig.inputType!=='date' && fieldConfig.inputType!== 'number' && fieldConfig.inputType!=='datetime-local' && fieldConfig.inputType!=='toggle'  && fieldConfig.inputType!=='radio-button' && fieldConfig.inputType!=='checkbox' && fieldConfig.inputType!=='colour-picker'">
      <mat-form-field [style.width.%]="width"
                      [floatLabel]="readOnlyMode ? 'always' : 'auto'"
                      appearance="outline">
        <mat-label>{{ fieldConfig?.label }}</mat-label>
        <input matInput
               [type]="fieldConfig?.inputType || 'text'"
               [formControl]="getFormControl()"
               autocomplete="new-password"
               [required]="isFormControlRequired"
               [readonly]="readOnlyMode"
               (keydown.enter)="$event.preventDefault()" />
        <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
                  style="color: #8e8e8e; font-size: 24px"
                  class="question-icon"
                  [matTooltip]="infoTooltip"
                  matTooltipPosition="above"
                  matSuffix
                  fontSet="fal"
                  fontIcon="fa-question-circle">
        </mat-icon>
        <mat-error>
          <span *ngIf="fieldControl.hasError('required')">{{getErrorMessage('required')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('minlength')">{{getErrorMessage('minLength')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('maxlength')">{{getErrorMessage('maxLength')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('min')">{{getErrorMessage('minValue')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('max')">{{getErrorMessage('maxValue')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('pattern')">{{getErrorMessage('pattern')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('email')">{{getErrorMessage('email')}}&nbsp;</span>
        </mat-error>
      </mat-form-field>
    </section>

    <!-- number input-->
    <section *ngIf="fieldConfig.inputType === 'number'" [class.--read-only-simple-input]="readOnlyMode">
      <mat-form-field [style.width.%]="width"
                      appearance="outline"
                      class="gd-field-builder__number-wrapper"
                      [floatLabel]="readOnlyMode ? 'always' : 'auto'"
                      [class.--error-field]="(fieldControl.invalid &&
                                              fieldControl.touched &&
                                              !numericInputControl.touched &&
                                              fieldConfig.key !== 'localisedDefaultValue')">
        <mat-label>{{ fieldConfig?.label }}</mat-label>
        <input matInput
               [type]="fieldConfig?.inputType || 'number'"
               [formControl]="getFormControl()"
               autocomplete="new-password"
               [required]="isFormControlRequired"
               (keydown.enter)="$event.preventDefault()"
               [readonly]="readOnlyMode"/>
        <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
                  style="color: #8e8e8e; font-size: 24px"
                  class="question-icon"
                  [matTooltip]="infoTooltip"
                  matTooltipPosition="above"
                  matSuffix
                  fontSet="fal"
                  fontIcon="fa-question-circle">
        </mat-icon>
        <mat-error>
          <span *ngIf="fieldControl.hasError('required')">{{getErrorMessage('required')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('minlength')">{{getErrorMessage('minLength')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('maxlength')">{{getErrorMessage('maxLength')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('min')">{{getErrorMessage('minValue')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('max')">{{getErrorMessage('maxValue')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('pattern')">{{getErrorMessage('pattern')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('email')">{{getErrorMessage('email')}}&nbsp;</span>
        </mat-error>
      </mat-form-field>
    </section>

    <!-- date input -->
    <section *ngIf="fieldConfig.inputType === 'date'"
             [class.--read-only-simple-input]="readOnlyMode">
      <mat-form-field appearance="outline"
                      class="gd-field-builder__date-wrapper"
                      [floatLabel]="readOnlyMode ? 'always' : 'auto'"
                      [class.--error-field]="(fieldControl.invalid && fieldControl.touched && !dateControl.touched)"
                      [class.--empty-required-field]="(!dateControl.value && fieldControl.hasError('required') && dateControl.touched) && !dateControl.hasError('matDatepickerParse')">
                     <mat-label>{{ fieldConfig?.label }}</mat-label>
        <input matInput
               [matDatepicker]="datePicker"
               [formControl]="dateControl"
               [placeholder]="!readOnlyMode ? moment().format(dateFormat) : ''"
               (dateChange)="updateDateInputField()"
               autocomplete="off"
               [required]="isFormControlRequired"
               [readonly]="readOnlyMode"
               (keydown.enter)="$event.preventDefault()">
        <mat-datepicker-toggle matSuffix
                               [for]="datePicker"></mat-datepicker-toggle>
        <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
                  style="color: #8e8e8e; font-size: 19px !important"
                  class="question-icon"
                  [matTooltip]="infoTooltip"
                  matTooltipPosition="above"
                  matSuffix
                  fontSet="fal"
                  fontIcon="fa-question-circle">
        </mat-icon>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error>
          <span *ngIf="dateControl.hasError('matDatepickerParse')">Invalid date
          </span>
        </mat-error>
      </mat-form-field>
    </section>

    <!-- date-time input -->
    <section *ngIf="fieldConfig.inputType === 'datetime-local'"
             class="gd-field-builder__datetime-wrapper"
             [class.--read-only-simple-input]="readOnlyMode">
      <mat-form-field appearance="outline"
                      class="--date"
                      [floatLabel]="readOnlyMode ? 'always' : 'auto'"
                      [class.--error-field]="(fieldControl.invalid && fieldControl.touched && !dateControl.touched)"
                      [class.--empty-required-field]="(!dateControl.value && fieldControl.hasError('required') && dateControl.touched) && !dateControl.hasError('matDatepickerParse')">
        <mat-label>{{ fieldConfig?.label }}</mat-label>
        <input matInput
               [matDatepicker]="datePicker"
               [placeholder]="!readOnlyMode ? moment().format(dateFormat) : ''"
               [formControl]="dateControl"
               (dateChange)="updateDatetimeInputField()"
               autocomplete="off"
               [required]="isFormControlRequired"
               [readonly]="readOnlyMode"
               (keydown.enter)="$event.preventDefault()">
        <mat-datepicker-toggle matSuffix
                               [for]="datePicker"></mat-datepicker-toggle>
        <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
                  style="color: #8e8e8e; font-size: 19px !important"
                  class="question-icon"
                  [matTooltip]="infoTooltip"
                  matTooltipPosition="above"
                  matSuffix
                  fontSet="fal"
                  fontIcon="fa-question-circle">
        </mat-icon>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error>
          <span *ngIf="dateControl.hasError('matDatepickerParse')">Invalid date
          </span>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="--time"
                      appearance="outline">
        <input matInput
               type="time"
               [formControl]="timeControl"
               autocomplete="off"
               (change)=updateDatetimeInputField()
               [readonly]="readOnlyMode"
               (keydown.enter)="$event.preventDefault()">
      </mat-form-field>
    </section>

    <!-- toggle input, for simple boolean values -->
    <section *ngIf="fieldConfig.inputType === 'toggle'"
             [class.--read-only-simple-input]="readOnlyMode"
             style="margin-bottom: 20px;">
      <div style="display: flex; flex-direction: row; align-items: center; gap: 6px;">
        <label class="gd-configuration__label">{{ fieldConfig?.label }}</label>
          <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
                    style="color: #8e8e8e; font-size: 24px"
                    class="question-icon"
                    [matTooltip]="infoTooltip"
                    matTooltipPosition="above"
                    matSuffix
                    fontSet="fal"
                    fontIcon="fa-question-circle">
          </mat-icon>
      </div>
      <div class="gd-field-builder__slide-toggle"
           [class.--read-only]="readOnlyMode">
        <mat-slide-toggle [style.width.%]="width"
                          [formControl]="typecastAbstractToFormControl(fieldControl)"
                          appearance="outline"
                          name="enableWifi"
                          class="gd-field-builder-element-spacing">{{ customDataLabel }}</mat-slide-toggle>
      </div>
    </section>

    <!-- radio button group input -->
    <section *ngIf="fieldConfig.inputType === 'radio-button'"
             style="margin-bottom: 20px;"
             class="gd-field-builder__radio-btn-group">
      <gd-radio-button-configuration [fieldControl]="fieldControl"
                                     [fieldConfig]="fieldConfig"
                                     [label]="fieldConfig?.label"
                                     [readOnlyMode]="readOnlyMode"></gd-radio-button-configuration>
    </section>

    <!-- checkbox group input -->
    <section *ngIf="fieldConfig.inputType === 'checkbox'"
             style="margin-bottom: 20px;">
      <gd-checkbox-configuration [fieldControl]="fieldControl"
                                 [fieldConfig]="fieldConfig"
                                 [label]="fieldConfig?.label"
                                 [readOnlyMode]="readOnlyMode"></gd-checkbox-configuration>
    </section>

    <!-- color picker input -->
    <section *ngIf="fieldConfig.inputType === 'colour-picker'"
             style="margin-bottom: 20px;">
      <gd-color-picker-configuration [fieldControl]="fieldControl"
                                     [fieldConfig]="fieldConfig"
                                     [label]="fieldConfig?.label"
                                     [direction]="dir$ | async"
                                     [readOnlyMode]="readOnlyMode">
      </gd-color-picker-configuration>
    </section>

  </ng-container>

  <!-- standard textarea input field -->
  <ng-container *ngSwitchCase="'textarea'">
    <section [class.--read-only-simple-input]="readOnlyMode">
      <mat-form-field [floatLabel]="!!fieldConfig?.label ? 'always' : 'never'"
                      [style.width.%]="width"
                      appearance="outline">
        <mat-label>{{ fieldConfig?.label }}</mat-label>
        <textarea matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="2"
                  [formControl]="typecastAbstractToFormControl(fieldControl)"
                  [required]="isFormControlRequired"
                  [readonly]="readOnlyMode"
                  (keydown.enter)="$event.preventDefault()">
      </textarea>
        <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
                  style="color: #8e8e8e; font-size: 24px"
                  class="question-icon"
                  [matTooltip]="infoTooltip"
                  matTooltipPosition="above"
                  matSuffix
                  fontSet="fal"
                  fontIcon="fa-question-circle">
        </mat-icon>
        <mat-error>
          <span *ngIf="fieldControl.hasError('required')">{{getErrorMessage('required')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('minlength')">{{getErrorMessage('minLength')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('maxlength')">{{getErrorMessage('maxLength')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('min')">{{getErrorMessage('minValue')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('max')">{{getErrorMessage('maxValue')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('pattern')">{{getErrorMessage('pattern')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('email')">{{getErrorMessage('email')}}&nbsp;</span>
        </mat-error>
      </mat-form-field>
    </section>

  </ng-container>

  <!-- modified textarea - basically different framing and monospace font for improved code readability -->
  <ng-container *ngSwitchCase="'snippet'">
    <section [class.--read-only-snippet]="readOnlyMode">
      <mat-form-field [floatLabel]="!!fieldConfig?.label ? 'always' : 'never'"
                      [style.width.%]="width"
                      appearance="fill"
                      class="gd-field-builder__snippet-field">
        <mat-label>{{ fieldConfig?.label }}</mat-label>
        <textarea matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="12"
                  cdkAutosizeMaxRows="42"
                  [formControl]="typecastAbstractToFormControl(fieldControl)"
                  style="font-family: monospace"
                  [required]="isFormControlRequired"
                  [readonly]="readOnlyMode"
                  (keydown.enter)="$event.preventDefault()">
        </textarea>
        <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
                  style="color: #8e8e8e; font-size: 24px"
                  class="question-icon"
                  [matTooltip]="infoTooltip"
                  matTooltipPosition="above"
                  matSuffix
                  fontSet="fal"
                  fontIcon="fa-question-circle">
        </mat-icon>
        <mat-error>
          <span *ngIf="fieldControl.hasError('required')">{{getErrorMessage('required')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('minlength')">{{getErrorMessage('minLength')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('maxlength')">{{getErrorMessage('maxLength')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('min')">{{getErrorMessage('minValue')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('max')">{{getErrorMessage('maxValue')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('pattern')">{{getErrorMessage('pattern')}}&nbsp;</span>
          <span *ngIf="fieldControl.hasError('email')">{{getErrorMessage('email')}}&nbsp;</span>
        </mat-error>
      </mat-form-field>
    </section>
  </ng-container>

  <!-- NOTE dataSource from fieldConfig defines which data select and autocomplete will use -->
  <!-- select component for CMS, custom and external data -->
  <div *ngIf="ready">
  <ng-container *ngSwitchCase="'select'">
    <gd-select-field-configuration [fieldConfig]="fieldConfig"
                                   [fieldControl]="typecastAbstractToFormControl(fieldControl)"
                                   [width]="width"
                                   *ngIf="ready"
                                   [hasActionPermission]="hasActionPermission"
                                   [readOnlyMode]="readOnlyMode">
    </gd-select-field-configuration>
  </ng-container>
</div>
  <!-- autocomplete component for CMS, custom and external data -->
  <div *ngIf="ready" [class.--read-only-simple-input]="readOnlyMode">
    <ng-container *ngSwitchCase="'autocomplete'">
      <gd-autocomplete-field-configuration [fieldConfig]="fieldConfig"
                                           [fieldControl]="typecastAbstractToFormControl(fieldControl)"
                                           [width]="width"
                                           [hasActionPermission]="hasActionPermission"
                                           [setFocus]="setFocus"
                                           [isContentPublished]="isContentPublished"
                                           [isAllowedTaxonomiesField]="isAllowedTaxonomiesField"
                                           [usage]="usage"
                                           *ngIf="ready"
                                           [activeLocale]="activeLocale"
                                           [readOnlyMode]="readOnlyMode">
      </gd-autocomplete-field-configuration>
    </ng-container>
  </div>

  <!-- value list, for defining list of custom strings -->
  <ng-container *ngSwitchCase="'valuelist'">
    <gd-text-value-list [fieldControl]="fieldControl"
                        [separatorKeysCodes]="separatorKeysCodes"
                        [fieldConfig]="fieldConfig"
                        [hasUpdatePermissionFlag]="true"
                        [readOnlyMode]="readOnlyMode">
    </gd-text-value-list>
  </ng-container>

  <!-- rich text editor custom field -->
  <ng-container *ngSwitchCase="'texteditor'">

    <section class="gd-field-builder__text-editor">
      <div style="display: flex; flex-direction: row; align-items: center;">
        <label class="gd-configuration__label"
               [class.--error]="fieldControl.hasError('required') && fieldControl.touched">{{fieldConfig?.label}}{{isFormControlRequired ? ' *' : ''}}</label>

        <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
                  class="gd-field-builder__text-editor-icon question-icon"
                  [matTooltip]="infoTooltip"
                  matTooltipPosition="above"
                  matSuffix
                  fontSet="fal"
                  fontIcon="fa-question-circle"></mat-icon>
      </div>
      <gd-rich-text-editor class="gd-rich-text-editor gd-field-builder-element-spacing"
                           [froalaConfigOptions]="{quickInsertEnabled: false, scrollableContainer: fieldConfig['scrollableContainer'] || '.main-container'}"
                           [authorEditorButtons]="textEditorButtons"
                           (editorChange)="getFormGroupData($event)"
                           [editorData]="fieldControl.value || ''"
                           [fieldConfig]="fieldConfig"
                           [fieldControl]="fieldControl"
                           [readOnlyMode]="readOnlyMode"
                           ></gd-rich-text-editor>
      <div *ngIf="isFormControlRequired"
           class="gd-configuration__error-message"
           [class.--visible]="fieldControl.hasError('required') && fieldControl.touched">
        {{ fieldConfig.label }} is required.
      </div>
    </section>
  </ng-container>

  <ng-container *ngSwitchCase="'group'">
    <gd-field-group-builder [fieldConfig]="fieldConfig"
                            [fieldControlGroup]="typecastAbstractToFormGroup(fieldControl)"
                            [isEditingMode]="isEditingMode"
                            [hasActionPermission]="hasActionPermission"
                            [width]="width"
                            [usage]="usage"
                            [activeLocale]="activeLocale"
                            [readOnlyMode]="readOnlyMode">
    </gd-field-group-builder>
  </ng-container>


  <!-- unknown field type -->
  <div *ngIf="ready">
    <ng-container *ngSwitchDefault>
      <p i18n>Unsupported field type</p>
    </ng-container>
  </div>
</ng-container>
</div>
