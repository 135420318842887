import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, map, startWith } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRouteSnapshot, ResolveStart } from '@angular/router';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  entityTitle = new BehaviorSubject<string>(null);

  constructor(private titleService: Title, private router: Router) {
    // reset entity title on navigating away from a page
    // ResolveStart triggers after guard check, so it covers if the navigation is canceled
    this.router.events
      .pipe(filter((event) => event instanceof ResolveStart))
      .subscribe(() => {
        if(!!this.entityTitle.getValue()) {
          this.entityTitle.next(null);
        }
      });
  }

  public setPageEntityTitle(title: string) {
    this.entityTitle.next(title);
  }

  // TODO icons in the title, more page entity stuff, see about removing the `Entity _edit_` part of the title
  /**
   * This will register a handler which on router navigation end checks if the
   * most specific route resolved has a title property defined in route configuration
   * for that section (e.g. in `articles-routing.module.ts`).
   * When we have a valid title and potentially other info, we set HTML page title based
   * on it, so that tabs in browser are a bit more differentiated
   */
  registerPageTitleHandler() {
    combineLatest([
      this.entityTitle.asObservable().pipe(startWith(null)),
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)),
    ])
      .pipe(
        map(([entityTitle]) => {
          // getting route snapshot directly from the router, as injected activated route
          // stopped updating around image edit section for some reason
          const rootRoute: ActivatedRouteSnapshot = this.router.routerState.snapshot.root;
          // find the most specific active route and extract page title
          let childRoute = rootRoute.firstChild;
          while (childRoute) {
            // not the most specific active route, move along
            if (childRoute.firstChild) {
              childRoute = childRoute.firstChild;
              continue;
            }

            const hasCustomPageTitle = childRoute.data && childRoute.data['title'];
            if (!hasCustomPageTitle) {
              return null;
            }

            return {
              title: childRoute.data['title'],
              id: childRoute.params['id'] || null,
              entityTitle: entityTitle || null,
            };
          }
          return null;
        })
      )
      .subscribe((routeData) => {
        const title = get(routeData, 'title', null);
        const id = get(routeData, 'id', null);
        let entityTitle = this.getEntityTitle(routeData);

        // just use generic `GPP` page title if we have no alternative data
        if (!title) {
          this.titleService.setTitle('GPP');
          return;
        }

        // handle external app view page
        if (title === 'External App') {
          let tabName = (entityTitle || title).trim();
          this.titleService.setTitle(`${tabName} - GPP`);
          return;
        }

        /**
         * note: we're always adding the `GPP` at the end rather than the beginning to
         * show as much useful info as soon as possible. i.e. to avoid bunch of small tabs
         * just showing GPP, which isn't useful - logo icon already identifies tab as GPP
         */

        // if we don't have the ID param in the URL params, just use the title
        if (title && !id) {
          this.titleService.setTitle(`${title} - GPP`);
          return;
        }

        // if the `printInfoLogs` is true, this is one of our development environments
        // for now, we will print the ID of the entity in the page title
        // later, we will add something more sensible
        if (title && id && window.printInfoLogs) {
          const pageTitle = entityTitle
            ? `${entityTitle} | ${id} - ${title} - GPP `
            : `${title} | ${id} - GPP `;
          this.titleService.setTitle(pageTitle);
          return;
        }

        const emoji = {
          article: `\u{1F4C4}`,
          collection: `\u{1F4DA}`,
        };

        const pageTitle = entityTitle
        ? `${entityTitle} - ${title} - GPP `
        : `${title} | ${id} - GPP `;

        // TODO handle the display of content specific page titles
        this.titleService.setTitle(pageTitle);
      });
  }

  private getEntityTitle(routeData) {
    let entityTitle = get(routeData, 'entityTitle', null);
    if(!entityTitle) {
      return null;
    }

    // trim the entity title to 38 or 48 chars, depending if we also print the ID or not
    // this is because the tab title gets has its own ellipsis mechanic
    const entityTitleMaxLength = window.printInfoLogs ? 38 : 48;
    if(entityTitle.length > entityTitleMaxLength) {
      return entityTitle.slice(0, entityTitleMaxLength).trim() + '...';
    }

    return entityTitle;
  }
}
