import { get } from 'lodash-es';
import {
  ContentModel, StructureNode, EmbeddedType, ContentNode, ReferencedContentType
} from '../../../core/store/article/article-content.model';
import { generateShortId } from '../../shared-functions';

const embeddedTypes = Object.values(EmbeddedType);
const referencedContentTypes = Object.values(ReferencedContentType);

export function transformDomIntoModel(nodeList: NodeList | HTMLElement[], fullModel: ContentModel, idMap = {}, isContentPanel = false) {
  if (!nodeList || nodeList.length === 0) { return []; }

  return Array.from(nodeList)
    .filter((el: HTMLElement) => el.className !== 'gd-temp-node')
    .map((element: HTMLElement) => {
      if (!isContentPanel) {
        isContentPanel = element.tagName === 'SECTION' && EmbeddedType.ContentPanel === element.getAttribute('data-type');
      }
      // fix issues when content_panel is removed with "delete" button
      const isContentPanelChild = ['gd-cp-header', 'gd-cp-column'].some(cName => element.classList && element.classList.contains(cName));
      if (isContentPanelChild && (!element.parentElement || element.parentElement.getAttribute('data-type') !== EmbeddedType.ContentPanel)) {
        return removeElement(element);
      }
      // remove placeholders displayed after "undo" action
      if (element.tagName === 'IMG' && element.getAttribute('data-embed-type')) {
        return removeElement(element);
      }
      // rectify any duplicate nodes - this is due to face that copy pasting in froala can result in duplicate ids
      if (element.id && idMap[element.id]) {
        // TODO: resolve the issue in a better way (quick fix for the issue with nested content_panels)
        // NOTE made a update as the logic below was causing issues with list in CPs (GPP-1870)
        const isContentTagMarker = element.classList.contains('content-tag-marker');
        const isCurrentElContentPanel = element.tagName === 'SECTION' && EmbeddedType.ContentPanel === element.getAttribute('data-type');
        if (isCurrentElContentPanel || isContentTagMarker) {
          return null;
        }
        // this node using this id already exists, regenerate node id to avoid id conflicts
        element.id = generateShortId();
        return null;
      }
      // new element, add Id to it for tracking
      if (!element.id) {
        element.id = generateShortId();
      }
      // register node with this id in the id usage map
      idMap[element.id] = true;

      const type = resolveType(element);
      if (!type) {
        return null;
      }
      const { structureNode, includeChildren } = processBlockDOMToModel(element, type);
      if (!structureNode) { return null; }
      const isEmbeddedElement = embeddedTypes.includes(type);
      if (isEmbeddedElement) {
        const model: ContentNode = createContentNode(structureNode, type);
        fullModel.contentNodes[structureNode.id] = model;
      }

      // if the first child element is a embed wrapper - then we need to add 'fr-embedded' class
      // to this parent element, else it would be added later and break content-to-model idempotency
      // TODO investigate where else and who injects 'fr-embedded' class within editor
      const firstChild: HTMLElement = element.childNodes[0] as HTMLElement;
      if (firstChild && firstChild.classList && firstChild.classList.contains('gd-ember-wrapper')) {
        const embedParentParagraphClass = 'fr-embedded';
        const classAttribute = get(structureNode, 'attributes.class', '');
        if (!classAttribute.includes(embedParentParagraphClass)) {
          structureNode.attributes.class = structureNode.attributes.class
            ? (embedParentParagraphClass + ' ' + structureNode.attributes.class)
            : embedParentParagraphClass;
        }

      }

      const structureFragment: StructureNode = {
        ...structureNode,
        parentId: element.parentElement.id,
        childNodes: includeChildren ? transformDomIntoModel(element.childNodes, fullModel, idMap, isContentPanel) : []
      };

      structureFragment.childNodes = structureFragment.childNodes.filter(node => node);

      return structureFragment;
    })
    .filter(node => node);
}

function resolveType(element: HTMLElement): any {
  if (!element.tagName && (element.textContent || element.nodeName === '#text')) {
    return 'content/text';
  }

  const tagName = element.tagName;
  const dataType: any = element.getAttribute('data-type');
  if (tagName === 'SPAN') {
    const mentionElements = [EmbeddedType.ArticleMention, EmbeddedType.PostMention, EmbeddedType.TaxonomyMention];
    return dataType && !mentionElements.includes(dataType) ? dataType : 'content/text';

  }
  if (tagName === 'A') {
    const mentionType = element.parentElement.tagName === 'SPAN' && element.parentElement.getAttribute('data-type');
    return mentionType || EmbeddedType.Link;

  }
  if (tagName === 'DIV') {
    const iFrame = element.classList.contains('fr-embedly') && EmbeddedType.Url;
    return iFrame || 'content/text';
  }
  if (tagName === 'SECTION') {
    return dataType || 'content/text';
  }

  if (tagName === 'EDITOR-WIDGET-CARD-FRAME') {
    return dataType;
  }

  return 'content/text';
}

function mapAttributes(attributes) {
  if (!attributes || (attributes && attributes.length === 0)) {
    return {};
  }

  const attrValues: Attr[] = Object.values(attributes);
  const res: any = attrValues.reduce((acc, attr) => {
    acc[attr.name] = attr.value;
    return acc;
  }, {});

  // if these classes are detected on the element
  // they should be removed as they are only runtime relevant
  // i.e. we or froala insert them for temporary use
  const volatileClasses = [
    'fr-selected-cell',
    'gd-content-loading',
    '--bg-contain',
    'widget-loading',
    'one-column-preview',
    'two-column-preview'
  ];

  // remove classes that can trip up function for checking if there
  // are any changes to content models from the pristine values
  if (res.class) {
    // TODO consider optimizing this so that it isn't invoked for every element with class
    res.class = res.class
    .split(/\s+/)
    .filter(cl => !volatileClasses.includes(cl))
    .join(' ')
    .trim();
    if (res.class === '') {
      delete res.class;
    }
  }

  return res;
}

function createContentNode(structureNode, type) {
  const isLinkOrMention = [EmbeddedType.Link, EmbeddedType.ArticleMention, EmbeddedType.PostMention, EmbeddedType.TaxonomyMention].includes(type);
  const model: ContentNode = {
    id: structureNode.id,
    type,
    src: referencedContentTypes.includes(type) ? null : structureNode.attributes['src'],
    authorUsername: structureNode.attributes['data-author-username'],
    alignment: structureNode.attributes['alignment'],
    dataId: structureNode.attributes['data-id'],
    liveReportId: type === EmbeddedType.PostMention && structureNode.attributes['data-live-report-id'],
    dataUrl: type === EmbeddedType.Link && structureNode.attributes['href'],
    target: type === EmbeddedType.Link && structureNode.attributes['target'],
    innerText: structureNode.saveInnerText && structureNode.innerText,
    innerHTML: type === EmbeddedType.Link ? structureNode.innerHTML : '',
    name: type === EmbeddedType.TaxonomyMention ? structureNode.name : '',
    slug: type === EmbeddedType.TaxonomyMention ? structureNode.slug : '',
    htmlAttributes: isLinkOrMention ?
      {
        rel: structureNode.attributes['rel'],
        target: structureNode.attributes['target'],
      } : null,
  };

  // to do: find better way for cleaning up htmlAttributes property
  if (model.htmlAttributes) {
    Object.keys(model.htmlAttributes).forEach(key => {
      return model.htmlAttributes[key] || delete model.htmlAttributes[key];
    });

    if (Object.keys(model.htmlAttributes).length === 0) {
      delete model['htmlAttributes'];
    }
  }

  Object.keys(model).forEach(key => model[key] || delete model[key]);

  // TODO this should be thrown out once content node data is in the body
  // if we are dealing with link we preserve these to avoid them being overridden by existing values
  if(type === EmbeddedType.Link) {
    if(!model.htmlAttributes) {
      model.htmlAttributes = null;
    }
    if(!model.target) {
      model.target = null;
    }
  }

  if (type === EmbeddedType.SystemWidget || type === EmbeddedType.ThirdPartyWidget) {
    delete structureNode.attributes['data-id'];
  }

  return { ...model, isInline: structureNode.attributes['isInline'] };
}


export function transformModelIntoDOM(structure, fullModel, referencedContent, usage = 'editor') {
  if (!structure) { return ''; }
  referencedContent = referencedContent || {};
  return structure
    .reduce((acc, el) => {
      const contentNode = fullModel.contentNodes[el.id] || {};
      let elementModel = { ...el, ...contentNode };
      // This is a text node, so just return the text it contains
      if (elementModel.tagName === 'TEXT') {
        return acc + elementModel.innerText;
      }

      let htmlEl = null;
      let isWidget = elementModel.type === EmbeddedType.SystemWidget || elementModel.type === EmbeddedType.ThirdPartyWidget;

      if (elementModel.tagName === 'IMG' && isWidget) {
        htmlEl = document.createElement('editor-widget-card-frame');
        const alignClassMap = { left: 'fr-fil', right: 'fr-fir', center: 'fr-fic' };
        const alignClass = alignClassMap[elementModel.alignment || 'center']
        const displayClass = elementModel.isInline ? 'fr-dii' : 'fr-dib';
        const elementClass = `gd-embedded fr-embedded gd-ember-wrapper fr-draggable --bg-contain ${displayClass} ${alignClass}`;
        const html = `<editor-widget-card-frame contenteditable="false" data-id=${+elementModel.dataId} data-type=${
          elementModel.type
        } id=${elementModel.id} contenteditable="false" title='${elementModel.attributes?.title || elementModel.title}'
        class='${elementClass}' style="min-height: 345px;" usage='${usage}'></editor-widget-card-frame>`;
        return acc + html;
      }

      htmlEl = document.createElement(elementModel.tagName);

      if (elementModel.id) {
        htmlEl.setAttribute('id', elementModel.id);
      }

      const isReferencedElement = referencedContentTypes.includes(elementModel.type);
      if (isReferencedElement) {
        const refEl = referencedContent[elementModel.id] || {};
        elementModel = { ...refEl, ...elementModel };
        elementModel.src = (!refEl.isNotLoaded && refEl.src) || getPlaceholderByReferencedContentType(elementModel.type);
        elementModel.href = refEl.dataUrl;
        elementModel.innerText = contentNode.innerText || refEl.innerText || elementModel.innerText;
      }

      const isEmbeddedElement = embeddedTypes.includes(elementModel.type);
      elementModel.attributes = isEmbeddedElement ? patchElementAttributes(elementModel) : elementModel.attributes;

      Object.entries(elementModel.attributes).forEach(([attrName, attrVal]) => htmlEl.setAttribute(attrName, attrVal));

      // loading effect not needed for internal links (mentions)
      const mentionElements = [EmbeddedType.ArticleMention, EmbeddedType.PostMention, EmbeddedType.TaxonomyMention];
      // NOTE: content panel and content tag should have a loading effect but it's removed temporarily to prevent a flashing issue in the article body
      const preventLoadingEffect = [EmbeddedType.ContentPanel, EmbeddedType.ContentTag].includes(elementModel.type)
      if (isReferencedElement && !mentionElements.includes(elementModel.type) && !preventLoadingEffect) {
        const isContentLoading = isReferencedElement && elementModel.dataId
          && (!referencedContent[elementModel.id] || referencedContent[elementModel.id].isNotLoaded);
        isContentLoading ? htmlEl.classList.add('gd-content-loading') : htmlEl.classList.remove('gd-content-loading');
      }

      // style parent span of mention elements
      const dataTypeAttribute = elementModel.attributes['data-type'];
      const isMentionSpan = elementModel.tagName
        && elementModel.tagName.toLowerCase() === 'span'
        && elementModel.attributes
        && mentionElements.includes(dataTypeAttribute);
      if (isMentionSpan) {
        const referencedMention = referencedContent[elementModel.childNodes[0].id] || { state: '' };
        const state = referencedMention?.error || referencedMention.state;
        elementModel.attributes = { ...elementModel.attributes, state };
        // remove pre-existing content state classes from the classList
        htmlEl.classList.remove("gd-content-published");
        htmlEl.classList.remove("gd-content-scheduled");
        htmlEl.classList.remove("gd-content-unpublished");
        htmlEl.classList.remove("gd-content-error");
        // add the current content state class if it is not present in the classList
        if (!htmlEl.classList.contains(`gd-content-${state?.status}`)) {
          htmlEl.classList.add(`gd-content-${state?.status}`);
        }
      }

      if (mentionElements.includes(elementModel.type)) {
        return acc + getHTMLForMention(htmlEl, elementModel);
      }

      if (elementModel.type === EmbeddedType.AnchorLink) {
        return acc + getHTMLForAnchorLink(htmlEl, elementModel);
      }

      if (isEmbeddedElement && elementModel.tagName === 'IMG') {
        return acc + getHtmlForEmbeddedElement(htmlEl, elementModel);
      }

      if (elementModel.type === EmbeddedType.Url) {
        return acc + getHtmlForEmbeddedUrl(elementModel);
      }
      let additionalHtml = '';
      if (elementModel.type === EmbeddedType.ContentPanel) {
        additionalHtml += getHtmlForContentPanelHeader(elementModel);
      }
      if (elementModel.type === EmbeddedType.ContentTag) {
        const color = elementModel.colour || '#006694';
        const style = `color: ${color}; border-color: ${color}`;
        htmlEl.setAttribute('style', style);
        htmlEl.setAttribute('id', elementModel.id);
        additionalHtml += getHtmlForTagMarkerDetails(elementModel);
      }
      const hasChildren = elementModel.childNodes && elementModel.childNodes.length > 0;

      // TODO perhaps only handle cases other than primary table header
      if (htmlEl.tagName === 'TR' && hasChildren) {
        const isHeaderTableRow = (elementModel.childNodes || [])
          .filter(cn => cn.tagName !== 'TEXT')
          .every(cn => cn.tagName === 'TH');
        if (isHeaderTableRow) {
          const tempId = elementModel.id || htmlEl.id || generateShortId();
          elementModel.childNodes = [
            ... elementModel.childNodes,
            {
              id: tempId,
              childNodes: [],
              attributes: { id: tempId, style: 'display: none', class: 'gd-temp-node' },
              parentId: elementModel.id,
              tagName: 'TD',
              innerText: ''
            }
          ];
        }
      }

      htmlEl.innerHTML = additionalHtml + (hasChildren
        ? transformModelIntoDOM(elementModel.childNodes, fullModel, referencedContent, usage)
        : elementModel.innerText);
      return acc + htmlEl.outerHTML;
    }, '');
}

function patchElementAttributes(element) {
  const attributes = element.attributes;
  if (element.src) {
    attributes['src'] = element.src;
  }
  if (element.title || element.name) {
    attributes['title'] = element.title || element.name;
  }
  if (element.href) {
    attributes['href'] = element.href;
  }
  return attributes;
}

function processBlockDOMToModel(element, type) {
  switch (type) {
    case 'content/text':
    case EmbeddedType.Note:
    case EmbeddedType.ContentPanel:
    case EmbeddedType.ContentTag:
      return htmlBlockTransformDOMToModel(element, type);
    case EmbeddedType.Link:
    case EmbeddedType.ArticleMention:
    case EmbeddedType.PostMention:
    case EmbeddedType.TaxonomyMention:
      return linkBlockTransformDOMToModel(element, type);
    case EmbeddedType.Url:
      return embeddedUrlTransformDOMToModel(element);
    case EmbeddedType.SystemWidget:
    case EmbeddedType.ThirdPartyWidget:
      return widgetEmbedTransformDOMToModel(element);
    default:
      return embeddedBlockTransformDOMToModel(element, type);
  }
}

function embeddedBlockTransformDOMToModel(element, type, alignment = null, isInline = null) {

  const hasChildren = !!element.childNodes.length;
  if (!hasChildren) {
    return { structureNode: null, includeChildren: false };
  }
  alignment = alignment || getBlockAlignment(element);
  isInline = isInline === null ? element.classList.contains('fr-dii') : isInline;

  const childNodes = Array.from(element.childNodes);
  const imgEl: any = childNodes.find((el: HTMLElement) => el.tagName === 'IMG');
  if (hasChildren && !imgEl) {
    return embeddedBlockTransformDOMToModel(element.childNodes[0], type, alignment, isInline);
  }
  const captionEl: any = childNodes.find((el: HTMLElement) => el.tagName === 'SPAN');
  const attributes = { ...mapAttributes(imgEl.attributes), alignment, isInline };
  const structureNode = {
    id: imgEl.id,
    tagName: imgEl.tagName,
    attributes,
    innerText: '',
  };
  const isReferencedElement = referencedContentTypes.includes(type);
  if (isReferencedElement) {
    delete (structureNode.attributes as any).src;
  }
  return { structureNode, includeChildren: false };
}

function linkBlockTransformDOMToModel(element, type) {
  // save inner text in case of external link or edited internal link (mention)
  const saveInnerText = !element.getAttribute('data-id') || element.classList.contains('--edited');
  const structureNode = {
    id: element.id,
    tagName: element.tagName,
    attributes: mapAttributes(element.attributes),
    innerText: type === EmbeddedType.Link ? element.innerText : getLinkText(element),
    innerHTML: type === EmbeddedType.Link ? getInnerHTMLForLink(element) : '',
    name: element.getAttribute('data-name') || '',
    slug: element.getAttribute('data-slug') || '',
    saveInnerText,
  };
  return { structureNode, includeChildren: type === EmbeddedType.Link };
}

export function getInnerHTMLForLink(element) {
  // This function removes id attributes and user gets clean HTML
  return element.innerHTML.replace(/( id="(.*?)(\"))/g, '');
}

function embeddedUrlTransformDOMToModel(element) {
  const attributes: any = mapAttributes(element.attributes);
  const childEl = element.childNodes[0];
  attributes.src = childEl.tagName === 'A' && childEl.getAttribute('href');
  const structureNode = {
    id: element.id,
    tagName: element.tagName,
    attributes,
  };
  return { structureNode, includeChildren: false };
}

function getLinkText(link) {
  if (link.childNodes.length === 0) {
    return link.textContent;
  }
  return getLinkText(link.childNodes[0]);
}

function getBlockAlignment(block) {
  if (block.classList.contains('fr-fil')) {
    return 'left';
  }
  if (block.classList.contains('fr-fir')) {
    return 'right';
  }
  return 'center';
}

function htmlBlockTransformDOMToModel(element, type) {
  if (element.classList && element.classList.contains('gd-cp-header')) {
    return { structureNode: null, includeChildren: false };
  }
  const childNodes = element.childNodes;

  const hasChildren = childNodes && childNodes.length !== 0;

  // Froala creates newlines by making a P > BR structure without text
  // we also check that the text is an empty string, as having something written
  // means that is no longer just a simple newline snippet
  const isNewLine = hasChildren
    && element.childElementCount === 1
    && element.textContent === ''
    && element.tagName === 'P'
    && element.children[0].tagName === 'BR';

  let includeChildren = hasChildren && !isNewLine;

  const tagName = element.tagName || 'TEXT';
  let innerText;
  const isSimpleHtmlBlock = tagName !== 'TEXT' && includeChildren && childNodes.length === 1 && !childNodes[0].tagName;
  if (isSimpleHtmlBlock) {
    innerText = childNodes[0].textContent;
    includeChildren = false;
  } else {
    innerText = includeChildren ? '' : (element.innerText || element.textContent || '');
    innerText = validateInnerText(innerText);
  }

  const isContentTagMarker = element.classList && element.classList.contains('content-tag-marker');
  includeChildren = isContentTagMarker ? false : includeChildren;

  if (tagName === 'G' || (tagName === 'TEXT' && innerText === '')) {
    return { structureNode: null, includeChildren: false };
  }

  const structureNode = {
    id: element.id,
    tagName,
    attributes: mapAttributes(element.attributes),
    innerText
  };

  // normalize - html should not contain meaningful content with these values
  if (structureNode.innerText === '\n') {
    structureNode.innerText = '';
  }

  return { structureNode, includeChildren };
}

function validateInnerText(innerText) {
  const isValidInnerText = !encodeURIComponent(innerText).includes('%E2%80%8B');
  if (isValidInnerText) { return innerText; }
  return innerText.split('').filter(char => encodeURIComponent(char) !== '%E2%80%8B').join('');
}

function getHtmlForEmbeddedElement(element, model) {
  const iconSizeClass = model.src.includes('/images/original') ? ' --is-icon' : '';
  const alignClassMap = { left: 'fr-fil', right: 'fr-fir', center: 'fr-fic' };
  isPlaceholderImage(model) ? element.classList.add('--bg-contain') : element.classList.remove('--bg-contain');
  const parentClass = `fr-img-caption gd-ember-wrapper ${alignClassMap[model.alignment || 'center']} `
    + (model.isInline ? 'fr-dii' : 'fr-dib');
  const additionalClass = '--' + model.type.split('/')[1];
  const elClass = isPlaceholderImage(model) ? parentClass : parentClass + ' ' + additionalClass;

  const isWidgetWithLabel = model.configuration && !!model.configuration.label
    && [EmbeddedType.SystemWidget, EmbeddedType.ThirdPartyWidget].includes(model.type);
  const widgetClass = isWidgetWithLabel ? ' --widget' : '';

  let html = '<span data-type="' + model.type + '" contenteditable="false" class="' + elClass + widgetClass + iconSizeClass + '" draggable="false">';
  html += '<span class="fr-img-wrap gd-ember-wrapper' + widgetClass + '">' + element.outerHTML;

  // figure out if we'll display the label under the embed, and add HTML for it
  const captionTypeList = [
    EmbeddedType.Image,
    EmbeddedType.File,
    EmbeddedType.SystemWidget,
    EmbeddedType.ThirdPartyWidget,
    EmbeddedType.ImageURL
  ];
  const caption = model.caption || model.title
    || (model.sourceMeta && (model.sourceMeta.caption || model.sourceMeta.title));
  const displayCaption = caption && captionTypeList.includes(model.type);
  if (displayCaption) {
    const widgetLabel = isWidgetWithLabel
      ? `<span style="color: dimgrey; padding-top:0px;">${model.configuration.label?.replace(/<[^>]*>/g, '')}</span>`
      : '';
    const captionCssClass = `fr-inner gd-image-caption ${model.title ? '--widget' : ''}`;
    html += `<span class="${captionCssClass}" contenteditable="false">`
      + caption + '</span>' + widgetLabel;
  }

  // figure out if the credit in the top right corner of the embed should be displayed
  // and create the caption HTML block
  const creditTypeList = [EmbeddedType.Image, EmbeddedType.ImageURL];
  let credit = model.credit || (model.sourceMeta && model.sourceMeta.credit);
  const displayCredit = credit
    && creditTypeList.includes(model.type)
    && (model.isNotLoaded || !isPlaceholderImage(model));
  if (displayCredit) {
    try {
      credit = JSON.parse(credit) || '';
    } catch (error) {
      // use the initial value as fallback in case of error with json parse
    }

    if (credit.length) {
      const creditCssClass = `gd-image-credit ${model.isNotLoaded ? '--hidden' : ''}`;
      html += `<i class="${creditCssClass}" contenteditable="false">${credit}</i>`;
    }
  }

  html += '</span></span>';
  return html;
}

function isPlaceholderImage(model) {
  if (model.isNotLoaded || !model.src || !model.src.startsWith('http')) {
    return true;
  }
  return false;
}

function getHtmlForEmbeddedUrl(model) {
  const html = '<div class="fr-embedly fr-draggable" contenteditable="false" data-original-embed="<a href=\'www.google.com\'' +
    `data-card-branding=\'0\' class=\'embedly-card\'></a>" draggable="true" id="${model.id}">`
    + `<a class="embedly-card" data-card-branding="0" href="${model.src}"></a></div>`;

  return html;
}

function getHtmlForContentPanelHeader(model) {
  return `
      <div class="gc-col-12 gd-cp-header" style="background: ${model.colour}" contenteditable="false">
        <span style="display: flex; width: calc(100% - 120px)">
          <span class="gd-cp-section__title-label-span">${model.title || ' '}</span>
          <span class="gd-cp-section__title-label-span">${model.configuration?.label ? ' - ' + model.configuration.label : ' '}</span>
        </span>
        <span>
            <mat-icon fontSet="fal" fontIcon="fa-cog"></mat-icon>
            <i class="fa fa-cut" title="Cut" aria-hidden="true"></i>
            <i class="fa fa-copy" title="Copy" aria-hidden="true"></i>
            <i class="fa fa-cog" title="Edit" aria-hidden="true"></i>
            <i class="fa fa-trash" title="Delete" aria-hidden="true"></i>
        </span>
      </div>`;
}

function getHtmlForTagMarkerDetails(model) {
  const isStartingMarker = !model?.startingMarkerId || model.attributes.class.includes('--starting-point');
  const editIcon = `<i class="fa fa-cog" aria-hidden="true"></i>`;
  const deleteIcon = `<i class="fa fa-trash" aria-hidden="true"></i>`;

  let html = '';
  // eslint-disable-next-line max-len
  const tagName = `<span class="content-tag-marker__tag-name"><span>${model.title || '' } </span> - ${isStartingMarker ? $localize`:startOfContentTag|:Start` : $localize`:endOfContentTag|:End`}</span>`;
  const tagIcon = '<img src="./assets/img/content_tag_grey.svg">';
  html += `<span class="content-tag-marker__tag-name-wrapper">${tagName + tagIcon}</span>`;

  html += `<span class="content-tag-marker__actions">${(isStartingMarker ? editIcon : '') + deleteIcon}</span>`;

  return html;
}

function getHTMLForAnchorLink(element, model) {
  const alignClassMap = { left: 'fr-fil', right: 'fr-fir', center: 'fr-fic' };
  isPlaceholderImage(model) ? element.classList.add('--bg-contain') : element.classList.remove('--bg-contain');
  const elClass = `fr-img-caption gd-ember-wrapper --anchor-link fr-dii ${alignClassMap[model.alignment || 'center']} `;
  let html = '<span data-type="' + model.type + '" contenteditable="false" class="' + elClass + '" draggable="false">';
  html += '<span class="fr-img-wrap gd-ember-wrapper --anchor-link">' + element.outerHTML;
  html += '</span></span>';

  return html;
}

function getHTMLForMention(element, model) {
  const message = model.state?.message || model.error?.message;
  const status = model.state?.status || model.error?.status;
  let html = '';

  element.innerText = model.innerText;
  if (model.type === EmbeddedType.TaxonomyMention) {
    element.style.color = !model.allowed ? 'red' : 'auto';
    model?.active ? '' : element.classList.add('--deactivated');

    const errorMessage = $localize`Taxonomy is missing corresponding localisation`;
    const deactivatedMessage = $localize`Deactivated Taxonomy`
    element.innerHTML += !model.active ? `<i class="fal fa-exclamation-circle" style="cursor: default" contenteditable="false" title="${deactivatedMessage}">&nbsp</i>` : '';
    element.innerHTML += !model.allowed ? `<i class="fal fa-exclamation-circle" style="cursor: default" contenteditable="false" title="${errorMessage}">&nbsp</i>` : '';
  } else {
    element.innerHTML += (status && status !== 'published')
      ? ` <i class="fal ${status === 'scheduled' ? 'fa-clock' : 'fa-exclamation-circle'}" contenteditable="false" title="${message}">&nbsp</i>`
      : '';
  }

  html += element.outerHTML;
  return html;
}

export function getPlaceholderByReferencedContentType(type) {
  switch (type) {
    case ReferencedContentType.Image:
      return './assets/img/img_icon_mono_grey.svg';
    case ReferencedContentType.Gallery:
      return './assets/img/gallery-placeholder-grey.svg';
    case ReferencedContentType.File:
      return './assets/img/files/default.svg';
    case ReferencedContentType.SystemWidget:
    case ReferencedContentType.ThirdPartyWidget:
      return './assets/img/custom-icons/glideCustomIcon_system-widgets.svg';
    case ReferencedContentType.ImageURL:
      return './assets/img/embed-image-url-dark.svg';
    default:
      return '';
  }
}

function removeElement(element) {
  if (element.parentNode) {
    element.parentNode.removeChild(element);
  }
  return null;
}

function widgetEmbedTransformDOMToModel(el) {
  const alignment = getBlockAlignment(el);
  const isInline = el.classList.contains('fr-dii');
  const attributes = { ...mapAttributes(el.attributes), alignment, isInline };
  const structureNode = {
    id: el.id,
    tagName: 'IMG',
    attributes: {
      alignment,
      isInline,
      id: el.id,
      title: attributes.title,
      class: attributes.class,
      'data-id': +el.dataId
    },
    innerText: '',
  };
  return { structureNode, includeChildren: false } as any;
}
