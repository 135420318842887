import { Taxonomy } from './taxonomies.reducer';
import { UnsafeAction } from '../unsafe-action.interface';

export const GET_TAXONOMIES = '[Taxonomies] Get taxonomies';
export class GetTaxonomiesAction implements UnsafeAction {
  readonly type = GET_TAXONOMIES;
  constructor() { }
}

export const GET_TAXONOMIES_COMPLETE = '[Taxonomies] Taxonomies fetch completed';
export class GetTaxonomiesCompleteAction implements UnsafeAction {
  readonly type = GET_TAXONOMIES_COMPLETE;
  constructor(public payload: any) { }
}

export const GET_TAXONOMY = '[Taxonomies] Get one taxonomy';
export class GetTaxonomyAction implements UnsafeAction {
  readonly type = GET_TAXONOMY;
  constructor(public payload: number) { }
}

export const GET_TAXONOMY_COMPLETE = '[Taxonomies] Get one taxonomy done';
export class GetTaxonomyCompleteAction implements UnsafeAction {
  readonly type = GET_TAXONOMY_COMPLETE;
  constructor(public payload: Taxonomy) { }
}

export const DELETE_TAXONOMY = '[Taxonomies] Delete taxonomy';
export class DeleteTaxonomyAction implements UnsafeAction {
  readonly type = DELETE_TAXONOMY;
  constructor(public payload: Taxonomy) { }
}

export const DELETE_TAXONOMY_COMPLETE = '[Taxonomies] Delete taxonomy completed';
export class DeleteTaxonomyCompleteAction implements UnsafeAction {
  readonly type = DELETE_TAXONOMY_COMPLETE;
  constructor(public payload: Taxonomy) { }
}

export const CREATE_TAXONOMY = '[Taxonomies] Create taxonomy';
export class CreateTaxonomyAction implements UnsafeAction {
  readonly type = CREATE_TAXONOMY;
  constructor(public payload: any) { }
}

export const CREATE_QUICK_TAXONOMY = '[Taxonomies] Create quick taxonomy';
export class CreateQuickTaxonomyAction implements UnsafeAction {
  readonly type = CREATE_QUICK_TAXONOMY;
  constructor(public payload: any) { }
}

export const CREATE_TAXONOMY_COMPLETE = '[Taxonomies] Create taxonomy completed';
export class CreateTaxonomyCompleteAction implements UnsafeAction {
  readonly type = CREATE_TAXONOMY_COMPLETE;
  constructor(public payload: any) { }
}

export const UPDATE_TAXONOMY = '[Taxonomies] Update taxonomy';
export class UpdateTaxonomyAction implements UnsafeAction {
  readonly type = UPDATE_TAXONOMY;
  constructor(public payload: Taxonomy) { }
}

export const UPDATE_TAXONOMY_COMPLETE = '[Taxonomies] Update taxonomy completed';
export class UpdateTaxonomyCompleteAction implements UnsafeAction {
  readonly type = UPDATE_TAXONOMY_COMPLETE;
  constructor(public payload: Taxonomy) { }
}

export const SET_TAXONOMY_SUGGESTION_FILTER = '[Taxonomies] Set taxonomy filter';
export class SetTaxonomyFilterAction implements UnsafeAction {
  readonly type = SET_TAXONOMY_SUGGESTION_FILTER;
  constructor(public payload: String) { }
}

export const REORDER_TAXONOMIES = '[Taxonomies] Readorder taxonomy';
export class ReorderTaxonomieAction implements UnsafeAction {
  readonly type = REORDER_TAXONOMIES;
  constructor(public payload: any) { }
}

export const REORDER_TAXONOMIES_COMPLETE = '[Taxonomies] Readorder taxonomy completed';
export class ReorderTaxonomieCompleteAction implements UnsafeAction {
  readonly type = REORDER_TAXONOMIES_COMPLETE;
  constructor(public payload: any) { }
}

export const TAXONOMIES_ACTION_FAILED = '[Taxonomies] Generic failure action';
export class TaxonomiesFailedAction implements UnsafeAction {
  readonly type = TAXONOMIES_ACTION_FAILED;
  constructor(public payload: any) { }
}

export const TAXONOMY_TREE_NODE_UPDATE = '[Taxonomies] Taxonomy tree node update';
export class TaxonomyTreeNodeUpdate implements UnsafeAction {
  readonly type = TAXONOMY_TREE_NODE_UPDATE;
  constructor(public payload: any) { }
}

export const TAXONOMY_TREE_NODE_UPDATE_COMPLETE = '[Taxonomies] Taxonomy tree node update complete';
export class TaxonomyTreeNodeUpdateComplete implements UnsafeAction {
  readonly type = TAXONOMY_TREE_NODE_UPDATE_COMPLETE;
  constructor(public payload: any) { }
}

export const TAXONOMY_TREE_SORT_CHANGE = '[Taxonomies] Taxonomy tree sorting change';
export class TaxonomyTreeSortChangeAction implements UnsafeAction {
  readonly type = TAXONOMY_TREE_SORT_CHANGE;
  constructor() { }
}

export const CLEAR_SELECTED_TAXONOMY = '[Taxonomies] Clear selected taxonomy';
export class ClearSelectedTaxonomyAction implements UnsafeAction {
  readonly type = CLEAR_SELECTED_TAXONOMY;
  constructor() { }
}

export const CLEAR_TAXONOMIES = '[Taxonomies] Clear all taxonomies';
export class ClearTaxonomiesAction implements UnsafeAction {
  readonly type = CLEAR_TAXONOMIES;
  constructor() { }
}


export const SET_TAXONOMIES_LOADING_FLAG = '[Taxonomies] Set taxonomies loading flag';
export class SetTaxonomiesLoadingFlagAction implements UnsafeAction {
  readonly type = SET_TAXONOMIES_LOADING_FLAG;
  constructor(public payload: any) { }
}

export const DELETE_TAXONOMY_LOCALIZATION = '[Taxonomies] Delete taxonomy localization';
export class DeleteTaxonomyLocalizationAction implements UnsafeAction {
  readonly type = DELETE_TAXONOMY_LOCALIZATION;
  constructor(public payload: any) { }
}

export const ACTIVATE_TAXONOMY = '[Taxonomies] Activate taxonomy';
export class ActivateTaxonomyAction implements UnsafeAction {
  readonly type = ACTIVATE_TAXONOMY;
  constructor(public payload: number) { }
}

export const ACTIVATE_TAXONOMY_COMPLETE = '[Taxonomies] Activate taxonomy completed';
export class ActivateTaxonomyCompleteAction implements UnsafeAction {
  readonly type = ACTIVATE_TAXONOMY_COMPLETE;
  constructor(public payload: any) { }
}

export const DEACTIVATE_TAXONOMY = '[Taxonomies] Deactivate taxonomy';
export class DeactivateTaxonomyAction implements UnsafeAction {
  readonly type = DEACTIVATE_TAXONOMY;
  constructor(public payload: number) { }
}

export const DEACTIVATE_TAXONOMY_COMPLETE = '[Taxonomies] Deactivate taxonomy completed';
export class DeactivateTaxonomyCompleteAction implements UnsafeAction {
  readonly type = DEACTIVATE_TAXONOMY_COMPLETE;
  constructor(public payload: any) { }
}
