import { get, keyBy } from 'lodash-es';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { WidgetTypeFieldConfiguration } from '../../../../core/store/widget-types/widget-types.model';
import { UntypedFormControl, Validators, ValidatorFn, UntypedFormGroup, AbstractControl, ValidationErrors } from '@angular/forms';
import { DataSourceFactoryService } from '../field-builder-data-factory.service';
import { Observable, Subscription, of, BehaviorSubject, combineLatest } from 'rxjs';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { DataSourceOptions } from '../../../../gpp-shared/custom-form-builder/data-source-options.enum';
import { take, filter, debounceTime, distinctUntilChanged, map, skip, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AccountSettingsService } from '../../../../core/api/account-settings/accounts-settings.service';
import { highlightSearchTerm } from '../../../../shared/shared-functions';
import { hasRequiredValidation } from '../has-required-validation/has-required-validation';
import { trimEnd } from 'lodash-es';
import { startCase } from 'lodash-es';
import { cmsDataTypeLabelMap } from '../../../custom-form-builder/widget-types-build/widget-types-form-configuration-build/glide-data-type-select/field-type-to-content-type-options';
import * as moment from 'moment';
import { AppState } from '../../../../core/store/app-reducer';
import { Store } from '@ngrx/store';
import { getTaxonomies } from '../../../../core/store';
import { isTaxonomyAllowed } from '../../../../core/api/taxonomies/taxonomies.service';
import { BidiService } from '../../../../core/i18n/bidi.service';
import { addUILocale } from '../../../i18n-utilities/i18n-utilities';
import { getContentLocalesList, multipleLocalesExist } from '../../../../core/store/content-locales/content-locales.reducer';
import { generateHelpTooltip } from '../info-tooltip.text';

@Component({
  selector: 'gd-autocomplete-field-configuration',
  templateUrl: './autocomplete-field-configuration.component.html',
  styleUrls: ['./autocomplete-field-configuration.component.scss'],
})
export class AutocompleteFieldConfigurationComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() fieldControl: UntypedFormControl;
  @Input() fieldConfig: WidgetTypeFieldConfiguration;
  @Input() width = 70;
  @Input() hasActionPermission = true;
  @Input() isContentPublished = true;
  /**
   * Sets focus on this field on page or dialog load
   *
   * @deprecated use the dialog data `autoFocus` property
   */
  @Input() setFocus = false;
  @Input() isAllowedTaxonomiesField = false;
  @Input() usage = null;
  @Input() activeLocale = null;
  @Input() readOnlyMode = false;
  /**
   * the field control used locally for form model, this is NOT the final
   * value to be saved, but value used for narrowing down results and loading data
   */
  filterField: UntypedFormControl = new UntypedFormControl();

  data$: Observable<any>;
  // TODO see if local server data can be refactored away and data factory used instead
  localServerData;
  dataSource;
  // is the data fetch for a suggestions list in progress?
  dataLoading: boolean = false;
  // this is used to suppress the loaded on initial click in the input field
  dataFetchCount = 0;
  firstDataFetchInProgress = false;

  // post select we do an uneccesarry data fetch, sometimes showing the loader
  // this timer and flag help suppress that. proper solution would be checking
  // and making sure we maker requests only when and as needed
  suppressFetchPostSelect = false;
  suppressFetchPostSelectTimeout = null;

  public selectedItem = null;
  isFormControlRequired;
  autocompletePlaceholder = '';

  isAutocompleteField = {
    isArticle: false,
    isCollection: false,
    isLiveReporting: false,
  };

  readOnlyTooltip = $localize`Read-only`;

  /**
   * `sourceVal` is used to store info on which data source, if any, will be used for
   * populating the field. Not relevant for `No Data` fields. Example:
   * ```
   * {
   *   "type": "CMS Data",
   *   "value": "articles",
   *   "allowedTypes": [10]
   * }
   * ```
   */
  cmsDataSourceType;
  /**
   * This is the same as `sourceVal.value`, check comment on `sourceVal`.
   */
  sourceVal = null;
  dataSourceIsCMS;

  // TODO see if we can remove the `multiSelectFieldChips` and just use the reactive variable
  public multiSelectFieldChips = [];
  multiSelectFieldChips$ = new BehaviorSubject([]);

  componentSubscriptions = new Subscription();

  showInvalidDataHint = false;
  showTaxonomyPath = this.accountSettingsService.getShowTaxonomyPathFlag();
  @ViewChild('focusInput') focusInput: ElementRef;
  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) afterSelectionFocus: MatAutocompleteTrigger;

  // determine if the labels for this field will be saved for CFG EDS fields
  saveLabels = false;

  get sysLabelsControl() {
    return this.fieldControl.parent.get('__sysLabels');
  }

  get sysSelectionValuesControl() {
    return this.fieldControl.parent?.get('__sysEdsOptionsData');
  }

  /**
   * This is used for single value autocomplete, it handles mapping of value
   * and label which is displayed in the control
   */
  get displayFn() {
    return (option) => {
      if (!option) {
        return '';
      }
      if (typeof option === 'object') {
        return option ? option.name || option.label : '';
      }
      // TODO revamp this, as it is liable to blow up. reactive and imperative should not mix like this
      let data;
      this.data$.pipe(take(1)).subscribe((res) => (data = res));
      const selected = (data && data.find((item) => item.id === option)) || {};
      return (
        selected.name ||
        (this.selectedItem && (this.selectedItem.name || this.selectedItem.label)) ||
        ''
      );
    };
  }

  get isExternalDataSourceField() {
    return get(this.fieldConfig, 'dataSource.type', null) === DataSourceOptions.ExternalData;
  }

  get isCustomDataSourceField() {
    return get(this.fieldConfig, 'dataSource.type', null) === DataSourceOptions.CustomData;
  }

  // these values are used for message provided by options data source
  dataSourceMessage = null;
  dataSourceMessageType = null;

  // added for easier validation of selected taxonomies
  taxonomies = {};
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  // includes both active and inactive content locales
  allContentLocales$ = this.store.select(getContentLocalesList);
  allContentLocalesMap = {};

  multipleLocalesExist = false;
  customRequiredFieldMessage = '';
  defaultRequiredFieldMessage = '';

  infoTooltip = '';
  isFocused = false;
  prefixSearchTooltip = $localize`Advanced searches: prefix search term(s) with special characters for narrowed results
   • Free Search: % (+ search terms) displays any matches to any search term found in a headline/catchline, regardless of written order
   • Exact Search: = displays only exact matches of the full search term in a headline/catchline
   • Matching Search: # searches for specific word strings which appear in a headline/catchline
   For more information on advanced search options, please visit our support portal.`

  constructor(
    private dataSourceFactory: DataSourceFactoryService,
    private router: Router,
    private accountSettingsService: AccountSettingsService,
    private store: Store<AppState>,
    private bidiService: BidiService,
  ) {}

  ngOnInit() {
    this.customRequiredFieldMessage = this.fieldConfig.validators.find(validator => validator.validator === 'required')?.message;
    this.defaultRequiredFieldMessage = $localize`${this.fieldConfig.label} is required.`
    this.componentSubscriptions.add(
      this.store.select(multipleLocalesExist).subscribe(mle => this.multipleLocalesExist = mle)
    );

    this.isFormControlRequired = hasRequiredValidation(this.fieldConfig);
    const isMultiSelect = this.fieldConfig.inputType === 'multiple';

    this.componentSubscriptions.add(
      combineLatest([this.fieldControl.statusChanges, this.fieldControl.valueChanges]).subscribe(
        ([status]) => {
          if (!this.isFormControlRequired) {
            return;
          }
          if (isMultiSelect) {
            this.filterField.addValidators(multipleSelectAutocompleteValidation(this.fieldControl));
            this.filterField.updateValueAndValidity();
            this.filterField.markAsTouched();
            return;
          }
          if (status === 'VALID') {
            this.filterField.removeValidators(Validators.required);
            this.filterField.updateValueAndValidity();
            this.fieldControl.markAsTouched();
            return;
          }
          if (status === 'INVALID') {
            this.filterField.addValidators(Validators.required);
            this.filterField.updateValueAndValidity();
            this.filterField.markAsTouched();
          }
          if (status === 'INVALID' && this.fieldControl.touched) {
            this.filterField.updateValueAndValidity();
            return;
          }
          this.filterField.addValidators(Validators.required);
          this.filterField.updateValueAndValidity();
        }
      )
    );

    if (!this.hasActionPermission) {
      this.filterField.disable();
      this.fieldControl.disable();
    }

    this.sourceVal = get(this.fieldConfig, 'dataSource', {});
    this.cmsDataSourceType = get(this.sourceVal, 'value', null);
    this.saveLabels = get(this.fieldConfig, 'dataSource.value.saveLabels', false);

    this.isAutocompleteField.isArticle = this.cmsDataSourceType === 'articles';
    this.isAutocompleteField.isCollection = this.cmsDataSourceType === 'collections';
    this.isAutocompleteField.isLiveReporting = this.cmsDataSourceType === 'liveReports';

    this.dataSourceIsCMS = this.isGlideDataOptionsSource();

    if (this.isFormControlRequired) {
      this.fieldControl.setValidators(Validators.required);
    }
    if (this.cmsDataSourceType === 'taxonomies') {
      this.fieldControl.addValidators(this.taxonomiesLocaleValidator());
    }
    this.autocompletePlaceholder = getAutocompletePlaceholder(
      this.dataSourceIsCMS,
      this.sourceVal.value
    );

    // create data source which will be used for providing options for the custom field
    this.dataSource = this.dataSourceFactory.create(
      this.fieldConfig,
      this.fieldControl.value,
      this.fieldControl,
      this.isContentPublished,
      this.activeLocale?.id
    );

    if (this.dataSource.dataLoading$) {
      this.componentSubscriptions.add(
        this.dataSource.dataLoading$
          // skip initial false flag at rest, we don't fetch data until user interacts with the field
          .pipe(skip(1))
          .subscribe((dataLoading) => {
            this.dataLoading = dataLoading;
            // whenever the dataLoading is true, we are fetching new data, increment data fetch counter
            if (dataLoading) {
              this.dataFetchCount++;
            }
            // we do special handling on first data fetch, suggestion panel is hidden
            this.firstDataFetchInProgress = dataLoading && this.dataFetchCount === 1;
          })
      );
    }

    this.componentSubscriptions.add(
      this.store.select(getTaxonomies).subscribe((taxonomies) => (this.taxonomies = taxonomies))
    );

    this.componentSubscriptions.add(
      this.allContentLocales$
        .pipe(map((locales) => keyBy(locales, 'id')))
        .subscribe((localesMap) => (this.allContentLocalesMap = localesMap))
    );

    // going further without dataSource will result in errors
    if (!this.dataSource) {
      return;
    }

    this.initializeDataSource(isMultiSelect);
    this.initializeOptionsMessageListener();

    const isDynamicSearchExternalDataField = get(
      this.sourceVal,
      'value.dynamicSearch.enabled',
      false
    );
    const isDynamicSearchField = this.dataSourceIsCMS || isDynamicSearchExternalDataField;
    if (isDynamicSearchField) {
      this.handleSaveSelectedOptionsDataForMultiEDSFields();
      // TODO check if the labels get handled correctly for multi select field
      this.handleDynamicSearchFields(isMultiSelect, isDynamicSearchExternalDataField);
      return;
    }

    const dataSourceType = get(this.sourceVal, 'type', null);
    const isCustomDataSource = dataSourceType === DataSourceOptions.CustomData;
    const isExternalDataSource = dataSourceType === DataSourceOptions.ExternalData;
    if (isCustomDataSource || isExternalDataSource) {
      this.handleSimpleCustomAndExternalDataFields(isMultiSelect, isExternalDataSource);
    }

    this.handleSaveSelectedOptionsDataForMultiEDSFields();

    // mainly added to cover the case when some of the prerequisite fields are changed and don't fit anymore
    if (isExternalDataSource) {
      this.componentSubscriptions.add(
        this.fieldControl.valueChanges
          .pipe(
            debounceTime(200),
            filter((val) => !val)
          )
          .subscribe(() => {
            this.filterField.setValue(null);
            this.multiSelectFieldChips = [];
            this.multiSelectFieldChips$.next([]);
          })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.infoTooltip = generateHelpTooltip(this.fieldConfig.description, this.readOnlyMode);
  }

  ngAfterViewInit() {
    if (!this.fieldControl.value && this.setFocus) {
      this.focusInput.nativeElement.focus();
    }
  }

  // only for EDS multi select fields within CFGs
  handleSaveSelectedOptionsDataForMultiEDSFields() {
    if (this.fieldConfig.inputType !== 'multiple') {
      return;
    }

    this.componentSubscriptions.add(
      this.multiSelectFieldChips$
        .pipe(distinctUntilChanged(), skip(1))
        .subscribe((selectedValues) => {
          this.multiSelectFieldChips = selectedValues;
          const labelValues = (selectedValues || []).reduce((acc, v) => {
            acc[v.id] = v.name || v.label;
            return acc;
          }, {});
          this.saveSelectedOptionLabels(labelValues);

          const selectedOptionsMap = this.multiSelectFieldChips.reduce((acc, v) => {
            acc[v.id] = v;
            return acc;
          }, {});
          this.saveSelectedOptionData(selectedOptionsMap);
        })
    );
  }

  isGlideDataOptionsSource() {
    const cmsDataSourceTypes = [
      'taxonomies',
      'articles',
      'htmlSnippets',
      'menus',
      'collections',
      'contentTags',
      'authors',
      'articleTypes',
      'collectionTypes',
      'liveReports',
      'contentLocales',
      'customFieldGroups',
      'users',
      'statuses'
    ];
    const optionsDataSourceType = get(this.sourceVal, 'type', null);
    return (
      optionsDataSourceType === DataSourceOptions.CMSData &&
      cmsDataSourceTypes.includes(this.sourceVal['value'])
    );
  }

  /**
   * This function handles init for custom field autocomplete controls which do dynamic filtering, i.e.
   * a new request to the API is made when the filter term changes. This includes all Glide data and
   * dynamic search external data fields.
   *
   * @param isMultiSelect determines if one or more options can be selected
   */
  handleDynamicSearchFields(isMultiSelect, isDynamicSearchExternalDataField) {
    // handle initialization logic for single and multi select
    // filter fields and data lists
    if (isMultiSelect) {
      // initialize the data list for the multi select control variant
      this.getInitialMultiSelectControlData();
    } else {
      // for single select field variant, set initial value of filter control after the data starts flowing
      this.componentSubscriptions.add(
        this.data$
          .pipe(
            filter((data) => data && data.length > 0),
            take(2)
          )
          .subscribe((selectionData) =>
            this.setCustomFieldControlData(isMultiSelect, selectionData)
          )
      );
    }

    // on filter control changes, invoke the setFilter method of data source
    // which will trigger new data fetch
    this.componentSubscriptions.add(
      this.filterField.valueChanges
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe((filterField) => {
          if (filterField?.length > 0 && filterField?.length < 2) {
            return;
          }
          // suppress empty queries after selecting an item for 425ms
          if(this.suppressFetchPostSelect && !filterField) {
            return;
          }
          const allowedContentLocales = get(this.fieldConfig, 'dataSource.allowedContentLocales', []);
          const allowedTypes = get(this.fieldConfig, 'dataSource.allowedTypes', []);
          const allowedTaxonomies = get(this.fieldConfig, 'dataSource.allowedTaxonomies', []);
          const allowedTaxonomySubtrees = get(
            this.fieldConfig,
            'dataSource.allowedTaxonomySubtrees',
            []
          );
          const allowedFilter =
            this.sourceVal.value === 'taxonomies' ? allowedTaxonomies : allowedTypes;

          if (this.sourceVal.value === 'articles') {
            setTimeout(() => this.dataSource.setFilter(filterField, allowedTypes, allowedContentLocales), 300);
            return;
          }
          if (this.sourceVal.value === 'taxonomies') {
            const showDeactivatedFlag: boolean = get(this.fieldConfig, 'dataSource.showDeactivated');
            setTimeout(() => this.dataSource.setFilter(filterField, allowedFilter, allowedTaxonomySubtrees, showDeactivatedFlag), 300);
            return;
          }
          this.dataSource.setFilter(filterField, allowedFilter, allowedTaxonomySubtrees);
        })
    );
  }

  /**
   * This method initializes the control for the case when we have simple external data and
   * custom data fields. Key thing in both of those cases is that data is fetched only once,
   * and thereafter filtered locally. In case of external data, the external API is used to
   * fetch the data, and in the case of custom data, it is pulled from the field config.
   *
   * @param isMultiSelect tells if one or more values can be selected
   * @param isExternalDataSource tells if this is an external data source field
   */
  handleSimpleCustomAndExternalDataFields(isMultiSelect, isExternalDataSource) {
    this.componentSubscriptions.add(
      this.filterField.valueChanges.subscribe((keystroke) => this.localDataFilter(keystroke))
    );

    // the take(2) bit is there as we need to skip the initial [] return and process actual data when fetched
    // but only for custom data - external data may involve multiple fetches as upstream values change
    const dataStream$ = isExternalDataSource ? this.data$ : this.data$.pipe(take(2));
    this.componentSubscriptions.add(
      dataStream$.subscribe((data) => {
        this.localServerData = data;
        // TODO refactor to use the method from custom field factory builder
        this.createObjectData(data);
        this.setCustomFieldControlData(isMultiSelect);
      })
    );
  }

  initializeDataSource(isMultiSelect) {
    this.data$ = this.dataSource.init().pipe(
      switchMap((data) => of(data)),
      map((data: any) => {
        if (this.fieldControl.value && !this.selectedItem && !isMultiSelect) {
          this.selectedItem = data.find((item) => item.id === this.fieldControl.value) || null;
          this.validateAutocompleteData(false);
        }

        if (
          data &&
          data.length > 0 && data[0] &&
          (this.isAutocompleteField.isArticle ||
            this.isAutocompleteField.isCollection ||
            this.isAutocompleteField.isLiveReporting)
        ) {
          data.forEach((article) =>
            highlightSearchTerm(article, this.filterField.value, this.cmsDataSourceType)
          );
        }

        if (!this.dataSourceIsCMS) {
          return data;
        }
        return data;
      })
    );

    this.dataSource.getData(null, true);
  }

  initializeOptionsMessageListener() {
    // if the options data source provides a stream of messages (hints or errors)
    // then wire up the source with local variables used to display those messages
    if (this.dataSource.getMessages) {
      this.componentSubscriptions.add(
        this.dataSource.getMessages().subscribe((messageData) => {
          this.dataSourceMessageType = get(messageData, 'type', null);
          this.dataSourceMessage = get(messageData, 'message', null);
        })
      );
    }
  }

  /**
   * This method is used to parse data for local filtering. End result is
   * data source created from localServerData
   */
  // TODO refactor this: make it return localServer data rather than setting it and handle `this.data$` case separately
  createObjectData(data) {
    const testData = data[0];
    if (typeof testData === 'object') {
      this.data$ = of();
      if (testData['id'] && testData['name']) {
        return (this.localServerData = data);
      }

      // fix id-value Custom Data input not triggering autocomplete
      if (testData['id'] && testData['label']) {
        this.localServerData = data.map((item) => {
          return {
            id: item.id,
            name: item.label,
          };
        });
        return this.localServerData;
      }

      if (testData['label'] && testData['value']) {
        // convert label-value to name-id
        this.localServerData = data.map((item) => {
          return {
            id: item.value,
            name: item.label,
          };
        });
        return this.localServerData;
      }

      return (this.localServerData = []);
    }

    // TODO handle this elsewhere and simplify this function
    this.localServerData = data.map((d) => ({ id: d, name: d }));
    this.data$ = of(this.localServerData);
  }

  setCustomFieldControlData(isMultiSelect, localServerData = null) {
    const isAutocompleteField = this.fieldConfig['fieldType'] === 'autocomplete';
    const isValidData = !!this.fieldControl.value;
    if (!isValidData || !isAutocompleteField) {
      return;
    }

    // prevent emitting additional change event ONLY in case of external data field
    // TODO: check if everything works as expected when emitEvent is false for all custom fields
    const emitEvent = !this.isExternalDataSourceField;

    // single select handling
    if (!isMultiSelect) {
      this.filterField.setValue(this.fieldControl.value, { emitEvent });

      // what follows is only relevant to EDS fields
      if (!this.isExternalDataSourceField) {
        return;
      }

      // TODO refactor and perhaps only do if the label for the field isn't defined
      // set initial value for the single select autocomplete
      const selected = (localServerData || this.localServerData).find(
        (val) => val.id === this.fieldControl.value
      );

      // there is a case when localServerData is not yet loaded and selected value
      // is not immediately available - it becomes available shortly after, so we
      // skip setting the labels and option data in that case
      if (!selected) {
        return;
      }

      this.saveSelectedOptionLabels(selected.name || selected.label);
      this.saveSelectedOptionData(selected);
      return;
    }

    this.filterField.setValue('', { emitEvent });
    const testData = this.fieldControl.value[0];
    if (typeof testData === 'object' && testData.id && testData.name) {
      // this fixes GPP-2593
      this.multiSelectFieldChips = this.fieldControl.value;
      this.multiSelectFieldChips$.next(this.fieldControl.value);
      return;
    }
    if (
      this.sourceVal &&
      this.sourceVal.type === 'External Data' &&
      isMultiSelect &&
      this.fieldControl.value
    ) {
      const serverData = [...(localServerData || this.localServerData)];
      const selectedData = serverData.filter(
        (item) => this.fieldControl.value.indexOf(item.id) !== -1
      );
      this.multiSelectFieldChips = selectedData;
      this.multiSelectFieldChips$.next(selectedData);
      // register labels here
      return;
    }

    // turn simple values (string array) into id-name objects
    if (typeof this.fieldControl.value === 'object') {
      const mappedSelectedData = this.fieldControl.value.map((d) => ({ id: d, name: d }));
      this.multiSelectFieldChips$.next(mappedSelectedData);
    }
  }

  clearAutocompleteFilterField() {
    this.isFocused = false;
    setTimeout(() => {
      // purge the old data from the data pipeline on filter field blur
      if(this.dataSource.clearData) {
        this.dataSource.clearData();
      }

      if (!this.filterField.value) {
        this.selectedItem = null;
        this.fieldControl.markAsDirty();
        this.validateAutocompleteData(false);
        return this.fieldControl.setValue('');
      }

      if (!this.fieldControl.value) {
        return this.filterField.setValue(this.fieldControl.value);
      }
      const validFilter =
        (this.filterField.value.id || this.filterField.value) === this.fieldControl.value;

      if (!validFilter) {
        this.filterField.setValue(this.fieldControl.value);
      }
    }, 200);
  }

  // handles single select
  handleAutocompleteSelect(event: MatAutocompleteSelectedEvent) {
    const fieldValue = event.option.value.id;
    this.selectedItem = event.option.value;
    this.fieldControl.setValue(fieldValue);
    this.fieldControl.markAsDirty();
    this.validateAutocompleteData(false);

    // proceed only for EDS fields within CFGs with input type 'single'
    if (!this.isExternalDataSourceField || this.fieldConfig.inputType !== 'single') {
      return;
    }
    this.saveSelectedOptionData(this.selectedItem);
    const selectedLabel =
      get(event, 'option.value.name', null) || get(event, 'option.value.label', null);
    this.saveSelectedOptionLabels(selectedLabel);
  }

  /**
   * Noop if the save labels EDS field option is not enabled
   */
  saveSelectedOptionLabels(selectedOptionLabel) {
    if (!this.saveLabels || !this.sysLabelsControl) {
      return;
    }
    this.sysLabelsControl.setValue({
      ...this.sysLabelsControl.value,
      [this.fieldConfig.key]: selectedOptionLabel,
    });
  }

  saveSelectedOptionData(selectedOption) {
    if (!this.sysSelectionValuesControl) {
      return;
    }
    this.sysSelectionValuesControl.setValue({
      ...this.sysSelectionValuesControl.value,
      [this.fieldConfig.key]: selectedOption,
    });
  }

  ngOnDestroy(): void {
    this.cleanUpDataSource();
    this.componentSubscriptions.unsubscribe();
  }

  updateFilterFieldValidation() {
    const isMultiSelect = this.fieldConfig.inputType === 'multiple';
    this.isFocused = false;
    if (this.isFormControlRequired && !isMultiSelect) {
      this.filterField.markAsTouched();
      this.filterField.updateValueAndValidity();
      this.fieldControl.markAsTouched();
      this.fieldControl.updateValueAndValidity();
    }
  }

  selectItem(event) {
    setTimeout(() => {
      this.filterField.setValue('');
      // add code to suppress additional data fetches after selecting an item
      this.suppressFetchPostSelect = true;
      clearTimeout(this.suppressFetchPostSelectTimeout);
      this.suppressFetchPostSelectTimeout = setTimeout(() => {
        this.suppressFetchPostSelect = false;
      }, 425);
    }, 0);

    // quick fix added for allowed taxonomies input
    if (this.isAllowedTaxonomiesField) {
      this.fieldControl.setValue([...(this.fieldControl.value || []), event.option.value.id]);
      this.fieldControl.markAsDirty();
      this.validateAutocompleteData(true);
      return;
    }
    const selectedItem = event.option.value;
    const itemExist = !!this.multiSelectFieldChips.find((item) => item.id === selectedItem.id);
    if (itemExist) {
      return;
    }
    this.multiSelectFieldChips = [...this.multiSelectFieldChips, selectedItem];
    this.multiSelectFieldChips$.next(this.multiSelectFieldChips);

    if (this.fieldConfig.dataSource.type === DataSourceOptions.CustomData) {
      this.fieldControl.setValue(this.multiSelectFieldChips);
    } else {
      this.fieldControl.setValue(this.multiSelectFieldChips.map((item) => item.id));
    }

    this.fieldControl.markAsDirty();
    this.validateAutocompleteData(true);
  }

  removeItem(id) {
    this.multiSelectFieldChips = this.multiSelectFieldChips.filter((item) => item.id !== id);
    if (this.fieldConfig.dataSource.type === DataSourceOptions.CustomData) {
      this.fieldControl.setValue(this.multiSelectFieldChips);
    } else {
      this.fieldControl.setValue(this.multiSelectFieldChips.map((item) => item.id));
    }
    this.multiSelectFieldChips$.next(this.multiSelectFieldChips);
    this.filterField.markAsTouched();
    this.fieldControl.markAsDirty();
    this.validateAutocompleteData(true);
  }

  /**
   * Set initial value of the filter control for the single autocomplete field.
   * This is required in order to display the
   */
  setFieldControlData() {
    if (!this.fieldControl.value) {
      return;
    }
    this.filterField.setValue(this.fieldControl.value);
  }

  /**
   * Used to get initial data for the custom fields which allow for multiple values to be selected.
   * Is invoked only once on initial field setup
   */
  getInitialMultiSelectControlData() {
    if (!this.fieldControl.value) {
      return;
    }

    const selectedIds =
      typeof this.fieldControl.value === 'object'
        ? this.fieldControl.value
        : [this.fieldControl.value];
    this.dataSource
      .getMultiSelectCMSData(selectedIds)
      .pipe(
        skip(this.isExternalDataSourceField ? 1 : 0),
        take(1),
        map((data: any) => data.filter((item: any) => !!item && item.id)),
        map((data: any) => data.map(item => ({
          ...item,
          name: item.name || item.label || item.headline,
          catchline: item.catchline || '',
          type: item.articleTypeId || item.collectionTypeId || null,
          scheduled: !!item.scheduledRevisionId,
          publishDateFrom: item.publishDateFrom,
          publishDateTo: item.publishDateTo,
          unpublishScheduled: !!item.publishedRevisionId && Date.now() < item.publishDateTo,
        })))
      )
      .subscribe((data) => {
        const dataMap = data.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});

        // TODO add comments explaining what is the source used for
        const dataSourceType = this.fieldConfig.dataSource?.value;
        const formatDataSource = dataSourceType && startCase(dataSourceType);
        let source = formatDataSource && trimEnd(formatDataSource, 's');

        if (dataSourceType && dataSourceType === 'taxonomies') {
          source = 'Taxonomy';
        }
        // Sorted data
        this.multiSelectFieldChips = selectedIds.map((id) => {
          const option = dataMap[id]
            ? { ...dataMap[id], source }
            : {
                ...dataMap[id],
                id,
                source,
                taxonomyDisplayData: [`[${source} - ${id}]`],
                name: `[${source} - ${id}]`,
                deleted: true,
              };
          if (this.isExternalDataSourceField) {
            delete option.source;
          }
          return option;
        });
        this.fieldControl.setValue(this.multiSelectFieldChips.map((item) => item.id));
        this.multiSelectFieldChips$.next(this.multiSelectFieldChips);
        this.validateAutocompleteData(true);
        setTimeout(() => this.filterField.setValue(''), 100);
      });
  }

  localDataFilter(keystroke) {
    const tmp = [];
    if (keystroke === '') {
      const data = this.localServerData || [];
      this.data$ = of(data);
      return;
    }
    this.localServerData.reduce((obj, val) => {
      const typedFilter = typeof keystroke === 'string' ? keystroke.toLowerCase() : keystroke;
      if (val['name'] && val['name'].toLowerCase().includes(typedFilter)) {
        tmp.push(val);
      }
    }, {});
    this.data$ = of(tmp);
    return;
  }

  // allows the opening of item selected in the field in the
  navigateToItem(itemId) {
    let url;
    switch (this.cmsDataSourceType) {
      case 'menus': {
        url = '/widgets/menus/' + itemId;
        break;
      }
      case 'htmlSnippets': {
        url = '/widgets/html-snippets/' + itemId;
        break;
      }
      case 'articleTypes': {
        url = '/site-builder/article-types/' + itemId;
        break;
      }
      case 'collectionTypes': {
        url = '/site-builder/collection-types/' + itemId;
        break;
      }
      case 'contentTags': {
        url = '/site-builder/content-tags/' + itemId;
        break;
      }
      case 'customFieldGroups': {
        url = '/site-builder/custom-field-groups/' + itemId;
        break;
      }
      case 'liveReports': {
        url = '/live-reporting/' + itemId;
        break;
      }
      case 'contentLocales': {
        url = '/site-builder/content-locales/' + itemId;
        break;
      }
      case 'users': {
        url = '/glide-users/users/' + itemId;
        break;
      }
      default: {
        url = '/' + this.cmsDataSourceType + '/' + itemId;
        break;
      }
    }
    this.router.navigate([]).then(() => {
      window.open(addUILocale(url), '_blank');
    });
  }

  // used for reordering options for controls were multiple options can be selected
  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.multiSelectFieldChips, event.previousIndex, event.currentIndex);
    this.fieldControl.setValue(this.multiSelectFieldChips.map((item) => item.id));
    this.multiSelectFieldChips$.next([...this.multiSelectFieldChips]);
    this.fieldControl.markAsDirty();
    this.validateAutocompleteData(true);
  }

  validateAutocompleteData(isMultiSelect) {
    const allowedTypes = this.fieldConfig.dataSource?.allowedTypes || [];
    const allowedTaxonomies = this.fieldConfig.dataSource?.allowedTaxonomies || [];
    const allowedTaxonomySubtrees = this.fieldConfig.dataSource?.allowedTaxonomySubtrees || [];
    const validationArray = this.sourceVal.value === 'taxonomies' ? allowedTaxonomies : allowedTypes;
    this.showInvalidDataHint = false;
    if ((!allowedTaxonomySubtrees?.length && validationArray.length < 1) || (!this.selectedItem && !isMultiSelect)) {
      // validate deleted taxonomies/taxonomy locale
      if (this.cmsDataSourceType === 'taxonomies') {
        this.validateTaxonomyInput();
      }
      return;
    }
    const selectedValues = isMultiSelect ? this.multiSelectFieldChips : [this.selectedItem];
    selectedValues.forEach((item) => {
      let valid;
      let invalidLocaleTaxonomy = false;
      if (this.sourceVal.value === 'taxonomies') {
        valid = isTaxonomyAllowed(item, this.taxonomies, validationArray, allowedTaxonomySubtrees);
        if (this.activeLocale) {
          invalidLocaleTaxonomy = !item.localizedDisplayData?.[this.activeLocale?.id];
        }
      } else {
        valid = !!validationArray.includes(
          item.type || item.articleTypeId || item.collectionTypeId || item.id
        );
      }
      if (this.sourceVal.value === 'taxonomies') {
        item.invalidLocaleTaxonomy = invalidLocaleTaxonomy;
        item.invalidAllowedTaxonomy = !valid;

      }
      item.isItemValid = valid;
      if(this.sourceVal.value === 'taxonomies'){
        this.showInvalidDataHint = this.showInvalidDataHint || !valid || invalidLocaleTaxonomy || item.deleted || !item.active;
      }else {
        this.showInvalidDataHint = this.showInvalidDataHint || !valid || invalidLocaleTaxonomy || item.deleted;
      }

    });

    if (this.sourceVal.value === 'taxonomies') {
      this.fieldControl.updateValueAndValidity();
    }
  }

  cleanUpDataSource() {
    if (this.dataSource && this.dataSource.destroy) {
      this.dataSource.destroy();
    }
  }

  getContentReferenceIssueTooltip(item) {
    if (!item) {
      return $localize`Content error`;
    }
    const typeOfContent = ' (' + this.getContentTypeLabel(item.source) + ')';
    if (!item.active && this.sourceVal.value === 'taxonomies') {
      return $localize`Deactivated Taxonomy`;
    }
    if (item.deleted && this.sourceVal.value === 'taxonomies') {
      return $localize`This Taxonomy is no longer available. It must be removed before saving the data.`;
    }
    if (item.deleted) {
      return $localize`Content deleted` + typeOfContent;
    }
    if (item.invalidAllowedTaxonomy) {
      return $localize`This Taxonomy is not permitted for this field. It must be removed from this field, or added to the Allowed Taxonomies, before saving the data.`;
    }
    if (item.invalidLocaleTaxonomy && this.activeLocale) {
      if (this.usage === 'CONTENT_QUEUE') {
        return $localize`There is no ${this.activeLocale?.label} localisation for this Taxonomy.`;
      } else {
        return $localize`There is no ${this.activeLocale?.label} localisation for this Taxonomy. It must be removed from this field, or a localised version created, before saving the data.`;
      }
    }
    if (item.scheduled) {
      return $localize`Publish scheduled: ${moment(item.publishDateFrom).format('DD-MMM-YYYY h:mm A')}`
    }
    if (item.unpublishScheduled) {
      return $localize`Unpublish scheduled: ${moment(item.publishDateTo).format('DD-MMM-YYYY h:mm A')}`
    }
    return $localize`Content unpublished` + typeOfContent;
  }

  getContentTypeLabel(type) {
    return (
      {
        // definitions lifted from `field-type-to-content-type-options.ts`
        'Article Type': $localize`Article Type`,
        Article: $localize`Article`,
        Author: $localize`Author`,
        'Collection Type': $localize`Collection Type`,
        Collection: $localize`Collection`,
        'Content Tag': $localize`Content Tag`,
        'Custom Field Groups': $localize`Custom Field Groups`,
        'HTML Snippet': $localize`HTML Snippet`,
        'Live Report': $localize`Live Report`,
        Menu: $localize`Menu`,
        Taxonomy: $localize`Taxonomy`,
        // just in case, have these mappings as well
        articleTypes: $localize`Article Type`,
        articles: $localize`Article`,
        authors: $localize`Author`,
        collectionTypes: $localize`Collection Type`,
        collections: $localize`Collection`,
        contentTags: $localize`Content Tag`,
        customFieldGroups: $localize`Custom Field Groups`,
        htmlSnippets: $localize`HTML Snippet`,
        liveReports: $localize`Live Report`,
        menus: $localize`Menu`,
        taxonomies: $localize`Taxonomy`,
        users: $localize`User`,
      }[type] || type
    );
  }

  inputFocusDataFetch() {
    this.isFocused = true;
    // TODO implement this logic if needed, currently we have the issue that sometimes the article in the list gets flagged as unpublished for some reason
    // we will only fetch the list of suggestions on focus if the field has no value
    // otherwise user must start typing to get the suggestions list
    // if(!!this.fieldControl.value) {
    //   return;
    // }
    if(this.suppressFetchPostSelect) {
      return;
    }

    if (this.dataSourceIsCMS) {
      const allowedContentLocales = get(this.fieldConfig, 'dataSource.allowedContentLocales');
      const allowedTypes = get(this.fieldConfig, 'dataSource.allowedTypes', []);
      const allowedTaxonomies = get(this.fieldConfig, 'dataSource.allowedTaxonomies', []);
      const allowedTaxonomySubtrees = get(this.fieldConfig, 'dataSource.allowedTaxonomySubtrees', []);
      const allowedFilter = this.sourceVal.value === 'taxonomies' ? allowedTaxonomies : allowedTypes;

      if(this.sourceVal.value === 'articles') {
        setTimeout(() => this.dataSource.setFilter('', allowedTypes, allowedContentLocales, { suppressLoader: true }), 300);
        return;
      }
      if (this.sourceVal.value === 'taxonomies') {
        const showDeactivatedFlag = get(this.fieldConfig, 'dataSource.showDeactivated');
        setTimeout(() => this.dataSource.setFilter('', allowedFilter, allowedTaxonomySubtrees, showDeactivatedFlag), 300);
        return;
      }
      setTimeout(() => this.dataSource.setFilter('', allowedFilter, allowedTaxonomySubtrees), 300);
    }
    if (this.isCustomDataSourceField) {
      setTimeout(() => this.localDataFilter(''), 300);
    }
  }

  openSelectPanel() {
    setTimeout(() => this.afterSelectionFocus.openPanel(), 100);
  }

  getSingleTaxonomyErrorMessage(item) {
    if (item.deleted && this.usage) {
      return $localize`This Taxonomy is no longer available. It must be removed before saving the data.`;
    }
    if (item.invalidAllowedTaxonomy) {
      if (this.usage === 'ARTICLE' || this.usage === 'COLLECTION' || this.usage === 'TAXONOMY') {
        return $localize`This Taxonomy is not permitted for this field. It must be removed from this field, or added to the Allowed Taxonomies, before saving the data.`;
      }
      return $localize`Default Taxonomy has been overruled by the Allowed Taxonomies selection! Amend the Allowed Taxonomies, or remove the selected Default Taxonomy to proceed.`;
    }
    if (item.invalidLocaleTaxonomy) {
      return $localize`There is no ${this.activeLocale?.label} localisation for this Taxonomy. It must be removed from this field, or a localised version created, before saving the data.`;
    }

    return '';
  }

  getMultipleTaxonomyErrorMessage() {
    if (this.usage === 'ARTICLE' || this.usage === 'COLLECTION' || this.usage === 'TAXONOMY') {
      return $localize`Taxonomies marked above in red are invalid. Hover to see the details.`;
    }
    const invalidAllowedTaxonomy = this.multiSelectFieldChips.some(tax => tax.invalidAllowedTaxonomy);
    const deletedTaxonomy = this.multiSelectFieldChips.some(tax => tax.deleted);
    if (!invalidAllowedTaxonomy && deletedTaxonomy || this.usage === 'CONTENT_QUEUE') {
      return '';
    }
    return $localize`Highlighted Default Taxonomies have been overruled by the Allowed Taxonomies selection! Amend the Allowed Taxonomies, or remove the highlighted Default Taxonomy to proceed.`;
  }

  validateTaxonomyInput() {
    // trigger taxonomy locale validator after input change
    this.fieldControl.updateValueAndValidity();
  }

  taxonomiesLocaleValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // quick fix for removing allowed taxonomies
      // remove functionality is in allowed-taxonomies-input.ts and multiSelectFieldChips never get updated
      if (this.isAllowedTaxonomiesField) {
        this.multiSelectFieldChips = this.multiSelectFieldChips.filter((item) =>
          this.fieldControl.value.includes(item.id)
        );
      }
      let invalidTaxonomies = false;
      const isMultiSelect = this.fieldConfig.inputType === 'multiple';
      let selectedItems = isMultiSelect ? this.multiSelectFieldChips : this.selectedItem ? [this.selectedItem] : [];
      if (selectedItems.length) {
        const allowedTaxonomies = this.fieldConfig.dataSource?.allowedTaxonomies || [];
        const allowedTaxonomySubtrees = this.fieldConfig.dataSource?.allowedTaxonomySubtrees || [];
        selectedItems.forEach((i) => (i.invalidAllowedTaxonomy = !isTaxonomyAllowed(i, this.taxonomies, allowedTaxonomies, allowedTaxonomySubtrees)));
        if (this.activeLocale) {
          selectedItems.forEach((i) => (i.invalidLocaleTaxonomy = !i.localizedDisplayData?.[this.activeLocale?.id]));
          invalidTaxonomies = selectedItems.some((i) => i.deleted || i.invalidLocaleTaxonomy || i.invalidAllowedTaxonomy);
        }
        if (!this.activeLocale) {
          invalidTaxonomies = selectedItems.some((i) => i.deleted || i.invalidAllowedTaxonomy);
        }
      }
      this.showInvalidDataHint = invalidTaxonomies;
      return invalidTaxonomies ? { taxonomyErr: true } : null;
    };
  }

  showScheduleIcon(selectedItem) {
    return this.dataSourceIsCMS && selectedItem && (selectedItem.scheduled || selectedItem.unpublishScheduled) && (this.isAutocompleteField.isArticle || this.isAutocompleteField.isCollection) && !selectedItem.deleted
  }
}

function getAutocompletePlaceholder(isCMSData, sourceType) {
  if (!isCMSData) {
    return $localize`Start typing to refine the suggestions`;
  }

  switch (sourceType) {
    case 'articles':
      return $localize`Start typing article headline/catchline`;

    case 'itemLists':
      return $localize`Start typing the name of item list`;

    case 'htmlSnippets' :
      return $localize`Start typing HTML snippet name`;

    case 'liveReports':
      return $localize`Start typing live report headline/catchline`;

    case 'contentLocales':
      return $localize`Start typing name of content locale`;

    case 'users':
      return $localize`Start typing any user name`;

    case 'statuses':
      return $localize`Start typing any status`;

    default:
      return $localize`Start typing ${cmsDataTypeLabelMap[sourceType].toLowerCase()}:glideDataType: name`;
  }
}

function multipleSelectAutocompleteValidation(fieldControl: UntypedFormControl): ValidatorFn {
  return (filterControl: UntypedFormGroup): { [key: string]: boolean } | null => {
    const isFormValid = (!filterControl.touched && !fieldControl.touched)
      || (fieldControl.value && fieldControl.value.length > 0);

    if (isFormValid) {
      return null;
    }

    filterControl.markAsTouched();
    return {required: true};
  };
}
