// TODO figure out how now to have this separate from other permission mappings
export const Permissions = {

  GU_ACCOUNT_CREATE: 'GU_ACCOUNT_CREATE',
  GU_ACCOUNT_READ: 'GU_ACCOUNT_READ',
  GU_ACCOUNT_UPDATE: 'GU_ACCOUNT_UPDATE',
  GU_ACCOUNT_DELETE: 'GU_ACCOUNT_DELETE',

  GU_ROLE_CREATE: 'GU_ROLE_CREATE',
  GU_ROLE_READ: 'GU_ROLE_READ',
  GU_ROLE_UPDATE: 'GU_ROLE_UPDATE',
  GU_ROLE_DELETE: 'GU_ROLE_DELETE',

  GU_USER_CREATE: 'GU_USER_CREATE',
  GU_USER_READ: 'GU_USER_READ',
  GU_USER_UPDATE: 'GU_USER_UPDATE',
  GU_USER_DELETE: 'GU_USER_DELETE',
  GU_USER_ACTIVATE: 'GU_USER_ACTIVATE',
  GU_USER_DEACTIVATE: 'GU_USER_DEACTIVATE',
  GU_USER_GET_DEACTIVATED: 'GU_USER_GET_DEACTIVATED',

  GM_CONFIGURE_READ: 'GM_CONFIGURE_READ',
  GM_CONFIGURE_WRITE: 'GM_CONFIGURE_WRITE',
  GM_CONFIGURE_UPDATE: 'GM_CONFIGURE_UPDATE',
  GM_CONFIGURE_DELETE: 'GM_CONFIGURE_DELETE',

  GM_IMAGE_READ: 'GM_IMAGE_READ',
  GM_IMAGE_WRITE: 'GM_IMAGE_WRITE',
  GM_IMAGE_UPDATE: 'GM_IMAGE_UPDATE',
  GM_IMAGE_DELETE: 'GM_IMAGE_DELETE',

  GM_GALLERY_READ: 'GM_GALLERY_READ',
  GM_GALLERY_WRITE: 'GM_GALLERY_WRITE',
  GM_GALLERY_UPDATE: 'GM_GALLERY_UPDATE',
  GM_GALLERY_DELETE: 'GM_GALLERY_DELETE',

  GM_TAG_READ: 'GM_TAG_READ',
  GM_TAG_WRITE: 'GM_TAG_WRITE',
  GM_TAG_UPDATE: 'GM_TAG_UPDATE',
  GM_TAG_DELETE: 'GM_TAG_DELETE',

  GC_ARTICLE_READ: 'GC_ARTICLE_READ',
  GC_ARTICLE_WRITE: 'GC_ARTICLE_WRITE',
  GC_ARTICLE_UPDATE: 'GC_ARTICLE_UPDATE',
  GC_ARTICLE_DELETE: 'GC_ARTICLE_DELETE',
  GC_ARTICLE_REVOKELOCK: 'GC_ARTICLE_REVOKELOCK',
  GC_ARTICLE_PUBLISH: 'GC_ARTICLE_PUBLISH',

  GC_TAXONOMY_READ: 'GC_TAXONOMY_READ',
  GC_TAXONOMY_WRITE: 'GC_TAXONOMY_WRITE',
  GC_TAXONOMY_UPDATE: 'GC_TAXONOMY_UPDATE',
  GC_TAXONOMY_DELETE: 'GC_TAXONOMY_DELETE',

  GC_TEMPLATE_READ: 'GC_TEMPLATE_READ',
  GC_TEMPLATE_WRITE: 'GC_TEMPLATE_WRITE',
  GC_TEMPLATE_UPDATE: 'GC_TEMPLATE_UPDATE',
  GC_TEMPLATE_DELETE: 'GC_TEMPLATE_DELETE',
  GC_TEMPLATE_PUBLISH: 'GC_TEMPLATE_PUBLISH',

  GC_PAGE_READ: 'GC_PAGE_READ',
  GC_PAGE_WRITE: 'GC_PAGE_WRITE',
  GC_PAGE_UPDATE: 'GC_PAGE_UPDATE',
  GC_PAGE_DELETE: 'GC_PAGE_DELETE',
  GC_PAGE_PUBLISH: 'GC_PAGE_PUBLISH',

  GC_ROUTE_READ: 'GC_ROUTE_READ',
  GC_ROUTE_WRITE: 'GC_ROUTE_WRITE',
  GC_ROUTE_UPDATE: 'GC_ROUTE_UPDATE',
  GC_ROUTE_DELETE: 'GC_ROUTE_DELETE',

  GC_3RD_PARTY_WIDGET_READ: 'GC_3RD_PARTY_WIDGET_READ',
  GC_3RD_PARTY_WIDGET_WRITE: 'GC_3RD_PARTY_WIDGET_WRITE',
  GC_3RD_PARTY_WIDGET_UPDATE: 'GC_3RD_PARTY_WIDGET_UPDATE',
  GC_3RD_PARTY_WIDGET_DELETE: 'GC_3RD_PARTY_WIDGET_DELETE',

  GC_SYSTEM_WIDGET_READ: 'GC_SYSTEM_WIDGET_READ',
  GC_SYSTEM_WIDGET_WRITE: 'GC_SYSTEM_WIDGET_WRITE',
  GC_SYSTEM_WIDGET_UPDATE: 'GC_SYSTEM_WIDGET_UPDATE',
  GC_SYSTEM_WIDGET_DELETE: 'GC_SYSTEM_WIDGET_DELETE',

  GU_ACCOUNT_SETTINGS_UPDATE: 'GU_ACCOUNT_SETTINGS_UPDATE',

  GU_PASSWORD_CHANGE: 'GU_PASSWORD_CHANGE',
  GU_PERMISSION_READ: 'GU_PERMISSION_READ',
  GC_AUTHOR_READ: 'GC_AUTHOR_READ',
  GC_AUTHOR_WRITE: 'GC_AUTHOR_WRITE',
  GC_AUTHOR_UPDATE: 'GC_AUTHOR_UPDATE',
  GC_AUTHOR_DELETE: 'GC_AUTHOR_DELETE',

  GC_ARTICLE_TYPE_READ: 'GC_ARTICLE_TYPE_READ',
  GC_ARTICLE_TYPE_WRITE: 'GC_ARTICLE_TYPE_WRITE',
  GC_ARTICLE_TYPE_UPDATE: 'GC_ARTICLE_TYPE_UPDATE',
  GC_ARTICLE_TYPE_DELETE: 'GC_ARTICLE_TYPE_DELETE',

  GC_REDIRECT_READ: 'GC_REDIRECT_READ',
  GC_REDIRECT_WRITE: 'GC_REDIRECT_WRITE',
  GC_REDIRECT_UPDATE: 'GC_REDIRECT_UPDATE',
  GC_REDIRECT_DELETE: 'GC_REDIRECT_DELETE',

  GC_HTML_SNIPPET_READ: 'GC_HTML_SNIPPET_READ',
  GC_HTML_SNIPPET_WRITE: 'GC_HTML_SNIPPET_WRITE',
  GC_HTML_SNIPPET_UPDATE: 'GC_HTML_SNIPPET_UPDATE',
  GC_HTML_SNIPPET_DELETE: 'GC_HTML_SNIPPET_DELETE',

  // GC_ITEM_LIST_READ: 'GC_ITEM_LIST_READ',
  // GC_ITEM_LIST_WRITE: 'GC_ITEM_LIST_WRITE',
  // GC_ITEM_LIST_UPDATE: 'GC_ITEM_LIST_UPDATE',
  // GC_ITEM_LIST_DELETE: 'GC_ITEM_LIST_DELETE',

  GC_MENU_READ: 'GC_MENU_READ',
  GC_MENU_WRITE: 'GC_MENU_WRITE',
  GC_MENU_UPDATE: 'GC_MENU_UPDATE',
  GC_MENU_DELETE: 'GC_MENU_DELETE',

  GC_CONTENT_PANEL_READ: 'GC_CONTENT_PANEL_READ',
  GC_CONTENT_PANEL_WRITE: 'GC_CONTENT_PANEL_WRITE',
  GC_CONTENT_PANEL_UPDATE: 'GC_CONTENT_PANEL_UPDATE',
  GC_CONTENT_PANEL_DELETE: 'GC_CONTENT_PANEL_DELETE',

  GC_COLLECTION_TYPE_READ: 'GC_COLLECTION_TYPE_READ',
  GC_COLLECTION_TYPE_WRITE: 'GC_COLLECTION_TYPE_WRITE',
  GC_COLLECTION_TYPE_UPDATE: 'GC_COLLECTION_TYPE_UPDATE',
  GC_COLLECTION_TYPE_DELETE: 'GC_COLLECTION_TYPE_DELETE',

  GC_COLLECTION_READ: 'GC_COLLECTION_READ',
  GC_COLLECTION_WRITE: 'GC_COLLECTION_WRITE',
  GC_COLLECTION_UPDATE: 'GC_COLLECTION_UPDATE',
  GC_COLLECTION_DELETE: 'GC_COLLECTION_DELETE',
  GC_COLLECTION_PUBLISH: 'GC_COLLECTION_PUBLISH',
  GC_COLLECTION_REVOKELOCK: 'GC_COLLECTION_REVOKELOCK',

  GC_TAXONOMY_CONFIGURATION_READ: 'GC_TAXONOMY_CONFIGURATION_READ',
  GC_TAXONOMY_CONFIGURATION_UPDATE: 'GC_TAXONOMY_CONFIGURATION_UPDATE',

  GM_FILE_READ: 'GM_FILE_READ',
  GM_FILE_WRITE: 'GM_FILE_WRITE',
  GM_FILE_UPDATE: 'GM_FILE_UPDATE',
  GM_FILE_DELETE: 'GM_FILE_DELETE',

  GC_CONTENT_TAG_READ: 'GC_CONTENT_TAG_READ',
  GC_CONTENT_TAG_WRITE: 'GC_CONTENT_TAG_WRITE',
  GC_CONTENT_TAG_UPDATE: 'GC_CONTENT_TAG_UPDATE',
  GC_CONTENT_TAG_DELETE: 'GC_CONTENT_TAG_DELETE',

  GU_SSO_READ: 'GU_SSO_READ',
  GU_SSO_UPDATE: 'GU_SSO_UPDATE',

  GC_CONTENT_FILTER_READ: 'GC_CONTENT_FILTER_READ',
  GC_CONTENT_FILTER_WRITE: 'GC_CONTENT_FILTER_WRITE',
  GC_CONTENT_FILTER_UPDATE: 'GC_CONTENT_FILTER_UPDATE',
  GC_CONTENT_FILTER_DELETE: 'GC_CONTENT_FILTER_DELETE',
  GC_CONTENT_FILTER_CHANGE_VISIBILITY: 'GC_CONTENT_FILTER_CHANGE_VISIBILITY',

  ARTICLE_CUSTOM_FIELDS_READ: 'ARTICLE_CUSTOM_FIELDS_READ',
  ARTICLE_CUSTOM_FIELDS_UPDATE: 'ARTICLE_CUSTOM_FIELDS_UPDATE',
  COLLECTION_CUSTOM_FIELDS_READ: 'COLLECTION_CUSTOM_FIELDS_READ',
  COLLECTION_CUSTOM_FIELDS_UPDATE: 'COLLECTION_CUSTOM_FIELDS_UPDATE',
  TAXONOMY_CUSTOM_FIELDS_READ: 'TAXONOMY_CUSTOM_FIELDS_READ',
  TAXONOMY_CUSTOM_FIELDS_UPDATE: 'TAXONOMY_CUSTOM_FIELDS_UPDATE',

  GC_FIELD_GROUP_READ: 'GC_FIELD_GROUP_READ',
  GC_FIELD_GROUP_WRITE: 'GC_FIELD_GROUP_WRITE',
  GC_FIELD_GROUP_UPDATE: 'GC_FIELD_GROUP_UPDATE',
  GC_FIELD_GROUP_DELETE: 'GC_FIELD_GROUP_DELETE',

  GV_ACCESS_BUNDLE_READ: 'GV_ACCESS_BUNDLE_READ',
  GV_ACCESS_BUNDLE_WRITE: 'GV_ACCESS_BUNDLE_WRITE',
  GV_ACCESS_BUNDLE_UPDATE: 'GV_ACCESS_BUNDLE_UPDATE',
  GV_ACCESS_BUNDLE_DELETE: 'GV_ACCESS_BUNDLE_DELETE',
  GV_ACCESS_BUNDLE_PUBLISH: 'GV_ACCESS_BUNDLE_PUBLISH',

  GV_CONFIGURE_READ: 'GV_CONFIGURE_READ',
  GV_CONFIGURE_UPDATE: 'GV_CONFIGURE_UPDATE',

  ARTICLE_GLIDE_VERIFY_READ: 'ARTICLE_GLIDE_VERIFY_READ',
  ARTICLE_GLIDE_VERIFY_UPDATE: 'ARTICLE_GLIDE_VERIFY_UPDATE',

  GC_LIVE_REPORT_READ: 'GC_LIVE_REPORT_READ',
  GC_LIVE_REPORT_WRITE: 'GC_LIVE_REPORT_WRITE',
  GC_LIVE_REPORT_UPDATE: 'GC_LIVE_REPORT_UPDATE',
  GC_LIVE_REPORT_DELETE: 'GC_LIVE_REPORT_DELETE',
  GC_LIVE_REPORT_PUBLISH: 'GC_LIVE_REPORT_PUBLISH',

  GU_USER_ACCOUNT_READ: 'GU_USER_ACCOUNT_READ',
  GU_USER_ACCOUNT_UPDATE: 'GU_USER_ACCOUNT_UPDATE',

  LIVE_REPORT_GLIDE_VERIFY_READ: 'LIVE_REPORT_GLIDE_VERIFY_READ',
  LIVE_REPORT_GLIDE_VERIFY_UPDATE: 'LIVE_REPORT_GLIDE_VERIFY_UPDATE',

  GT_SUBSCRIBER_READ: 'GT_SUBSCRIBER_READ',
  GT_SUBSCRIBER_WRITE: 'GT_SUBSCRIBER_WRITE',
  GT_SUBSCRIBER_UPDATE: 'GT_SUBSCRIBER_UPDATE',
  GT_SUBSCRIBER_DELETE: 'GT_SUBSCRIBER_DELETE',

  ARTICLE_MULTI_CHANNEL_PUBLISHING_READ: 'ARTICLE_MULTI_CHANNEL_PUBLISHING_READ',
  ARTICLE_MULTI_CHANNEL_PUBLISHING_UPDATE: 'ARTICLE_MULTI_CHANNEL_PUBLISHING_UPDATE',
  COLLECTION_MULTI_CHANNEL_PUBLISHING_READ: 'COLLECTION_MULTI_CHANNEL_PUBLISHING_READ',
  COLLECTION_MULTI_CHANNEL_PUBLISHING_UPDATE: 'COLLECTION_MULTI_CHANNEL_PUBLISHING_UPDATE',

  GC_CONTENT_LOCALE_READ: 'GC_CONTENT_LOCALE_READ',
  GC_CONTENT_LOCALE_WRITE: 'GC_CONTENT_LOCALE_WRITE',
  GC_CONTENT_LOCALE_UPDATE: 'GC_CONTENT_LOCALE_UPDATE',
  GC_CONTENT_LOCALE_DELETE: 'GC_CONTENT_LOCALE_DELETE',

  // TODO: UPDATE THIS WHEN NEW NOTIFICATIONS ARE ADDED (create new permissions for root and add instead of system ones)
  // GC_NOTIFICATION_CREATE: 'GC_SYSTEM_NOTIFICATION_CREATE',
  // GC_NOTIFICATION_UPDATE: 'GC_SYSTEM_NOTIFICATION_UPDATE',
  // GC_NOTIFICATION_DELETE: 'GC_SYSTEM_NOTIFICATION_DELETE',

  GC_SYSTEM_NOTIFICATION_CREATE: 'GC_SYSTEM_NOTIFICATION_CREATE',
  GC_SYSTEM_NOTIFICATION_UPDATE: 'GC_SYSTEM_NOTIFICATION_UPDATE',
  GC_SYSTEM_NOTIFICATION_DELETE: 'GC_SYSTEM_NOTIFICATION_DELETE',

  GC_GAIA_SUMMARIZE: 'GC_GAIA_SUMMARIZE',
  GC_GAIA_GENERATE: 'GC_GAIA_GENERATE',
  GC_GAIA_TRANSLATE: 'GC_GAIA_TRANSLATE',

  GC_LIVE_REPORT_TYPE_READ: 'GC_LIVE_REPORT_TYPE_READ',
  GC_LIVE_REPORT_TYPE_WRITE: 'GC_LIVE_REPORT_TYPE_WRITE',
  GC_LIVE_REPORT_TYPE_UPDATE: 'GC_LIVE_REPORT_TYPE_UPDATE',
  GC_LIVE_REPORT_TYPE_DELETE: 'GC_LIVE_REPORT_TYPE_DELETE',

  GC_CONTENT_QUEUE_READ: 'GC_CONTENT_QUEUE_READ',
  GC_CONTENT_QUEUE_WRITE: 'GC_CONTENT_QUEUE_WRITE',
  GC_CONTENT_QUEUE_UPDATE: 'GC_CONTENT_QUEUE_UPDATE',
  GC_CONTENT_QUEUE_DELETE: 'GC_CONTENT_QUEUE_DELETE',

  CONTENT_QUEUE_GLOBAL_UPDATE :  'CONTENT_QUEUE_GLOBAL_UPDATE',

  GC_TAXONOMY_ACTIVATE : 'GC_TAXONOMY_ACTIVATE',
  GC_TAXONOMY_DEACTIVATE: 'GC_TAXONOMY_DEACTIVATE',

  GC_EXTERNAL_APP_READ: 'GC_EXTERNAL_APP_READ',
  GC_EXTERNAL_APP_WRITE: 'GC_EXTERNAL_APP_WRITE',
  GC_EXTERNAL_APP_UPDATE: 'GC_EXTERNAL_APP_UPDATE',
  GC_EXTERNAL_APP_DELETE: 'GC_EXTERNAL_APP_DELETE',
};
