import { withLatestFrom, catchError, tap, filter, mergeMap, take, map, switchMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { Router } from '@angular/router';

import { AppState } from '../app-reducer';
import { ArticlesService } from '../../api/articles/articles.service';
import { BreadcrumbService } from '../../../fury-core/breadcrumb/breadcrumb.service';
import {
  UpdateArticleCompleteAction,
  UNPUBLISH_ARTICLE,
  UnpublishArticleCompleteAction,
  ARTICLE_ACTION_FAILED,
  ArticleFailedAction,
  SWITCH_ACTIVE_REVISION,
  SwitchActiveArticleRevisionCompleteAction,
  GET_ARTICLE_COMPLETE,
  UPDATE_ARTICLE_COMPLETE,
  UpdateRelatedArticlesAction,
  SetArticleLoadingFlagAction,
  SET_IS_USING_AUTOMATIC_RELATED_ARTICLES,
  PUBLISH_ARTICLE,
  PublishArticleCompleteAction,
  COPY_ARTICLE_BY_ID,
  ARTICLE_ACTION_NOT_FOUND,
  ArticleNotFoundAction,
  SCHEDULE_ARTICLE,
  UNSCHEDULE_ARTICLE,
  LoadLocalizedVersionsAction,
  CREATE_ARTICLE_COMPLETE,
  LOCALIZE_ARTICLE,
  LocalizeArticleAction,
  LOCALIZE_ARTICLE_SUCCESS,
  LocalizeArticleSuccessAction,
  SWITCH_ACTIVE_REVISION_COMPLETE,
} from './article.actions';
import {
  GET_ARTICLE,
  GetArticleCompleteAction,
  UPDATE_ARTICLE,
  CREATE_ARTICLE,
  CreateArticleCompleteAction,
} from './article.actions';
import { UnsafeAction } from '../unsafe-action.interface';
import { getActiveArticleState, getActiveRevisionContentBlocks } from './article.reducers';
import { ActiveArticleState, Article, ArticleRevision, getEmptyActiveArticle } from './article.model';
import { AccountSettingsService } from '../../api/account-settings/accounts-settings.service';
import { getActiveWorkflowForArticles } from '../workflows/workflow.reducer';
import { isItemLocked } from '../lock/lock.reducer';
import { RefreshLockAction } from '../lock/lock.actions';
import { regenerateBodyNodeIds } from './article-copy-helpers';
import { ArticleBodyReferencedContentService } from '../../body-content/referenced-content/article-body-referenced-content.service';
import {
  ARTICLE_AUTOSAVE,
  ArticleAutosaveSuccessAction,
  DISCARD_ARTICLE_AUTOSAVE,
  DiscardArticleAutosaveSuccessAction,
  SetRelatedArticlesLoadingFlagAction,
  ScheduleArticleSuccessAction,
  UnscheduleArticleSuccessAction,
  DISCARD_ARTICLE_AUTOSAVE_SUCCESS,
  SwitchActiveArticleRevisionAction,
  DiscardArticleAutosaveAction,
  PublishArticleAction,
  TRANSLATE_ARTICLE,
} from '..';
import { ContentLocalesService } from '../../api/content-locales/content-locales.service';
import { NewLocalizationModes } from '../../../gpp-shared/content-locale-components/localize-mode-dialog/new-localization-modes';
import { get } from 'lodash-es';
import { getArticleTypeById } from '../article-type/article-type.reducer';
import { ModalsService } from '../../../shared/modals/modals.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { getContentLocaleById, getContentLocalesList } from '../content-locales/content-locales.reducer';
import { PermissionService } from '../../api/auth/permissions.service';
import { Permissions } from '../auth/permissions';
import { ArticleTypesService } from '../../api/article-types/article-types.service';

@Injectable()
export class ActiveArticleEffects {
  translationLoaderRef: MatDialogRef<any>;

  // needs to bo coppied
  copyArticleById$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(COPY_ARTICLE_BY_ID)).pipe(
    mergeMap((action: UnsafeAction) =>
      this.articlesService.getArticleById(+action.payload).pipe(
        map((article: Article) => {
          const workingRevision = {
            ...article.latestRevision,
            body: regenerateBodyNodeIds(article.latestRevision.body),
            publishDateFrom: null,
            publishDateTo: null,
            lastPublish: null,
            lastUpdate: null,
            isBreakingNews: null,
            articleId: null,
            createdAt: null,
            id: null,
            status: null,
            accountId: article.latestRevision.accountId,
            headline: $localize `:This is the headline for new content created by copy:Copy of ${article.latestRevision.headline}`,
          };

          // TODO take a look at differences between ActiveArticleState and Article models, this is a bit hacky
          const mappedArticle = {
            ...article,
            id: null,
            localizationId: null,
            glideId: null,
            createdAt: null,
            updatedAt: null,
            articleRevisions: null,
            articleRevisionsList: [],

            // ui data
            activeRevisionId: null,
            publishedRevisionId: null,
            publishedRevision: null,
            autosavedRevisionId: null,
            autosavedRevision: null,


            publishDateFrom: null,
            publishDateTo: null,
            lastPublish: null,
            lastUpdate: null,
            isBreakingNews: null,

            latestRevision: workingRevision,

            // ui flags
            loaded: true,
            loading: false,
            referencedContent: {},
            relatedArticles: [],
          };

          // regular article copy does NOT result in adding
          // of the copy to the same localization group.
          // remove localizationId so that API can set it
          delete mappedArticle.localizationId;

          return new GetArticleCompleteAction(mappedArticle);
        })
      )
    )
  ));

  localizeArticle$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(LOCALIZE_ARTICLE)).pipe(
    mergeMap((action: LocalizeArticleAction) =>
      combineLatest([
        this.articlesService.getArticleById(+action.payload.id),
        this.contentLocaleService.getContentLocaleById(+action.payload.contentLocaleId)
      ])
      .pipe(
        map(([article, contentLocale]: any) => {

          const localizationMode = action.payload.mode;
          const headline = $localize `:This is the headline for new content created by localizing:Localisation copy of ${article.latestRevision.headline}`;

          // handle case when we create blank article or copy custom data only
          if(localizationMode !== NewLocalizationModes.Copy) {
            const newArticle: any = {
              ...getEmptyActiveArticle({
                loaded: true,
                loading: false,
                typeId: +action.payload.typeId
              }),
              localizationId: action.payload.localizationId,
              contentLocaleId: +action.payload.contentLocaleId,
              contentLocale: contentLocale,
            }

            newArticle.latestRevision = newArticle.workingRevision;
            newArticle.latestRevision.headline = headline;

            // add custom data on top of the empty template
            if(localizationMode === NewLocalizationModes.CopyCustomData) {
              const customData = get(article, 'latestRevision.additionalItems.customTypeData', null);
              newArticle.latestRevision.additionalItems.customTypeData = customData;
            }

            return new LocalizeArticleSuccessAction(newArticle);
          }


          // create new localization via article COPY
          const workingRevision = {
            ...article.latestRevision,
            body: regenerateBodyNodeIds(article.latestRevision.body),
            publishDateFrom: null,
            publishDateTo: null,
            lastPublish: null,
            lastUpdate: null,
            isBreakingNews: null,
            articleId: null,
            createdAt: null,
            id: null,
            status: null,
            accountId: article.latestRevision.accountId,
            headline: headline
          };

          const mappedArticle = {
            ...article,

            // localization relevant
            localizationId: action.payload.localizationId,
            contentLocaleId: +action.payload.contentLocaleId,
            contentLocale: contentLocale,

            id: null,
            glideId: null,
            createdAt: null,
            updatedAt: null,
            articleRevisions: {},
            articleRevisionsList: [],

            // ui data
            activeRevisionId: null,
            publishedRevisionId: null,
            publishedRevision: null,
            autosavedRevisionId: null,
            autosavedRevision: null,

            publishDateFrom: null,
            publishDateTo: null,
            lastPublish: null,
            lastUpdate: null,
            isBreakingNews: null,

            latestRevision: workingRevision,

            // ui flags
            loaded: true,
            loading: false,
            referencedContent: {},
            relatedArticles: [],
          };

          return new LocalizeArticleSuccessAction(mappedArticle);
        })
      )
    )
  ));

  loadActiveArticle$: Observable<Action> = createEffect(() =>
  this.actions$.pipe(ofType(GET_ARTICLE)).pipe(
    mergeMap((action: UnsafeAction) => {
      const glideIdRegEx = /^[a][a-zA-Z0-9]{11}$/;
      const isGlideId: boolean = glideIdRegEx.test(action.payload);
      const article$ = isGlideId
        ? this.articlesService.getArticleByGlideId(action.payload)
        : this.articlesService.getArticleById(action.payload);

      return article$.pipe(
        filter((article) => {
          if (article) {
            return true;
          }
          this.snackBar.open($localize`Invalid Article Id`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['articles/']);
          return false;
        }),
        tap((article: any) => {
          if (isGlideId) {
            const newUrl = window.location.href.replace(action.payload, article.id);
            history.replaceState(null, '', newUrl);
          }
          this.breadcrumbService.addFriendlyNameForRoute(this.router.url, article.title);
        }),
        map((article) => new GetArticleCompleteAction(article)),
        catchError((e) => {
          return of(new ArticleNotFoundAction({ error: e, action }));
        })
      );
    })
  )
);


  getLocalizedVersions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      // TODO handle for Localize Article Success? Or just don't fire it
      ofType(GET_ARTICLE_COMPLETE, UPDATE_ARTICLE_COMPLETE, CREATE_ARTICLE_COMPLETE, LOCALIZE_ARTICLE),
      filter((action: UnsafeAction)=> !!action.payload.id),
      mergeMap((action: UnsafeAction) => {

        if(action.type === LOCALIZE_ARTICLE)  {
          // take the first entry from the list
          return this.articlesService.getLocalizedVersionsByLocalizationIds([action.payload?.localizationId])
            .pipe(map(localizationRecords => localizationRecords[0]));
        }

        return this.articlesService.getLocalizedVersionsByArticleId(action.payload?.id);
      }),
      map((localizedVersions: any) => new LoadLocalizedVersionsAction(localizedVersions)),
      catchError((e) => of(new ArticleFailedAction({ error: e })))
    )
  );

  switchActiveRevision$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(SWITCH_ACTIVE_REVISION))
    .pipe(
      mergeMap((action: UnsafeAction) => {
        const revisionId: number = action.payload;
        return this.store.select(getActiveArticleState).pipe(
          take(1),
          map((activeArticle: ActiveArticleState) => {
            const cachedRevision: ArticleRevision = activeArticle.articleRevisions[revisionId];
            return {
              cachedRevision,
              revisionId,
              articleId: activeArticle.id,
              autosavedRevisionId: activeArticle.autosavedRevisionId || activeArticle.autosavedRevision?.id
            };
          })
        );
      }),
      // discarding autosave as part of changing of the revision
      mergeMap(({ cachedRevision, revisionId, articleId, autosavedRevisionId }) => {
        // no autosave or we're switching to it, no need to discard it
        if(!autosavedRevisionId || autosavedRevisionId === revisionId) {
          return of({ cachedRevision, revisionId });
        }
        // discard the autosave and then pass along the data
        // maybe do this as a side effect via tap? this ensures it happens though
        return this.articlesService.discardAutosave(articleId)
          .pipe(map(() => {
            return { cachedRevision, revisionId, articleId };
          }));
      }),
      mergeMap(({ cachedRevision, revisionId }) => {
        if (cachedRevision) {
          return of(new SwitchActiveArticleRevisionCompleteAction(cachedRevision));
        }
        return this.articlesService
          .getArticleRevisionById(revisionId)
          .pipe(map((revision) => new SwitchActiveArticleRevisionCompleteAction(revision)));
      }),
      tap(() => this.snackBar.open($localize `Active revision updated`, $localize `Close`, { duration: 4000 })),
      catchError((e) => of(new ArticleFailedAction({ error: e })))
    ));

  createArticle$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(CREATE_ARTICLE)).pipe(
    withLatestFrom(
      this.store.select((state) => state.activeArticle.workingRevision.authors),
      this.store.select(getActiveRevisionContentBlocks)
    ),
    mergeMap(([action, authors, body]: [UnsafeAction, any, any]) => {
      const authorIds = authors.map((author) => author.id);
      const articlePayload = { ...action.payload, authorIds, body };
      return this.articlesService.createArticle(articlePayload).pipe(
        tap((article) => {
          this.snackBar.open($localize `Article saved`, $localize `Close`, { duration: 4000 });
          this.router.navigate(['articles/', article && article.id]);
        }),
        map((article) => new CreateArticleCompleteAction(article)),
        catchError((e) => of(new ArticleFailedAction({ error: e, action })))
      );
    })
  ));


  updateActiveArticle$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(UPDATE_ARTICLE)).pipe(
    withLatestFrom(
      this.store.select((state) => state.activeArticle.workingRevision.authors),
      this.store.select(getActiveRevisionContentBlocks)
    ),
    mergeMap(([action, authors, body]: [UnsafeAction, any, any]) => {
      const authorIds = authors.map((author) => author.id);
      const articlePayload = { ...action.payload, authorIds, body };
      // this is a regular article update
      return this.articlesService.updateArticle(articlePayload).pipe(
        tap((article) => {
          this.breadcrumbService.addFriendlyNameForRoute(this.router.url, article.headline);
          this.snackBar.open($localize `Article saved`, $localize `Close`, { duration: 4000 });
        }),
        map((article) => new UpdateArticleCompleteAction(article)),
        catchError((e) =>
          this.store.select(isItemLocked).pipe(
            take(1),
            map((isLocked) => {
              if (!isLocked) {
                return new ArticleFailedAction({ error: e, action });
              }
              this.store.dispatch(new SetArticleLoadingFlagAction(false));
              return new RefreshLockAction();
            })
          )
        )
      );
    })
  ));

  autosaveActiveArticle$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(ARTICLE_AUTOSAVE)).pipe(
    filter((action: UnsafeAction) => {
      const isCreateArticleMode = !action.payload.id;
      window.printInfoLogs && isCreateArticleMode && console.log('Not handling article create autosave yet');
      return !isCreateArticleMode;
    }),
    withLatestFrom(
      this.store.select((state) => state.activeArticle.workingRevision.authors),
      this.store.select(getActiveRevisionContentBlocks)
    ),
    mergeMap(([action, authors, body]: [UnsafeAction, any, any]) => {
      const authorIds = authors.map((author) => author.id);
      const autosavePayload = { ...action.payload, authorIds, body };
      delete autosavePayload.promoDetails;
      delete autosavePayload.customTypeData;
      // this is a regular article update
      return this.articlesService.makeAutosave(autosavePayload).pipe(
        tap((article) => {
          this.breadcrumbService.addFriendlyNameForRoute(this.router.url, article.headline);
        }),
        map((article) => new ArticleAutosaveSuccessAction(article)),
        catchError((e) =>
          this.store.select(isItemLocked).pipe(
            take(1),
            map((isLocked) => {
              if (!isLocked) {
                return new ArticleFailedAction({ error: e, action });
              }
              this.store.dispatch(new SetArticleLoadingFlagAction(false));
              return new RefreshLockAction();
            })
          )
        )
      );
    })
  ));

  discardArticleAutosave$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(DISCARD_ARTICLE_AUTOSAVE),
      mergeMap((action: DiscardArticleAutosaveAction) => {
        return this.articlesService.discardAutosave(action.payload).pipe(
          map((autosavedRevisionId) => {
            return new DiscardArticleAutosaveSuccessAction(autosavedRevisionId, action.fireAndForget)
          })
        )
      })
    )
  );

  discardArticleAutosaveSwitchRevision$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(DISCARD_ARTICLE_AUTOSAVE_SUCCESS),
      // we won't fire any additional effects if the fireAndForget flag is set
      filter((action: DiscardArticleAutosaveSuccessAction) => {
        return !action.fireAndForget;
      }),
      mergeMap((action: DiscardArticleAutosaveSuccessAction) => {
        // on discarding autosave revision, switch to latest revision
        return this.store.select(getActiveArticleState).pipe(
          take(1),
          map((activeArticle: ActiveArticleState) => {
            const latestRevisionId = activeArticle.latestRevisionId;
            return new SwitchActiveArticleRevisionAction(latestRevisionId);
          })
        );
      })
    )
  );

  publishArticle$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(PUBLISH_ARTICLE)).pipe(
    mergeMap((action: PublishArticleAction) => {
      const articlePayload = action.payload;

      if (action.mockPublish) {
        return combineLatest([
          this.articlesService.getArticleById(articlePayload.articleId),
          this.store.select(getActiveWorkflowForArticles)
        ])
          .pipe(
            take(1),
            map(([article, activeWorkflow]) => {
              const articleData = {
                "publishedState": activeWorkflow.publishedState,
                "unpublishedState": activeWorkflow.unpublishedState,
                "createdAt": article.createdAt,
                "updatedAt": article.updatedAt,
                "firstPublishedAt": article.firstPublishedAt,
                "lastPublishedAt": article?.lastPublishedAt,
                "transmitUpdatedAt": article.transmitUpdatedAt,
                "publishedRevisionId": article.publishedRevisionId,
                "stagedRevisionId": article.stagedRevisionId,
                "id": article.id,
                "glideId": article.glideId,
              }
              return articleData;
            }),
            tap((data) => {
              const message = $localize`Scheduled article published successfully`
              this.snackBar.open(message, $localize`Close`, { duration: 4000 })
            }),
            map((data) => new PublishArticleCompleteAction(data)),
            catchError((e) => of(new ArticleFailedAction({ error: e, action })))
          )
      }

      return this.articlesService
        .publishArticle(articlePayload.articleId, articlePayload.publishedRevisionId)
        .pipe(
          mergeMap((res) => {
            return this.store.select(getActiveWorkflowForArticles).pipe(
              take(1),
              map((activeWorkflow) => {
                const { data, message } = res;
                data.publishedState = activeWorkflow.publishedState;
                data.unpublishedState = activeWorkflow.unpublishedState;
                return { data, message };
              })
            );
          }),
          tap((res) => {
            const message = $localize `Article published successfully`
            this.snackBar.open(message, $localize `Close`, { duration: 4000 })
          }),
          map((res) => new PublishArticleCompleteAction(res.data)),
          catchError((e) => of(new ArticleFailedAction({ error: e, action })))
        );
    })
  ));


  unpublishArticle$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(UNPUBLISH_ARTICLE)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.articlesService.unpublishArticle(action.payload).pipe(
        mergeMap((message) => {
          return this.store.select(getActiveWorkflowForArticles).pipe(
            take(1),
            map((activeWorkflow) => {
              const data = activeWorkflow.unpublishedState;
              return { data, message };
            })
          );
        }),
        tap((res) => {
          const message = $localize `Successfully unpublished.`;
          this.snackBar.open(message, $localize `Close`, { duration: 4000 })
        }),
        map((res) => new UnpublishArticleCompleteAction(res.data)),
        catchError((e) => of(new ArticleFailedAction({ error: e, action })))
      );
    })
  ));


  getReferencedContent$: Observable<any> = createEffect(() => this.actions$
    .pipe(ofType(GET_ARTICLE_COMPLETE, SWITCH_ACTIVE_REVISION_COMPLETE, LOCALIZE_ARTICLE_SUCCESS))
    .pipe(
      mergeMap((action: UnsafeAction) => {
        return this.store.select(getActiveArticleState).pipe(
          take(1),
          map((activeArticle: ActiveArticleState) => ({ activeArticle }))
        );
      }),
      filter(({ activeArticle }) => !!activeArticle.workingRevision.body),
      tap(({ activeArticle }) => {
        const body = activeArticle.workingRevision.body;
        const cachedReferencedContent = activeArticle.referencedContent;
        const localeId = get(activeArticle, 'contentLocale.id', null);
        this.referencedContentService.loadReferencedContentForContentModel(body, cachedReferencedContent, localeId);
      })
    ), { dispatch: false });


  getRelatedArticles$: Observable<Action> = createEffect(() => this.actions$
      .pipe(
        ofType(
          GET_ARTICLE_COMPLETE,
          LOCALIZE_ARTICLE_SUCCESS,
          SWITCH_ACTIVE_REVISION_COMPLETE,
          UPDATE_ARTICLE_COMPLETE,
          SET_IS_USING_AUTOMATIC_RELATED_ARTICLES
        )
      )
      .pipe(
        mergeMap((action: UnsafeAction) => {
          return this.store.select(getActiveArticleState).pipe(
            take(1),
            map((activeArticle) => {
              return {
                action: action.type,
                revisionId: activeArticle.activeRevisionId,
                useAutomaticRelatedArticles:
                  activeArticle.workingRevision.useAutomaticRelatedArticles,
                typeId: activeArticle.workingRevision.typeId,
              };
            })
          );
        }),
        mergeMap((data) => {
          return this.store.select(getArticleTypeById(data.typeId)).pipe(
            filter((at) => !!at),
            take(1),
            map((at) => ({ ...data, at }))
          );
        }),
        filter(({ action, revisionId, useAutomaticRelatedArticles, at }) => {
          const handleAction = useAutomaticRelatedArticles || action !== UPDATE_ARTICLE_COMPLETE;
          const sidebarOptions = get(at, 'additionalConfiguration.articleSidebar', []);
          const relatedArticlesPanelVisible = sidebarOptions.find(option => option.key === 'related_articles')?.enabled;
          return !!revisionId && handleAction && relatedArticlesPanelVisible;
        }),
        mergeMap(({ revisionId, useAutomaticRelatedArticles }) => {
          this.store.dispatch(new SetRelatedArticlesLoadingFlagAction(true));
          const size = this.accountSettingsService.getNumberOfRelatedArticles();
          return this.articlesService.getRelatedArticles({
            revisionId,
            size,
            useAutomaticRelatedArticles,
          });
        }),
        map((relatedArticles) => new UpdateRelatedArticlesAction(relatedArticles)),
        catchError((e) => of(new ArticleFailedAction({ error: e })))
      ));

    // find by looking at article actio failed
  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(ARTICLE_ACTION_FAILED)).pipe(
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      // Handling Taxonomy permissions
      const message = (err.payload && err.payload.error && err.payload.error.message) || '';
      if (message === 'Forbidden') {
        this.router.navigate(['articles/']);
        const errorMessage =
          $localize `Access denied. You may not have the appropriate Taxonomy permissions to access this article.`;
        return this.snackBar.open(errorMessage, $localize `Close`, { duration: 5000 });
      }
      console.error('Article action ERROR', err);
      // TODO figure out how to properly localize this, likely we'll need to explicitly list all cases
      this.snackBar.open($localize `Action failed: ${actionType}`, $localize `Close`, { duration: 4000 });
    }),
    // Note: stop effect propagation
    filter((err) => false),
    map((err) => ({ type: 'NULL_ACTION' }))
  ));


  actionNotFound$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(ARTICLE_ACTION_NOT_FOUND)).pipe(
    tap((err: any) => {
      const message = (err.payload && err.payload.error && err.payload.error.message) || '';
      this.router.navigate(['articles/']);
      // Handling Taxonomy permissions
      if (message === 'Forbidden') {
        const errorMessage =
          $localize `Access denied. You may not have the appropriate Taxonomy permissions to access this article.`;
        return this.snackBar.open(errorMessage, $localize `Close`, { duration: 5000 });
      }

      this.snackBar.open($localize `Article not found.`, $localize `Close`, { duration: 4000 });
    }),
    // Note: stop effect propagaion
    filter((err) => false),
    map((err) => ({ type: 'NULL_ACTION' }))
  ));


  scheduleArticle$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(SCHEDULE_ARTICLE)).pipe(
    mergeMap((action: UnsafeAction) => {
      const articlePayload = action.payload;
      return this.articlesService
        .scheduleArticle(articlePayload.articleId, articlePayload.publishedRevisionId)
        .pipe(
          mergeMap((res) => {
            return this.store.select(getActiveWorkflowForArticles).pipe(
              take(1),
              map((activeWorkflow) => {
                const { data, message } = res;
                data.scheduledState = activeWorkflow.scheduledState;
                return { data, message };
              })
            );
          }),
          tap((res) => {
            const message = $localize `Article scheduled successfully.`
            this.snackBar.open(message, $localize `Close`, { duration: 4000 })
          }),
          map((res) => new ScheduleArticleSuccessAction(res.data)),
          catchError((e) => of(new ArticleFailedAction({ error: e, action })))
        );
    })
  ));


  unscheduleArticle$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(UNSCHEDULE_ARTICLE)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.articlesService.unscheduleArticle(action.payload).pipe(
        mergeMap((message) => {
          return this.store.select(getActiveWorkflowForArticles).pipe(
            take(1),
            map((activeWorkflow) => {
              const data = activeWorkflow.publishableState;
              return { data, message };
            })
          );
        }),
        tap((res) => {
          const message = $localize `Successfully unscheduled.`
          this.snackBar.open(message, $localize `Close`, { duration: 4000 })
        }),
        map((res) => new UnscheduleArticleSuccessAction(res.data)),
        catchError((e) => of(new ArticleFailedAction({ error: e, action })))
      );
    })
  ));

  translateArticle$: Observable<Action> | any = createEffect(() => this.actions$.pipe(ofType(TRANSLATE_ARTICLE)).pipe(
    tap(() => this.translationLoaderRef = this.modalsService.translationLoader()),
    mergeMap((action: UnsafeAction) => combineLatest([
      this.articleTypeService.getOne(+action.payload.typeId),
      this.store.select(getContentLocalesList).pipe(filter(locales => !!locales.length), take(1))
    ]).pipe(map(([at, locales]) => {
        const activeLocales = locales.filter(l => l.active);
        const gaiaEnabled = this.accountSettingsService.isGAIAEnabled();
        const translateEnabled = get(at, 'gaiaConfiguration.translate.enabled', false);
        const hasTranslatePermission = this.permissionService.hasPermission(Permissions.GC_GAIA_TRANSLATE);
        const canTranslate = gaiaEnabled && hasTranslatePermission && translateEnabled && activeLocales.length > 1;
        if (canTranslate) {
          const locale = activeLocales.find(l => l.id === +action.payload.contentLocaleId);
          return [locale, action];
        }
        this.snackBar.open($localize`Invalid GAIA Translate configuration`, $localize`Close`, { duration: 3000 });
        this.router.navigate(['articles']);
        this.translationLoaderRef.close();
        return null;
    }))),
    filter(r => !!r),
    switchMap(([locale, action]) =>
      this.articlesService.translateArticle(+action.payload.id, locale.id)
        .pipe(
          map(article => {
            const { mode, localizationId } = action.payload;
            const workingRevision = {
              ...article,
              body: regenerateBodyNodeIds(article.body),
              publishDateFrom: null,
              publishDateTo: null,
              lastPublish: null,
              lastUpdate: null,
              isBreakingNews: null,
              articleId: null,
              createdAt: null,
              id: null,
              status: null,
            };

            const clearCustomData = mode === NewLocalizationModes.TranslateWithoutCustomData && get(workingRevision, 'additionalItems.customTypeData', null);
            if (clearCustomData) {
              delete workingRevision.additionalItems.customTypeData;
            }

            const newArticle: any = {
              ...getEmptyActiveArticle({
                loaded: true,
                loading: false,
                typeId: +article.typeId,
                contentLocale: locale,
                contentLocaleId: locale.id,
              }),
              localizationId,
              workingRevision,
              latestRevision: { ...workingRevision },
            };
            return new GetArticleCompleteAction(newArticle);
          }),
          catchError((e) => {
            this.router.navigate(['articles']);
            return of(new ArticleFailedAction({ error: e, action: { type: TRANSLATE_ARTICLE } }));
          }),
          tap(() => this.translationLoaderRef.close()),
        )
    ),
  ));

  constructor(
    private actions$: Actions,
    private articlesService: ArticlesService,
    private contentLocaleService: ContentLocalesService,
    public snackBar: MatSnackBar,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private store: Store<AppState>,
    private accountSettingsService: AccountSettingsService,
    private referencedContentService: ArticleBodyReferencedContentService,
    private modalsService: ModalsService,
    private permissionService: PermissionService,
    private articleTypeService: ArticleTypesService,
  ) {}

}
