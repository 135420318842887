import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Actions, ofType } from '@ngrx/effects';
import { filter, Subscription, take } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Store } from '@ngrx/store';
import { AuthService } from '../../../../core/api';
import { BidiService } from '../../../../core/i18n/bidi.service';
import { LOGGED_IN } from '../../../../core/store';
import { AppState } from '../../../../core/store/app-reducer';
import { GetActiveExternalAppAction } from '../../../../core/store/external-apps/external-apps.actions';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { getActiveExternalApp } from '../../../../core/store/external-apps/external-apps.reducer';
import { ExternalApp } from '../../../../core/store/external-apps/external-apps.model';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { PageTitleService } from '../../../../core/miscellaneous/page-title.service';

@Component({
  standalone: true,
  selector: 'gd-external-app-view',
  templateUrl: './external-app-view.component.html',
  styleUrls: ['./external-app-view.component.scss'],
  imports: [CommonModule, MatIconModule, MatCardModule, MatTooltipModule],
})
export class ExternalAppViewComponent implements OnInit, OnDestroy {
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();
  url;
  externalApp: ExternalApp;

  @ViewChild('ext_app_container', { static: false }) iframeRef: ElementRef;
  componentSubs: Subscription = new Subscription();

  constructor(
    private bidiService: BidiService,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private actions$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private pageTitleService: PageTitleService
  ) {}

  ngOnInit() {
    // subscription added for reinitializing the page because the route is "same" for all external apps
    this.componentSubs.add(
      this.route.params.subscribe((params) => {
        this.url = null;
        const appSlug = params?.slug;
        if (!appSlug) {
          this.router.navigate(['dashboard']);
          this.snackBar.open($localize`External app not found`, $localize`Close`, {
            duration: 3000,
          });
          return;
        }
        setTimeout(() => this.loadExternalApp(appSlug), 0);
      })
    );

    // send new auth data to iframe in case of token refresh
    this.componentSubs.add(
      this.actions$
        .pipe(
          ofType(LOGGED_IN),
          filter(() => !!(this.url && this.iframeRef && this.externalApp?.sendGlideAuthToken))
        )
        .subscribe(() => this.sendAuthDataToIframe())
    );
  }

  loadExternalApp(slug) {
    this.store.dispatch(new GetActiveExternalAppAction({ key: 'slug', value: slug }));
    this.componentSubs.add(
      this.store
        .select(getActiveExternalApp)
        .pipe(
          filter((app) => !!app),
          take(1)
        )
        .subscribe((app) => {
          this.externalApp = app;
          this.pageTitleService.setPageEntityTitle(app.name);
          const url = (app?.url || '').trim();
          if (url && this.extractDomainFromURL(url)) {
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(app.url);
          }
        })
    );
  }

  sendAuthDataToIframe() {
    const message = {
      type: 'NEW_GLIDE_CMS_AUTH_TOKEN',
      username: this.authService.userData.username,
      accountId: +this.authService.userData.accountId,
      accountSlug: this.authService.userData.accountSlug,
      token: this.authService.authToken,
    };
    const domain = this.extractDomainFromURL(this.externalApp.url);
    this.iframeRef.nativeElement.contentWindow.postMessage(message, domain);
  }

  extractDomainFromURL(url) {
    const regex = /^https:\/\/?([^\/]+)/;
    const match = url.match(regex);
    return match ? match[0] : '';
  }

  getHelpText() {
    if (this.externalApp?.helpText) {
      return this.externalApp?.helpText;
    }
    return $localize`You're viewing an external app. If you notice any issues, please contact your account administrator.`;
  }

  ngOnDestroy() {
    this.componentSubs.unsubscribe();
  }
}
