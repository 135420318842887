import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';

import { ExternalAppsState } from './external-apps.model';
import {
  CLEAR_ACTIVE_EXTERNAL_APP,
  CREATE_EXTERNAL_APP,
  CREATE_EXTERNAL_APP_SUCCESS,
  DELETE_EXTERNAL_APP,
  DELETE_EXTERNAL_APP_SUCCESS,
  EXTERNAL_APPS_ACTION_FAILED,
  GET_ACTIVE_EXTERNAL_APP,
  GET_ACTIVE_EXTERNAL_APP_SUCCESS,
  GET_EXTERNAL_APPS,
  GET_EXTERNAL_APPS_SUCCESS,
  SET_EXTERNAL_APPS_LOADING_FLAG,
  UPDATE_EXTERNAL_APP,
  UPDATE_EXTERNAL_APP_SUCCESS,
  UPDATE_EXTERNAL_APPS_POSITIONS,
} from './external-apps.actions';

const initialState: ExternalAppsState = {
  loaded: false,
  loading: false,
  error: null,
  items: [],
  activeItem: null
};

export function externalAppsReducer(
  state: ExternalAppsState = initialState,
  action: UnsafeAction
) {
  switch (action.type) {
    case GET_EXTERNAL_APPS: {
      return {
        ...state,
        items: [],
        loading: true,
        loaded: false,
      };
    }

    case GET_ACTIVE_EXTERNAL_APP: {
      return { ...state, loading: true, activeItem: null };
    }

    case CREATE_EXTERNAL_APP:
    case UPDATE_EXTERNAL_APP:
    case DELETE_EXTERNAL_APP:
    case UPDATE_EXTERNAL_APPS_POSITIONS: {
      return { ...state, loading: true };
    }

    case GET_EXTERNAL_APPS_SUCCESS: {
      const items = action.payload;
      return {
        ...state,
        loaded: true,
        loading: false,
        error: null,
        items
      };
    }

    case GET_ACTIVE_EXTERNAL_APP_SUCCESS: {
      const items = action.payload.items;
      const activeItem = action.payload.activeItem;

      return {
        ...state,
        loading: false,
        loaded: true,
        items,
        activeItem
      };
    }

    case CREATE_EXTERNAL_APP_SUCCESS: {
      const items = [...state.items, action.payload];

      return {
        ...state,
        loading: false,
        error: null,
        items,
        activeItem: action.payload
      };
    }

    case UPDATE_EXTERNAL_APP_SUCCESS: {
      const items = [...state.items];
      const index = items.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        items[index] = action.payload;
      }

      return {
        ...state,
        loading: false,
        error: null,
        items,
        activeItem: action.payload
      };
    }

    case DELETE_EXTERNAL_APP_SUCCESS: {
      return {
        ...state,
        loading: false,
        items: state.items.filter(item => item.id !== action.payload)
      }
    }

    case SET_EXTERNAL_APPS_LOADING_FLAG: {
      return { ...state, loading: action.payload };
    }

    case CLEAR_ACTIVE_EXTERNAL_APP: {
      return { ...state, activeItem: null };
    }

    case EXTERNAL_APPS_ACTION_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}

export const getExternalAppsState = (state) => state.externalApps;
export const getExternalApps = createSelector(getExternalAppsState, (state) => state.items);
export const getExternalAppsLoadingFlag = createSelector(getExternalAppsState, (state) => state.loading);
export const getActiveExternalApp = createSelector(getExternalAppsState, (state) => state.activeItem);
