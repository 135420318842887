<!-- for when it is possible to select only a single option -->
<mat-form-field *ngIf="fieldConfig.inputType === 'single'"
                [style.width.%]="width"
                appearance="outline"
                class="autocomplete-single-select"
                [class.--deleted]="selectedItem && selectedItem.deleted"
                [class.--invalid-taxonomy]="selectedItem && (selectedItem.invalidAllowedTaxonomy || selectedItem.invalidLocaleTaxonomy || selectedItem.deleted)"
                [class.--deactivated]="selectedItem && !selectedItem.active"
                [class.--selected]="selectedItem"
                [class.--taxonomies]="cmsDataSourceType === 'taxonomies'"
                [floatLabel]="readOnlyMode ? 'always' : 'auto'">
  <mat-label>{{ fieldConfig?.label }}</mat-label>

  <img *ngIf="cmsDataSourceType === 'contentLocales' && selectedItem && multipleLocalesExist"
       class="gd-field-builder__locale-flag-prefix"
       matPrefix
       [src]="allContentLocalesMap[selectedItem.id].iconUrl || '/assets/img/flags/xx.svg'">
  <input  matInput
          [matAutocomplete]="auto"
          [formControl]="filterField"
          [required]="isFormControlRequired"
          [placeholder]="!readOnlyMode ? autocompletePlaceholder : ''"
          autocomplete="off"
          (blur)="clearAutocompleteFilterField()"
          #focusInput
          (keydown.enter)="$event.preventDefault()"
          (focus)="inputFocusDataFetch()"
          (click)="openSelectPanel()"
          [readonly]="readOnlyMode"
          #afterSelectionFocus/>
          <!-- this spinner indicates suggestions are loading for all except initial result set -->
          <mat-spinner *ngIf="dataLoading && !firstDataFetchInProgress"
                       class="gd-autocomplete-field__field-loader"
                       diameter="20"
                       matSuffix>
          </mat-spinner>
          <mat-icon *ngIf="isAutocompleteField.isArticle && isFocused"
                    class="info-icon"
                    [matTooltip]="prefixSearchTooltip"
                    i18n-matTooltip
                    matSuffix
                    fontSet="fal"
                    fontIcon="fa-info-circle"
                    matTooltipClass="gd-autocomplete-field__prefix-search"
                    style="padding-left: 2px;"></mat-icon>
          <mat-icon *ngIf="showScheduleIcon(selectedItem)"
                        matSuffix
                        [matTooltip]="getContentReferenceIssueTooltip(selectedItem)"
                        fontSet="fal"
                        fontIcon="fa-clock"
                        class="gd-field-builder__clock-icon --input"
                        style="font-size: 19px"></mat-icon>
          <mat-icon *ngIf="dataSourceIsCMS && selectedItem &&
                           ((selectedItem.deleted || selectedItem.invalidAllowedTaxonomy || selectedItem.invalidLocaleTaxonomy || (!selectedItem.active && sourceVal.value === 'taxonomies')) ||
                           ((isAutocompleteField.isCollection || isAutocompleteField.isArticle || isAutocompleteField.isLiveReporting) &&
                           !selectedItem.publishedRevisionId && !selectedItem.scheduled))"
                    [matTooltip]="getContentReferenceIssueTooltip(selectedItem)"
                    class="gd-field-builder__exclamation-icon --input"
                    [class.--deleted]="selectedItem.deleted || selectedItem.invalidAllowedTaxonomy || selectedItem.invalidLocaleTaxonomy || !selectedItem.active"
                    fontSet="fal"
                    fontIcon="fa-exclamation-circle"
                    style="font-size: 19px !important"
                    matSuffix></mat-icon>
          <mat-icon matSuffix
                    *ngIf="fieldControl.value &&
                           dataSourceIsCMS &&
                           selectedItem &&
                           !selectedItem.deleted &&
                           !selectedItem.invalidAllowedTaxonomy &&
                           !selectedItem.invalidLocaleTaxonomy &&
                           cmsDataSourceType !== 'contentLocales' &&
                           cmsDataSourceType !== 'statuses'"
                    class="gd-field-builder__single-new-tab-button --input gd-field-builder__icon"
                    [class.--read-only]="readOnlyMode"
                    matTooltip="Open in new tab"
                    i18n-matTooltip
                    matTooltipPosition="below"
                    style="font-size: 20px !important"
                    (click)="navigateToItem(fieldControl.value)">open_in_new</mat-icon>
          <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
                    style="color: #8e8e8e; font-size: 19px !important"
                    class="question-icon"
                    [matTooltip]="infoTooltip"
                    matTooltipPosition="above"
                    matSuffix
                    fontSet="fal"
                    fontIcon="fa-question-circle"></mat-icon>
   <mat-hint *ngIf="showTaxonomyPath && selectedItem && cmsDataSourceType === 'taxonomies'">{{selectedItem?.fullPath}}</mat-hint>
   <mat-hint *ngIf="showInvalidDataHint && cmsDataSourceType !== 'taxonomies'" class="invalid-data-hint --single" align="end" i18n>The selected item type is not from the list of allowed types.</mat-hint>
   <mat-hint *ngIf="showInvalidDataHint && cmsDataSourceType === 'taxonomies'" class="invalid-data-hint --single" align="end" i18n>{{ getSingleTaxonomyErrorMessage(selectedItem) }}</mat-hint>
   <mat-hint *ngIf="dataSourceMessage"
             [class.mat-error]="dataSourceMessageType === 'error'">
      {{ dataSourceMessage }}
    </mat-hint>
    <mat-hint style="padding-bottom: 2px;"
              *ngIf="usage === 'COLLECTION_ITEM' && isAutocompleteField.isArticle && !!filterField.value && selectedItem"
              i18n>
    </mat-hint>
   <mat-autocomplete autoActiveFirstOption
                     #auto="matAutocomplete"
                     [displayWith]="displayFn"
                     (optionSelected)="handleAutocompleteSelect($event)">
      <mat-option *ngFor="let option of (!readOnlyMode ? (data$ | async) : [])"
                  [value]="option"
                  class="gd-autocomplete-field__option"
                  [class.--loading]="dataLoading"
                  [class.taxonomy-autocomplete-option]="cmsDataSourceType === 'taxonomies'"
                  [style.height]="cmsDataSourceType === 'taxonomies' && showTaxonomyPath && option?.parent && '60px'">
        <ng-container [ngSwitch]="cmsDataSourceType">
          <ng-container *ngSwitchCase="'articles'">
            <div fxLayout="row"
                 fxLayoutAlign="start center"
                 fxLayoutGap="10px">
              <img class="gd-field-builder__locale-flag"
                   *ngIf="multipleLocalesExist"
                   [src]="allContentLocalesMap[option.contentLocaleId]?.iconUrl || '/assets/img/flags/xx.svg'">
              <span class="highlighted-article-search-terms__wrapper">
                <span class="highlighted-article-search-terms__headline"
                      [innerHTML]="option.headlinePlaceholder"></span>
                <span class="highlighted-article-search-terms__catchline"
                      [innerHTML]="option.catchlinePlaceholder"></span>
              </span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'collections'">
            <span class="highlighted-article-search-terms__wrapper">
              <span class="highlighted-article-search-terms__headline"
                    [innerHTML]="option.headlinePlaceholder"></span>
              <span class="highlighted-article-search-terms__catchline"
                    [innerHTML]="option.catchlinePlaceholder"></span>
            </span>
          </ng-container>
          <ng-container *ngSwitchCase="'liveReports'">
            <span class="highlighted-article-search-terms__wrapper">
              <span class="highlighted-article-search-terms__headline"
                    [innerHTML]="option.headlinePlaceholder"></span>
              <span class="highlighted-article-search-terms__catchline"
                    [innerHTML]="option.catchlinePlaceholder"></span>
            </span>
          </ng-container>

          <ng-container *ngSwitchCase="'contentLocales'">
            <div fxLayout="row"
                 fxLayoutAlign="start center"
                 fxLayoutGap="10px">
              <img class="gd-field-builder__locale-flag"
                   [src]="option?.iconUrl || '/assets/img/flags/xx.svg'">
              <span class="highlighted-article-search-terms__wrapper">
                <span class="highlighted-article-search-terms__headline"
                      [innerHTML]="option.name"></span>
              </span>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'taxonomies'">
            <ng-container
                          *ngFor="let name of (showTaxonomyPath ? option.taxonomyDisplayData : [option?.name || option?.label]); let last = last; let first = first;">
              <br *ngIf="showTaxonomyPath && last && option.parent">
              <mat-icon class="arrow-taxonomy-icon"
                        *ngIf='!first && !last'
                        fontSet="fal"
                        [fontIcon]="(dir$ | async) !== 'rtl' ? 'fa-angle-right' : 'fa-angle-left'"> </mat-icon>
              <mat-icon *ngIf="name === 'NODE_PLACEHOLDER'"
                        class="arrow-taxonomy-icon"
                        [matTooltip]="option.fullPath"
                        [matTooltipClass]="'taxonomy-path-tooltip'"
                        fontSet="fal"
                        fontIcon="fa-ellipsis-h-alt">
              </mat-icon>
              <mat-icon class="taxonomy-path-arrow" *ngIf="showTaxonomyPath && last && option.parent" fontSet="fal" fontIcon="fa-level-up"></mat-icon>
              <span *ngIf="name !== 'NODE_PLACEHOLDER'"
                    class="taxonomy-path-part"
                    [ngClass]="(showTaxonomyPath && last && option.parent) ? 'taxonomy-path-part-options' : ''">{{ name }}</span>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>{{ option?.name || option?.label }}</ng-container>
        </ng-container>
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="filterField.hasError('required')">{{customRequiredFieldMessage || defaultRequiredFieldMessage}}</mat-error>
  </mat-form-field>

<!-- for when it is possible to select multiple options -->
<mat-form-field class="gd-field-builder__autocomplete-multi-select-wrapper"
                *ngIf="fieldConfig.inputType === 'multiple' && !readOnlyMode"
                [style.width.%]="width"
                appearance="outline"
                [class.--empty]="!multiSelectFieldChips.length && !isAllowedTaxonomiesField">
  <mat-label>{{ fieldConfig?.label + (isFormControlRequired ? ' *' : '') }}</mat-label>
    <input matInput
           [formControl]="filterField"
           [matAutocomplete]="multipleAuto"
           (blur)="updateFilterFieldValidation()"
           [placeholder]="autocompletePlaceholder"
           autocomplete="off"
           (keydown.enter)="$event.preventDefault()"
           (focus)="inputFocusDataFetch()"
           (click)="openSelectPanel()"/>
      <!-- this spinner indicates suggestions are loading for all except initial result set -->
      <mat-spinner *ngIf="dataLoading && !firstDataFetchInProgress"
                   class="gd-autocomplete-field__field-loader"
                   diameter="20"
                   matSuffix>
      </mat-spinner>
      <mat-hint *ngIf="showInvalidDataHint && cmsDataSourceType !== 'taxonomies'" class="invalid-data-hint --multiple" align="start" i18n>Some of the selected items type are not from the list of allowed types.</mat-hint>
      <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
                style="color: #8e8e8e; font-size: 19px !important"
                class="question-icon"
                [matTooltip]="infoTooltip"
                matTooltipPosition="above" matSuffix fontSet="fal"
                fontIcon="fa-question-circle"></mat-icon>
      <mat-icon *ngIf="isAutocompleteField.isArticle && isFocused"
                class="info-icon"
                [matTooltip]="prefixSearchTooltip"
                i18n-matTooltip
                matSuffix
                fontSet="fal"
                fontIcon="fa-info-circle"
                matTooltipClass="gd-autocomplete-field__prefix-search"></mat-icon>
      <mat-error *ngIf="filterField.hasError('required')">{{customRequiredFieldMessage || defaultRequiredFieldMessage}}</mat-error>
        <mat-autocomplete autoActiveFirstOption
                          #multipleAuto="matAutocomplete"
                          (optionSelected)="selectItem($event)"
                          showPanel="filterField.value">
          <mat-option *ngFor="let option of (data$ | async)"
                      [value]="option"
                      class="gd-autocomplete-field__option"
                      [class.--loading]="dataLoading"
                      [class.taxonomy-autocomplete-option]="cmsDataSourceType === 'taxonomies'"
                      [style.height]="cmsDataSourceType === 'taxonomies' && showTaxonomyPath && option.parent && '60px'">
            <ng-container [ngSwitch]="cmsDataSourceType">
              <ng-container *ngSwitchCase="'articles'">
                <div fxLayout="row"
                     fxLayoutAlign="start center"
                     fxLayoutGap="10px">
                  <img class="gd-field-builder__locale-flag"
                       *ngIf="multipleLocalesExist"
                       [src]="allContentLocalesMap[option.contentLocaleId]?.iconUrl || '/assets/img/flags/xx.svg'">
                  <span class="highlighted-article-search-terms__wrapper">
                    <span class="highlighted-article-search-terms__headline"
                          [innerHTML]="option.headlinePlaceholder"></span>
                    <span class="highlighted-article-search-terms__catchline"
                          [innerHTML]="option.catchlinePlaceholder"></span>
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'collections'">
                <span class="highlighted-article-search-terms__wrapper">
                  <span class="highlighted-article-search-terms__headline"
                        [innerHTML]="option.headlinePlaceholder"></span>
                  <span class="highlighted-article-search-terms__catchline"
                        [innerHTML]="option.catchlinePlaceholder"></span>
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="'liveReports'">
                <span class="highlighted-article-search-terms__wrapper">
                  <span class="highlighted-article-search-terms__headline"
                        [innerHTML]="option.headlinePlaceholder"></span>
                  <span class="highlighted-article-search-terms__catchline"
                        [innerHTML]="option.catchlinePlaceholder"></span>
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="'contentLocales'">
                <div fxLayout="row"
                     fxLayoutAlign="start center"
                     fxLayoutGap="10px">
                  <img class="gd-field-builder__locale-flag"
                       [src]="option?.iconUrl || '/assets/img/flags/xx.svg'">
                  <span class="highlighted-article-search-terms__wrapper">
                    <span class="highlighted-article-search-terms__headline"
                          [innerHTML]="option.name"></span>
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'taxonomies'">
                <ng-container
                              *ngFor="let name of (showTaxonomyPath ? option.taxonomyDisplayData : [option?.name || option?.label]); let last = last; let first = first;">
                  <br *ngIf="showTaxonomyPath && last && option.parent">
                  <mat-icon class="arrow-taxonomy-icon"
                            *ngIf='!first && !last'
                            fontSet="fal"
                            [fontIcon]="(dir$ | async) !== 'rtl' ? 'fa-angle-right' : 'fa-angle-left'"> </mat-icon>
                  <mat-icon *ngIf="name === 'NODE_PLACEHOLDER'"
                            class="arrow-taxonomy-icon"
                            [matTooltip]="option.fullPath"
                            [matTooltipClass]="'taxonomy-path-tooltip'"
                            fontSet="fal"
                            fontIcon="fa-ellipsis-h-alt">
                  </mat-icon>
                  <mat-icon class="taxonomy-path-arrow" *ngIf="showTaxonomyPath && last && option.parent" fontSet="fal" fontIcon="fa-level-up">
                  </mat-icon>
                  <span *ngIf="name !== 'NODE_PLACEHOLDER'"
                        class="taxonomy-path-part"
                        [ngClass]="(showTaxonomyPath && last && option.parent) ? 'taxonomy-path-part-options' : ''">{{ name }}</span>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchDefault>{{ option?.name || option?.label }}</ng-container>
            </ng-container>
          </mat-option>
        </mat-autocomplete>
</mat-form-field>

<!--Read-only mode display for multiple options -->
<section *ngIf="fieldConfig.inputType === 'multiple' && readOnlyMode">
  <div style="display: flex; align-items: center; gap: 6px;">
    <div class="gd-field-builder__read-only-label">{{ fieldConfig?.label }}</div>
    <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
              class="question-icon"
              [matTooltip]="infoTooltip"
              matTooltipPosition="above"
              matSuffix
              fontSet="fal"
              fontIcon="fa-question-circle"
              style="color: #8e8e8e"></mat-icon>
  </div>
  <div [class.read-only-field__no-value]="!multiSelectFieldChips.length">
    {{ multiSelectFieldChips.length ? '' : 'No value' }}</div>
</section>

   <!-- NOTE code below is relevant if the control allows multiple values to be selected -->
   <!-- list of options selected for non article multi select autocomplete -->
   <!-- TODO check the behaviour of gd-field-builder__card-list -->
   <section *ngIf="!(isAutocompleteField.isArticle || isAutocompleteField.isCollection || isAutocompleteField.isLiveReporting) && fieldConfig.inputType === 'multiple' && !isAllowedTaxonomiesField"
            class="gd-field-builder__card-list"
            [class.--invalid-data-hint-visible]="showInvalidDataHint && cmsDataSourceType !== 'taxonomies'"
            [class.gd-field-builder__card-list]="multiSelectFieldChips.length ? true : false"
            [style.width.%]="width"
            cdkDropList
            (cdkDropListDropped)="drop($event)"
            [cdkDropListDisabled]="!hasActionPermission || readOnlyMode">
    <mat-card *ngFor="let card of multiSelectFieldChips"
              class="gd-field-builder__card-wrapper"
              [class.--invalid-data-hint-border]="!card.isItemValid && showInvalidDataHint && cmsDataSourceType !== 'taxonomies'"
              [class.--deleted]="card.deleted"
              [class.--deactivated]="!card.active"
              [class.--unpublished]="isAutocompleteField.isCollection && !card.publishedRevisionId"
              [class.--invalid-taxonomy]="cmsDataSourceType === 'taxonomies' && showInvalidDataHint && (card.invalidLocaleTaxonomy || card.invalidAllowedTaxonomy)"
              [class.--no-localisation]="cmsDataSourceType === 'taxonomies' && showInvalidDataHint && (card.invalidLocaleTaxonomy || card.invalidAllowedTaxonomy) && usage === 'CONTENT_QUEUE'"
              [class.--read-only]="readOnlyMode"
              cdkDrag>
       <span class="gd-field-builder__card-name-wrapper"
             [class.--no-drag]="!hasActionPermission || readOnlyMode">
          <div class="gd-field-builder__card-name">
            <ng-container [ngSwitch]="cmsDataSourceType">
              <ng-container *ngSwitchCase="'taxonomies'">
                <mat-icon *ngIf="!card.active"
                          class="gd-field-builder__card-chip-icon"
                          svgIcon="glideCustomIcon_deactivatedTaxonomies">
                </mat-icon>
                <div class="gd-field-builder__card-label-wrapper">
                <span *ngFor="let name of card.taxonomyDisplayData; let first = first;"
                      [dir]="dir$ | async">
                  <mat-icon class="arrow-taxonomy-icon"
                            *ngIf='!first'
                            fontSet="fal"
                            [fontIcon]="(dir$ | async) !== 'rtl' ? 'fa-angle-right' : 'fa-angle-left'"
                            style="margin-top: 0;"> </mat-icon>
                  <mat-icon *ngIf="name === 'NODE_PLACEHOLDER'"
                            class="arrow-taxonomy-icon"
                            [matTooltip]="card.fullPath"
                            [matTooltipClass]="'taxonomy-path-tooltip'"
                            fontSet="fal"
                            fontIcon="fa-ellipsis-h-alt"
                            style="margin-top: 0;">
                  </mat-icon>
                  <span *ngIf="name !== 'NODE_PLACEHOLDER'">{{ name }}</span>
                </span>
              </div>
              </ng-container>
              <ng-container *ngSwitchCase="'contentLocales'">
                <span class="gd-field-builder__locale-name">
                  <img class="gd-field-builder__locale-flag-card" [src]="card?.iconUrl || '/assets/img/flags/xx.svg'">
                  {{card.name}}
                </span>
              </ng-container>
              <ng-container *ngSwitchDefault>{{ card?.name || card?.label || card?.headline }}</ng-container>
            </ng-container>
          </div>
          <span class="gd-field-builder__card-button-wrapper">
            <mat-icon *ngIf="(isAutocompleteField.isCollection && !card.publishedRevisionId) || (showInvalidDataHint && (card.deleted || card.invalidAllowedTaxonomy || card.invalidLocaleTaxonomy )) || (!card.active && sourceVal.value === 'taxonomies')"
                      fontSet="fal"
                      fontIcon="fa-exclamation-circle"
                      class="gd-field-builder__exclamation-icon"
                      [matTooltip]="getContentReferenceIssueTooltip(card)"
                      [matTooltipDisabled]="!usage && !card.deleted && card.active"
                      [class.--deleted]="showInvalidDataHint && (card.deleted || card.invalidAllowedTaxonomy || card.invalidLocaleTaxonomy) || !card.active"></mat-icon>
            <mat-icon *ngIf="dataSourceIsCMS &&
                            (!card.deleted && !card.invalidAllowedTaxonomy &&
                            !card.invalidLocaleTaxonomy && card.active) &&
                            cmsDataSourceType !== 'contentLocales' &&
                            cmsDataSourceType !== 'statuses' &&
                            (!+card.id || card.id > 0)"
                      (click)="navigateToItem(card.id)"
                      matTooltip="Open in new tab"
                      i18n-matTooltip
                      class="gd-field-builder__single-new-tab-button"
                      matTooltipPosition="below">open_in_new</mat-icon>
            <mat-icon *ngIf="hasActionPermission && !readOnlyMode"
                      (click)="removeItem(card.id)"
                      class="gd-field-builder__remove-item-icon"
                      fontSet="fal"
                      fontIcon="fa-times"></mat-icon>
          </span>
        </span>
        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
     </mat-card>
     <mat-hint *ngIf="showInvalidDataHint && cmsDataSourceType === 'taxonomies'" class="gd-field-builder__card-list-hint" align="start" i18n>{{getMultipleTaxonomyErrorMessage()}}</mat-hint>
  </section>

  <!-- list of options selected for article multi select autocomplete -->
  <section *ngIf="(isAutocompleteField.isArticle || isAutocompleteField.isCollection || isAutocompleteField.isLiveReporting) && fieldConfig.inputType === 'multiple' && multiSelectFieldChips.length"
             class="gd-field-builder__card-list"
             [class.--invalid-data-hint-visible]="showInvalidDataHint"
             [style.width.%]="width"
             cdkDropList
             (cdkDropListDropped)="drop($event)"
             [cdkDropListDisabled]="!hasActionPermission || readOnlyMode">
     <mat-card *ngFor="let card of multiSelectFieldChips"
               class="gd-field-builder__card-wrapper"
               [class.--invalid-data-hint-border]="!card.isItemValid && showInvalidDataHint"
               [class.--deleted]="card.deleted"
               [class.--unpublished]="isAutocompleteField.isArticle && !card.publishedRevisionId && !card.scheduled"
               [class.--scheduled]="isAutocompleteField.isArticle && card.scheduled"
               [class.--unpublish-scheduled]="isAutocompleteField.isArticle && !card.scheduled && card.unpublishScheduled"
               [class.--read-only]="readOnlyMode"
               cdkDrag>
       <div>
         <span class="gd-field-builder__card-name-wrapper"
               [class.--no-drag]="!hasActionPermission || readOnlyMode">
             <span class="gd-field-builder__card-name">{{ card?.name || card.headline || card?.label }}</span>
             <span class="gd-field-builder__card-button-wrapper">
              <mat-icon *ngIf="card.deleted || card.invalidAllowedTaxonomy || card.invalidLocaleTaxonomy || (!card.publishedRevisionId && !card.scheduled) || (!card.active && sourceVal.value === 'taxonomies')"
                        [matTooltip]="getContentReferenceIssueTooltip(card)"
                        fontSet="fal"
                        fontIcon="fa-exclamation-circle"
                        class="gd-field-builder__exclamation-icon"
                        [class.--deleted]="card.deleted || card.invalidAllowedTaxonomy || card.invalidLocaleTaxonomy  || !card.active"></mat-icon>
               <mat-icon *ngIf="card.scheduled || card.unpublishScheduled"
                        [matTooltip]="getContentReferenceIssueTooltip(card)"
                        fontSet="fal"
                        fontIcon="fa-clock"
                        class="gd-field-builder__clock-icon"></mat-icon>
               <mat-icon *ngIf="dataSourceIsCMS && !card.deleted"
                         (click)="navigateToItem(card.id)"
                         matTooltip="Open in new tab"
                         i18n-matTooltip
                         class="gd-field-builder__single-new-tab-button"
                         matTooltipPosition="below">open_in_new</mat-icon>
                <mat-icon *ngIf="hasActionPermission && !readOnlyMode"
                          (click)="removeItem(card.id)"
                          class="gd-field-builder__remove-item-icon"
                          fontSet="fal"
                          fontIcon="fa-times"></mat-icon>
              </span>
          </span>
          <span class="gd-field-builder__card-catchline-wrapper">{{ card.catchline }}</span>
        </div>
        <div class="example-custom-placeholder --article" *cdkDragPlaceholder></div>
     </mat-card>
     <mat-hint *ngIf="showInvalidDataHint && cmsDataSourceType === 'taxonomies'" class="gd-field-builder__card-list-hint" align="start" i18n>{{getMultipleTaxonomyErrorMessage()}}</mat-hint>
  </section>
