import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from './breadcrumb.service';
import { BidiService } from '../../core/i18n/bidi.service';

@Component({
  selector: 'gd-breadcrumbs',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() prefix = '';

  urls: string[];
  noIndexPage: string [];
  private _routerSubscription: any;
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private bidiService: BidiService,
  ) { }

  ngOnInit(): void {
    this.urls = [];
    this.noIndexPage = ['/widgets', '/site-builder', '/media', '/glide-users', '/verify', '/notifications'];

    if (this.prefix.length > 0) {
      this.urls.unshift(this.prefix);
    }

    this._routerSubscription = this.router.events.subscribe((navigationEnd: NavigationEnd) => {
      if (!(navigationEnd instanceof NavigationEnd)) {
        return;
      }
      this.urls.length = 0; // Fastest way to clear out array
      const breadcrumbUrl = navigationEnd.urlAfterRedirects ? navigationEnd.urlAfterRedirects : navigationEnd.url;
      if (!!breadcrumbUrl) {
        this.generateBreadcrumbTrail(breadcrumbUrl);
      }
    });

    if (this.urls) {
      this.generateBreadcrumbTrail(this.route.snapshot['_routerState'].url);
    }
  }

  ngOnChanges(): void {
    if (!this.urls) {
      return;
    }

    this.urls.length = 0;
    this.generateBreadcrumbTrail(this.router.url);
  }

  generateBreadcrumbTrail(url: string): void {
    // workaround for removing the "Update" label from breadcrumbs in case of external apps view page
    if (this.urls.some(url => url.includes('/external-app/'))) {
      this.urls = ['/external-app'];
      return;
    }
    if (!this.breadcrumbService.isRouteHidden(url)) {
      /**
       * check if url is live-reporting-id/posts or live-reporting-id/posts/post-id because we want to skip those urls,
       * and if we don't skip those urls we will have breadcrumb like this
       * Live Reporting > Update > Update or Live Reporting > Update > Update > Update
       */
      const isPostUrl = !!url.match(/\/[0-9]+\/posts/);
      const isArticleLinkingUrl = !!url.match(/(links-report).+$/);
      if(!isPostUrl && !isArticleLinkingUrl) {
        // Add url to beginning of array (since the url is being recursively broken down from full url to its parent)
        this.urls.unshift(url);
      }
    }
    if (url.lastIndexOf('/') > 0) {
      this.generateBreadcrumbTrail(url.substr(0, url.lastIndexOf('/'))); // Find last '/' and add everything before it as a parent route
    } else if (this.prefix.length > 0) {
      this.urls.unshift(this.prefix);
    }
    if (this.urls.includes('/authors') && !this.urls.includes('/glide-users')) {
      this.urls.unshift('/glide-users');
    }
  }

  navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  friendlyName(url: string): string {
    const temp = !url ? '' : this.breadcrumbService.getFriendlyNameForRoute(url);
    // This is hard coded breadcrumb to avoid routing updates. Need to update it properly
    const friendlyName = temp === 'Widgets' ? 'Site Builder' : temp;
    return friendlyName;
  }

  ngOnDestroy(): void {
    this._routerSubscription.unsubscribe();
  }
}
