<div *ngIf="item.isRouteString(); then routeNavItem else functionNavItem"></div>

<ng-template #functionNavItem>
  <a class="sidenav-anchor gd-sidenav-list-item" [ngClass]="item.customClass"
     *ngIf="!item.hasSubItems()"
     mat-list-item mat-ripple (click)="item.route()">

    <mat-icon>{{ item.icon }}</mat-icon>
    <span class="sidenav-item-name fade-in-on-icon-sidenav">{{ item.name }}</span>
    <span fxFlex><!-- fill space --></span>
    <span class="badge fade-in-on-icon-sidenav" *ngIf="item.badge" [style.background-color]="item.badgeColor">{{ item.badge }}</span>
  </a>
</ng-template>


<ng-template #routeNavItem>
  <a class="sidenav-anchor gd-sidenav-list-item" [ngClass]="item.customClass"
     *ngIf="!item.hasSubItems()"
     mat-list-item mat-ripple
     [routerLink]="[item.route]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
     [title]="item.tooltip"
     (click)="updateCurrentlyOpenSection()">

    <mat-icon *ngIf="!item.isCustomSvgIcon" class="gd-sidenav-fa-icon" fontSet="fal" fontIcon="{{item.icon}}"></mat-icon>
    <mat-icon *ngIf="item.isCustomSvgIcon" class="gd-sidenav-svg-icon" svgIcon="{{item.icon}}"></mat-icon>
    <span class="sidenav-item-name fade-in-on-icon-sidenav">{{ item.name }}</span>
    <span fxFlex><!-- fill space --></span>
    <span class="badge fade-in-on-icon-sidenav" *ngIf="item.badge" [style.background-color]="item.badgeColor">{{ item.badge }}</span>
  </a>
</ng-template>

<a class="sidenav-anchor gd-sidenav-list-item"
   *ngIf="item.hasSubItems()"
   mat-list-item mat-ripple
   (click)="toggleDropdown()"
   [ngClass]="item.customClass"
   [class.--active-anchor]="isItemActive"
   [title]="item.tooltip">
   <mat-icon *ngIf="!item.isCustomSvgIcon"  class="gd-sidenav-fa-icon" fontSet="fal" fontIcon="{{item.icon}}"></mat-icon>
   <mat-icon *ngIf="item.isCustomSvgIcon" class="gd-sidenav-svg-icon" svgIcon="{{item.icon}}"></mat-icon>
  <span class="sidenav-item-name fade-in-on-icon-sidenav">{{ item.name }}</span>
  <span fxFlex><!-- fill space --></span>
  <span class="badge fade-in-on-icon-sidenav" *ngIf="item.badge" [style.background-color]="item.badgeColor" style="margin-right: 12px;">{{ item.badge }}</span>
  <mat-icon *ngIf="item.hasSubItems()" class="sidenav-item-dropdown-indicator rotate fade-in-on-icon-sidenav" fontSet="fal" fontIcon="fa-angle-down"></mat-icon>

</a>

<mat-nav-list class="sidenav-list sidenav-list-nested"
     fxLayout="column" [style.height]="getSubItemsHeight()">
  <gd-sidenav-item *ngFor="let subItem of item.subItems" (click)="sendMixPanelEvent($event, subItem)"
                   [item]="subItem"
                   [currentlyActiveSidenavItem]="currentlyActiveSidenavItem"></gd-sidenav-item>
</mat-nav-list>
