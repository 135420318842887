import { mergeMap, catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { UnsafeAction } from '../unsafe-action.interface';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import {
  CREATE_EXTERNAL_APP,
  CreateExternalAppSuccessAction,
  DELETE_EXTERNAL_APP,
  DeleteExternalAppSuccessAction,
  EXTERNAL_APPS_ACTION_FAILED,
  ExternalAppsFailedAction,
  GET_ACTIVE_EXTERNAL_APP,
  GET_EXTERNAL_APPS,
  GetActiveExternalAppSuccessAction,
  GetExternalAppsSuccessAction,
  UPDATE_EXTERNAL_APP,
  UPDATE_EXTERNAL_APPS_POSITIONS,
  UpdateExternalAppSuccessAction,
} from './external-apps.actions';
import { ExternalAppsService } from '../../api/external-apps/external-apps.service';

@Injectable()
export class ExternalAppsEffects {
  getExternalApps$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_EXTERNAL_APPS),
      mergeMap((action: UnsafeAction) => {
        return this.externalAppsService.getExternalApps(action.payload).pipe(
          map((response) => new GetExternalAppsSuccessAction(response)),
          catchError((error) => of(new ExternalAppsFailedAction({ error, action })))
        );
      })
    )
  );

  getActiveExternalApp$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_ACTIVE_EXTERNAL_APP),
      mergeMap((action: UnsafeAction) => {
        // list endpoint is being used given that we need all external apps for slug validation
        return this.externalAppsService.getExternalApps().pipe(
          map((items) => {
            // allow filtering by id or slug
            const { key, value } = action.payload;
            const activeItem = items.find((item) => item[key] === value);
            if (!activeItem) {
              const redirectPath = key === 'slug' ? 'dashboard' : 'site-builder/external-apps';
              this.router.navigate([redirectPath]);
              this.snackBar.open($localize`External app not found`, $localize`Close`, {
                duration: 3000,
              });
            }
            return new GetActiveExternalAppSuccessAction({ items, activeItem });
          }),
          catchError((error) => of(new ExternalAppsFailedAction({ error, action })))
        );
      })
    )
  );

  createExternalApp$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CREATE_EXTERNAL_APP),
      mergeMap((action: UnsafeAction) => {
        return this.externalAppsService.createExternalApp(action.payload).pipe(
          tap((response) => {
            this.router.navigate(['site-builder/external-apps', response?.id]);
            this.snackBar.open($localize`External app created successfully.`, $localize`Close`, {
              duration: 3000,
            });
          }),
          map((response) => new CreateExternalAppSuccessAction(response)),
          catchError((error) => of(new ExternalAppsFailedAction({ error, action })))
        );
      })
    )
  );

  updateExternalApp$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UPDATE_EXTERNAL_APP),
      mergeMap((action: UnsafeAction) => {
        return this.externalAppsService.updateExternalApp(action.payload).pipe(
          tap(() => {
            this.snackBar.open($localize`External app updated successfully.`, $localize`Close`, {
              duration: 3000,
            });
          }),
          map((response) => new UpdateExternalAppSuccessAction(response)),
          catchError((error) => of(new ExternalAppsFailedAction({ error, action })))
        );
      })
    )
  );

  updateExternalAppsPositions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UPDATE_EXTERNAL_APPS_POSITIONS),
      mergeMap((action: UnsafeAction) => {
        return this.externalAppsService.updateExternalAppsPositions(action.payload).pipe(
          tap(() => this.snackBar.open($localize`External app positions updated`, $localize`Close`, { duration: 3000 })),
          map((response) => new GetExternalAppsSuccessAction(response)),
          catchError((error) => of(new ExternalAppsFailedAction({ error, action })))
        );
      })
    )
  );

  deleteExternalApp$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DELETE_EXTERNAL_APP),
      mergeMap((action: UnsafeAction) => {
        return this.externalAppsService.deleteExternalApp(action.payload).pipe(
          map(() => new DeleteExternalAppSuccessAction(action.payload)),
          catchError((error) => of(new ExternalAppsFailedAction({ error, action })))
        );
      })
    )
  );

  actionFailed$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EXTERNAL_APPS_ACTION_FAILED),
        tap((err: any) => {
          const actionType =
            (err && err.payload && err.payload.action && err.payload.action.type) ||
            $localize`Unknown`;
          this.snackBar.open($localize`Action failed: ` + actionType, $localize`Close`, {
            duration: 4000,
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private externalAppsService: ExternalAppsService,
    private router: Router
  ) {}
}
