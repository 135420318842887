import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from '../fury-core/admin/admin.component';
import { AuthGuard } from '../core/api/auth/auth-guard.guard';
import { PermissionGuard } from '../core/api/auth/permission-guard.guard';
import { ExternalAppViewComponent } from './+site-builder/+external-apps/external-app-view/external-app-view.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./+dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [PermissionGuard]
      },
      {
        path: 'articles',
        loadChildren: () => import('./+articles/articles.module').then(m => m.ArticlesModule),
        canActivate: [PermissionGuard]
      },
      {
        path: 'taxonomies',
        loadChildren: () => import('./+taxonomies/taxonomies.module').then(m => m.TaxonomiesModule),
        canActivate: [PermissionGuard]
      },
      {
        path: 'collections',
        loadChildren: () => import('./+collections/collection.module').then(m => m.CollectionModule),
        canActivate: [PermissionGuard]
      },
      {
        path: 'authors',
        loadChildren: () => import('./+authors/authors.module').then(m => m.AuthorsModule),
        canActivate: [PermissionGuard] },
      {
        path: 'site-builder',
        loadChildren: () => import('./+site-builder/site-builder.module').then(m => m.SiteBuilderModule),
        canActivate: [PermissionGuard]
      },
      {
        path: 'widgets',
        loadChildren: () => import('./+widgets/widgets.module').then(m => m.WidgetsModule),
        canActivate: [PermissionGuard]
      },
      {
        path: 'verify',
        loadChildren: () => import('./+verify/verify.module').then(m => m.VerifyModule),
        canActivate: [PermissionGuard]
      },
      {
        path: 'live-reporting',
        loadChildren: () => import('./+live-reports/live-reports.module').then(m => m.LiveReportsModule),
        canActivate: [PermissionGuard]
      },
      { path: 'notifications',
        loadChildren: () => import('./+notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [PermissionGuard]
      },
      {
        path: 'external-app/:slug',
        component: ExternalAppViewComponent,
        canActivate: [PermissionGuard],
        // added for detecting external app view component and managing page title (tab name)
        data: { title: 'External App' }
      },
      {
        path: '**',
        redirectTo: 'misc/error404'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GlideCreateRoutingModule { }
